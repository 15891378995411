/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MessageBubbleIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Message Bubble icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Message Bubble Icon element.
 */
export default function MessageBubbleIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="message-bubble-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.71825 14.3227C3.79425 14.4717 3.94525 14.5572 4.10175 14.5572C4.16775 14.5572 4.23475 14.5422 4.29725 14.5097L7.28975 12.9772C7.52525 12.9992 7.76425 13.0102 8.00125 13.0102C11.7067 13.0102 14.7212 10.3427 14.7212 7.06369C14.7212 3.78469 11.7067 1.11719 8.00125 1.11719C4.29575 1.11719 1.28125 3.78469 1.28125 7.06369C1.28125 8.88269 2.20275 10.5772 3.80975 11.7127C4.00375 11.8497 4.27275 11.8032 4.40975 11.6097C4.54675 11.4162 4.50075 11.1467 4.30675 11.0097C2.93175 10.0377 2.14275 8.59969 2.14275 7.06369C2.14275 4.25969 4.77075 1.97869 8.00175 1.97869C11.2327 1.97869 13.8608 4.25969 13.8608 7.06369C13.8608 9.86769 11.2323 12.1492 8.00175 12.1492C7.75225 12.1492 7.50075 12.1352 7.25425 12.1082C7.17025 12.0987 7.08575 12.1142 7.01075 12.1527L3.90525 13.7432C3.69375 13.8517 3.60975 14.1112 3.71825 14.3227ZM7.17875 6.98419C7.17875 7.44719 7.55275 7.82319 8.01475 7.82319C8.47625 7.82319 8.85075 7.44719 8.85075 6.98419C8.85075 6.52119 8.47625 6.14519 8.01475 6.14519C7.55275 6.14519 7.17875 6.52069 7.17875 6.98419ZM4.67125 7.82319C4.20925 7.82319 3.83525 7.44719 3.83525 6.98419C3.83525 6.52119 4.20925 6.14519 4.67125 6.14519C5.13275 6.14519 5.50675 6.52069 5.50675 6.98419C5.50675 7.44769 5.13275 7.82319 4.67125 7.82319ZM11.3577 7.82319C10.8962 7.82319 10.5223 7.44719 10.5223 6.98419C10.5223 6.52119 10.8962 6.14519 11.3577 6.14519C11.8193 6.14519 12.1938 6.52069 12.1938 6.98419C12.1938 7.44769 11.8193 7.82319 11.3577 7.82319Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
