/* eslint-disable no-param-reassign */
/**
 * @module ButtonItem
 */
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import Icon from './Icon';
import './ButtonItem.scss';

const ButtonItem = ({
  buttonSize,
  className = '',
  darkVariant,
  disabled,
  icon,
  iconPosition,
  iconRenderAsBg,
  id,
  image,
  onClick,
  style,
  target,
  text,
  url,
  ...passThroughProps
}) => {
  const [selectedIcon, setSelectedIcon] = React.useState('');
  const [fullUrl, setFullUrl] = React.useState(url);
  const Tag = url ? 'a' : 'button';
  const btnRef = React.useRef(null);

  const tagClass = `btn ${style} size-${
    convertValueToClassName(buttonSize) || 'small'
  } ${darkVariant ? 'dark-mode' : ''} ${
    icon?.field !== 'none' && !!iconPosition
      ? convertValueToClassName(iconPosition)
      : ''
  } ${convertValueToClassName(className)}`.trim();
  const anchorClassLinkBtn = `anchor-link ${convertValueToClassName(style)} ${
    darkVariant ? 'dark-mode' : ''
  } size-${convertValueToClassName(buttonSize) || 'small'} ${
    /* istanbul ignore next */ icon?.field !== 'none' && !!iconPosition
      ? convertValueToClassName(iconPosition)
      : ''
  } ${convertValueToClassName(className)}`.trim();
  const anchorClassImageLink = `anchor-link ${convertValueToClassName(
    style,
  )} ${convertValueToClassName(className)}`.trim();

  React.useEffect(() => {
    const finalUrl = implementUtmParams(url, window?.location);

    if (url && url.charAt(0) === '#') {
      setFullUrl(window.location.href.split('#')[0] + finalUrl);
    } else {
      setFullUrl(finalUrl);
    }

    if (Tag === 'a') {
      btnRef?.current?.setAttribute('href', finalUrl);
    } else {
      btnRef?.current?.setAttribute('type', 'button');
    }

    const iconsMap = {
      'channel-icons': 'channelIcon',
      'directional-icons': 'directionalIcon',
      'external-icons': 'externalIcon',
      'general-icons': 'generalIcon',
      'lc-icons': 'lcIcon',
    };
    const iconForButton = iconsMap[icon?.field];
    if (iconForButton && icon[iconForButton]) {
      setSelectedIcon(icon[iconForButton]);
    }

    const $btn = document.querySelectorAll('.btn');
    /* istanbul ignore next */
    $btn.forEach((btn) => {
      if (
        (!!btn.classList.contains('btn-small') && btn.offsetHeight > 37) ||
        (!!btn.classList.contains('btn-medium') && btn.offsetHeight > 66) ||
        (!!btn.classList.contains('btn-large') && btn.offsetHeight > 70)
      ) {
        btn.style.borderRadius = `${btn.offsetHeight / 3}px`;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* istanbul ignore next */
  const handleOutsideClick = (e) => {
    const current = !!btnRef && btnRef.current;
    if (!!current && !current.contains(e.target)) {
      current.classList.remove('pressed');
      document.removeEventListener('click', handleOutsideClick, false);
    }
  };

  /* istanbul ignore next */
  const handleButtonClick = (event) => {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
    if (event) {
      const current = event.currentTarget;
      if (current) {
        current.classList.add('pressed');
        document.addEventListener('click', handleOutsideClick, false);
      }
    }
  };

  /* istanbul ignore next */
  const onImageLinkClick = (event) => {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  };

  if (style === 'banner') {
    return (
      <a
        className={`btn btn-primary ${convertValueToClassName(
          className,
        )}`.trim()}
        data-testid={passThroughProps?.['data-testid'] || 'button-item-test-id'}
        href={fullUrl || '#'}
        target={target || '_self'}
      >
        {text}
      </a>
    );
  }

  const returnVal = (
    <>
      {style !== 'share-link' &&
      style !== 'btn-tertiary' &&
      style !== 'image-link' &&
      style !== 'link-tertiary' ? (
        <Tag
          className={tagClass}
          data-testid={
            passThroughProps?.['data-testid'] || 'button-item-test-id'
          }
          disabled={disabled}
          id={id}
          name={typeof text === 'string' ? text : ''}
          onClick={handleButtonClick}
          ref={btnRef}
          target={target}
        >
          <Icon
            icon={icon}
            iconRenderAsBg={iconRenderAsBg}
            selectedIcon={selectedIcon}
          />
          {style !== 'btn-channel' && (
            <span className="button-text">{text}</span>
          )}
        </Tag>
      ) : null}
      {['share-link', 'btn-tertiary', 'link-tertiary'].includes(style) ? (
        <a
          className={anchorClassLinkBtn}
          data-testid={
            passThroughProps?.['data-testid'] || 'button-item-test-id'
          }
          href={fullUrl || '#'}
          onClick={onClick}
          target={target || '_self'}
        >
          <Icon
            icon={icon}
            iconRenderAsBg={iconRenderAsBg}
            selectedIcon={selectedIcon}
          />
          {style !== 'share-link' && <span className="link-text">{text}</span>}
        </a>
      ) : null}
      {style === 'image-link' ? (
        <a
          className={anchorClassImageLink}
          data-testid={
            passThroughProps?.['data-testid'] || 'button-item-test-id'
          }
          href={fullUrl || '#'}
          onClick={onImageLinkClick}
          target={target || '_self'}
        >
          {image ? (
            <img
              alt={image?.metadata?.caption || 'image link'}
              className="image-link-image"
              src={image['@link']}
            />
          ) : null}
        </a>
      ) : null}
    </>
  );

  /* istanbul ignore next */
  return returnVal;
};

export default ButtonItem;
