/**
 * @module StartAGroupMessage
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import { URLS } from '../../../utils/constants';
import { STRINGS } from '../../../utils/strings';

/**
 * Represents a simple container with "Start a LifeGroup" verbiage and call-to-action button.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.filteredList - Array of campus locations.
 * @param {Function} props.onClick - Handler function for button click.
 *
 * @returns {React.ReactElement} The StartAGroupMessage component.
 */
function StartAGroupMessage({ filteredList, onClick }) {
  const { searchResults } = useAlgoliaSearch();
  const resultsLength = searchResults?.nbHits || 0;
  const isLcoOnly =
    filteredList && filteredList.length === 1 && filteredList[0] === 'int';
  const url = isLcoOnly ? URLS.churchOnlineLinkUrl : URLS.lifeGroupLinkUrl;

  /**
   * Handler function for the button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  }

  return (
    <div
      className={`start-a-group container ${resultsLength > 0 ? 'p-t-3' : ''}`}
      data-testid="lg-start-a-group-container"
    >
      <div className="text-center">
        <h1 className="title">{STRINGS.startLifeGroup.title}</h1>
        <p className="subtitle">
          {STRINGS.startLifeGroup.broadenSearch}{' '}
          <a
            href={implementUtmParams(url, window?.location)}
            onClick={handleButtonClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            {STRINGS.startLifeGroup.cta}
          </a>
        </p>
      </div>
    </div>
  );
}

export default StartAGroupMessage;
