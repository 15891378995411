/**
 * @module LgLegacyCard
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { truncate } from '@lifechurch/web-tools-io/dist/utils/helpers/truncate';
import { camelToSnake } from '@lifechurch/web-tools-io/dist/utils/helpers/strings';
import { transformKeys } from '@lifechurch/web-tools-io/dist/utils/helpers/transformKeys';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { APP_CONFIG, SNAKE_CASE_EXCEPTIONS } from '../../utils/constants';
import { getDayLabel, normalize, setAgeLimits } from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import './LgCard.scss';

/**
 * Represents a card with LifeGroup or Local Partner data.
 *
 * Note: This component is considered legacy since it has been replaced in favor
 * of LgCard and the new design, but is still used for legacy support of Local
 * Partner cards and necessary initial launch support with LG Search API.
 *
 * @param {object} props - The component props object.
 * @param {object} props.data - The data object of the LG Search API-returned group.
 * @param {number} props.index - The index at which the card shows in the list from search.
 *
 * @returns {React.ReactElement} The LgLegacyCard component.
 */
function LgLegacyCard({ data, index }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics } = useAlgoliaSearch();

  if (!data) {
    return null;
  }

  const props = {
    lgAgeRange: setAgeLimits(data.attributes.agemin, data.attributes.agemax),
    lgCampusCode: data.attributes['campus-code'],
    lgCampusLocation: data.attributes['campus-location'],
    lgCampusName: data.attributes['campus-name'],
    lgCategory: data.attributes['community-types'][0],
    lgDay: getDayLabel(data.attributes['day-of-week']),
    lgDescription: data.attributes.description,
    lgFrequency: data.attributes['meeting-frequency'],
    lgId: data.attributes.guid,
    lgKidsWelcome: data.attributes['kids-welcome'],
    lgLocation: data.attributes['lg-loc'],
    lgMeetOnline: data.attributes['meet-online'],
    lgName: data.attributes.name,
    lgTime: data.attributes['meeting-time'],
    lgTimeZone: data.attributes['utc-time-zone'],
  };

  const dayIsWeekday = (day) => {
    return day !== 'Varies';
  };

  /* istanbul ignore next */
  const getHref = () => {
    const href =
      props.lgCategory.toLowerCase() === 'local partner'
        ? `${APP_CONFIG.myLifeChurchLgDomain}/LocalPartners/${props.lgId}`
        : `/lifegroup/${props.objectID}`;
    return implementUtmParams(href, window?.location);
  };

  /**
   * Handler function for the card click event.
   *
   * @param {Event} event - The Event data object associated with the click.
   */
  function handleCardClick(event) {
    const dataForAnalytics = getDataForAnalytics();
    const lgAnalyticsData = transformKeys(
      {
        ...data,
        position: index,
      },
      camelToSnake,
      SNAKE_CASE_EXCEPTIONS,
    );
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupCardLegacy,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: props.lgName,
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });
  }

  return (
    <div
      className="lg-legacy-card-wrapper"
      data-testid="lg-legacy-card-component"
    >
      <a
        href={implementUtmParams(getHref(), window?.location)}
        onClick={handleCardClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className={`lg-legacy-card border-radius-md lg-legacy-card-category-${normalize(
            props.lgCategory,
          )}`}
        >
          <div className="lg-legacy-card-side-bar">
            <div className="lg-legacy-card-meta">
              <div className="lg-legacy-day-of-week">{props.lgDay}</div>
              {
                /* istanbul ignore next */ dayIsWeekday(props.lgDay) ? (
                  <div className="fontSize-xxs">
                    {props.lgTime} {props.lgTimeZone}
                  </div>
                ) : null
              }
              {
                /* istanbul ignore next */ dayIsWeekday(props.lgDay) ? (
                  <div className="fontSize-xxs">{props.lgFrequency}</div>
                ) : null
              }
            </div>
            <div className="lg-legacy-card-meta-seperator"></div>
            <div className="lg-legacy-card-info m-b-1">
              {' '}
              {props.lgCategory}{' '}
            </div>
            <div className="fontSize-xxs m-b-half lg-legacy-card-info-age">
              {props.lgAgeRange}
            </div>
            {
              /* istanbul ignore next */ props.lgKidsWelcome ? (
                <div className="fontSize-xxs lg-legacy-card-info-kids">
                  Kids
                </div>
              ) : null
            }
          </div>
          <div className="lg-legacy-card-content p-t-1">
            <h2 className="lg-legacy-card-name fontSize-body color-black">
              {truncate(props.lgName, 40)}
            </h2>
            <p className="lg-legacy-card-campus fontSize-xs">
              {props.lgCampusName}
            </p>
            <p className="lg-legacy-card-description color-black">
              {props.lgDescription}
            </p>
            <div className="description-fade" />
          </div>
          <span className="more-info-text">{STRINGS.global.moreInfo}</span>
        </div>
      </a>
    </div>
  );
}

export default LgLegacyCard;
