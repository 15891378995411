/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module PrayerIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Prayer icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Prayer Icon element.
 */
export default function PrayerIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="prayer-icon">
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.59081 3.13172C7.48843 3.12937 7.38845 3.16291 7.3082 3.22652C7.22795 3.29013 7.17247 3.37981 7.15139 3.48002L5.61239 10.6856L4.79024 11.5071L6.61409 13.3296L7.64481 12.2989C7.76485 12.1795 7.86006 12.0375 7.92494 11.8811C7.98982 11.7247 8.02308 11.557 8.02281 11.3876V3.57115C8.02266 3.45587 7.97735 3.34524 7.89659 3.26297C7.81584 3.1807 7.70607 3.13402 7.59081 3.13172ZM5.83109 3.19787C5.89492 2.89944 6.03397 2.62227 6.23503 2.39268C6.43609 2.16309 6.69249 1.98868 6.97989 1.88603C7.2673 1.78338 7.57617 1.75588 7.87718 1.80614C8.1782 1.8564 8.46138 1.98276 8.69984 2.17322C8.93828 1.98257 9.22152 1.85607 9.52263 1.80574C9.82375 1.75541 10.1327 1.78293 10.4202 1.88568C10.7077 1.98843 10.9641 2.163 11.1651 2.39279C11.3661 2.62257 11.505 2.89995 11.5686 3.19855L13.0239 10.0133L15.9061 12.8956L12.1734 16.6283L8.79906 13.254C8.76531 13.2198 8.73201 13.1847 8.69916 13.1487C8.66681 13.1847 8.6335 13.2198 8.59926 13.254L5.22494 16.629L1.49219 12.8963L4.37444 10.0133L5.83109 3.19787ZM10.2476 3.47935C10.2259 3.37195 10.1649 3.27653 10.0765 3.21173C9.98813 3.14694 9.87878 3.11744 9.76981 3.129C9.66085 3.14056 9.56012 3.19236 9.48732 3.27425C9.41453 3.35615 9.3749 3.46226 9.37619 3.57182V11.387C9.37619 11.7292 9.51186 12.0572 9.75419 12.2982L10.7849 13.3296L12.6074 11.5064L11.7866 10.685L10.2476 3.47935Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
