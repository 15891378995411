/**
 * @module ProfileApi
 */
import { apiClient } from '../core';

/**
 * Retrieve user profile.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getUser({ accessToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.PROFILES_API_DOMAIN}/${process.env.PROFILES_API_VERSION}/people/me`,
  });
}
