/**
 * @module CategoryBasedSearch
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import { CategoryButtons } from './CategoryButtons';
import { CategoryStep } from './CategoryStep';
import { CATEGORIES_DATA } from './variables';
import './CategoryBasedSearch.scss';

/**
 * Represents a view with step-by-step card-based group selections to display for users to select and filter.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.onSearchComplete - Handler function for search complete event.
 *
 * @returns {React.ReactElement} The CategoryBasedSearch component.
 */
function CategoryBasedSearch({ onSearchComplete }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const {
    campusList,
    filteredList,
    genders,
    getDataForAnalytics,
    meetingType,
    storeGender,
    storeMeetingType,
    storeTopic,
    topics,
  } = useAlgoliaSearch();
  const [step, setStep] = React.useState(0);

  // Convenience component-based state vars.
  const [groupTypeLocation, setGroupTypeLocation] = React.useState('inPerson');

  /**
   * Convenience function to trigger analytics calls.
   *
   * @param {object} params - The function params object including `event` and `properties` keys.
   */
  function callAnalytics(
    { event, properties } = {
      event: ANALYTICS.events.buttonAction,
      properties: {},
    },
  ) {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: event || ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.categoryBasedSearch,
        component_url: null,
        ...properties,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Handler function for card click of group type location (online or in-person groups).
   *
   * @param {object} params - The function params object.
   * @param {object} params.data - The category data object associated with the click.
   */
  function handleGroupTypeLocationCardClick(params) {
    callAnalytics({
      properties: {
        label: params.data.card.title,
      },
    });
    setGroupTypeLocation(params.data.card.value);
    storeMeetingType({
      value: params.data.card.value,
    });
    setStep(1);
  }

  /**
   * Handler function for card click of group type (men's, women's, etc.).
   *
   * Note: If the group type is in-person, increase the step to allow for
   * location selection. Otherwise, trigger onSearchComplete() to show results.
   *
   * @param {object} params - The function params object.
   * @param {object} params.data - The category data object associated with the click.
   * @param {Event} params.event - The Event object associated with the click.
   */
  function handleGroupTypeCardClick(params) {
    callAnalytics({
      properties: {
        label: params.data.title,
      },
    });
    if (params.data.value.toLowerCase() === 'shared interest') {
      storeTopic({ value: ['Hobby'] });
    } else {
      storeGender({ value: params.data.value });
    }
    if (groupTypeLocation === CATEGORIES_DATA['In-Person'].card.value) {
      setStep(2);
    } else {
      onSearchComplete();
    }
  }

  /**
   * Handler function for card/element click of category step component.
   *
   * @param {object} params - The function params object.
   * @param {object} params.data - The category data object associated with the click.
   * @param {Event} params.event - The Event object associated with the click.
   */
  function handleCategoryStepClick(params) {
    if (step === 0) {
      handleGroupTypeLocationCardClick(params);
    } else if (step === 1) {
      handleGroupTypeCardClick(params);
    }
  }

  /**
   * Handler function for "Back" button click.
   */
  function handleBackClick() {
    callAnalytics({
      properties: {
        label: STRINGS.global.back,
      },
    });
    if (step === 1) {
      meetingType.forEach((type) => {
        storeMeetingType({ value: type });
      });
    } else if (step === 2) {
      genders.forEach((gender) => {
        storeGender({ value: gender });
      });
      topics.forEach((topic) => {
        storeTopic({ value: topic });
      });
    }
    setStep(step - 1);
  }

  /**
   * Handler function for "Next" button click.
   */
  function handleNextClick() {
    callAnalytics({
      properties: {
        label: STRINGS.global.next,
      },
    });
    onSearchComplete();
  }

  /**
   * Handler function for "Skip" button click.
   */
  function handleSkipClick() {
    callAnalytics({
      properties: {
        label: STRINGS.global.skip,
      },
    });
    if (step === 0) {
      setGroupTypeLocation(CATEGORIES_DATA['In-Person'].card.value);
      setStep(step + 2);
    } else {
      onSearchComplete();
    }
  }

  return (
    <div
      className="category-based-search container"
      data-testid="lg-category-based-search"
    >
      <div>
        <div className="text-center">
          <CategoryStep
            campusList={campusList}
            filteredList={filteredList || []}
            groupTypeLocation={groupTypeLocation}
            onClick={handleCategoryStepClick}
            stepNumber={step}
          />
        </div>
      </div>
      <div>
        <div>
          <CategoryButtons
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            onSkipClick={handleSkipClick}
            stepNumber={step}
          />
        </div>
      </div>
    </div>
  );
}

export default CategoryBasedSearch;
