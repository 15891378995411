/**
 * @module AlgoliaSearchContext
 */
import React from 'react';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia';
import axios from 'axios';
import PropTypes from 'prop-types';
import cleanDeep from 'clean-deep';
import { v4 as uuidv4 } from 'uuid';
import states from 'us-state-codes';
import { getName as getCountryFromCode } from 'country-list';
import qs from 'qs';
import { algoliasearch } from 'algoliasearch';
import { getLifeGroups } from '../api/lifegroups';
import { logError } from '../utils/errorLogger';
import { ALGOLIA_OVERRIDES, LG_API_OVERRIDES } from '../utils/override_data';
import {
  getMetroGroupForLocation,
  scrollToTop as scrollToTopOfWindow,
} from '../utils/helpers';
import {
  ALGOLIA_CONFIG,
  CAMPUS_VARIATIONS,
  DEFAULT_CONTEXT_STATE_VALUES,
  ENDPOINT_WORKSPACE_MAP,
  ENVIRONMENT,
  GROUP_TYPES_MAP,
  LGS_API_CONFIG,
  LIFECHURCH_ONLINE_LOCATIONS,
  MGNL_ENV_VARS,
  OVERRIDE_ALGOLIA_QUERY_KEY,
} from '../utils/constants';

export const AlgoliaSearchContext = React.createContext({
  age: null,
  algoliaFacets: null,
  campusList: null,
  campusSearch: null,
  childrenValue: null,
  churchOnlineLocation: null,
  churchOnlineLocations: null,
  countrySearch: null,
  days: null,
  fetchLifeGroupData: null,
  fetchLocationData: null,
  fetchRecommendations: null,
  filteredList: null,
  genders: null,
  getDataForAnalytics: null,
  getObject: null,
  groupTypes: null,
  indexName: null,
  init: null,
  isAlgoliaDataFetched: null,
  isLocationFiltersToggled: null,
  isMoreFiltersToggled: null,
  isPeopleFiltersToggled: null,
  isSearchResultsUpdatedByFilterSearch: null,
  isSearchTrayExpanded: null,
  isTopicFiltersToggled: null,
  keywords: null,
  meetingFrequency: null,
  meetingType: null,
  overrideAlgolia: null,
  recommendedResults: null,
  removeCampus: null,
  resetCampuses: null,
  resetChurchOnlineLocation: null,
  resetChildren: null,
  resetDays: null,
  resetFilters: null,
  resetGenders: null,
  resetMeetingFrequency: null,
  resetMeetingType: null,
  resetOverrideAlgolia: null,
  resetSeasonOfLife: null,
  resetTopics: null,
  resultsPagination: null,
  seasonOfLife: null,
  searchAndFilterData: null,
  searchResults: null,
  storeAge: null,
  storeCampus: null,
  storeChurchOnlineLocation: null,
  storeDay: null,
  storeGender: null,
  storeGroupType: null,
  storeKeywords: null,
  storeMeetingFrequency: null,
  storeMeetingType: null,
  storeOverrideAlgolia: null,
  storeSeasonOfLife: null,
  storeTopic: null,
  toggleLocationFilters: null,
  toggleMoreFilters: null,
  togglePeopleFilters: null,
  toggleSearchTray: null,
  toggleTopicFilters: null,
  topics: null,
  triggerKeywordUpdate: null,
});
AlgoliaSearchContext.displayName = 'AlgoliaSearchContext';

/**
 * React Context Provider for Algolia Search service.
 *
 * @param {object} props - The context component props object.
 * @param {React.ReactNode} props.children - The React children around which the context provider is wrapped.
 *
 * @returns {React.ReactElement} The Algolia Search Context Provider.
 */
export function AlgoliaSearchProvider({ children, ...props }) {
  /**
   * Local variables not needed to be exposed or available by context consumer.
   */
  const appId = process.env.ALGOLIA_APP_ID;
  const searchApiKey = process.env.ALGOLIA_SEARCH_API_KEY;
  const indexName = process.env.ALGOLIA_SEARCH_INDEX_NAME;
  const client = algoliasearch(appId, searchApiKey);
  const [parseAndUpdateUrl, setParseAndUpdateUrl] = React.useState(true);
  const [isUrlParsed, setIsUrlParsed] = React.useState(false);

  const [age, setAge] = React.useState(DEFAULT_CONTEXT_STATE_VALUES.age);
  const [algoliaFacets, setAlgoliaFacets] = React.useState({});
  const [campusList, setCampusList] = React.useState([]);
  const [campusSearch, setCampusSearch] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.campusSearch,
  );
  const [childrenValue, setChildrenValue] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.children,
  );
  const [churchOnlineLocation, setChurchOnlineLocation] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.churchOnlineLocation,
  );
  const [churchOnlineLocations, setChurchOnlineLocations] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.countrySearch,
  );
  const [days, setDays] = React.useState(DEFAULT_CONTEXT_STATE_VALUES.days);
  const [filteredList, setFilteredList] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.filteredList,
  );
  const [genders, setGenders] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.genders,
  );
  const [groupTypes, setGroupTypes] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.groupTypes,
  );
  const [isAlgoliaDataFetched, setIsAlgoliaDataFetched] = React.useState(false);
  const [isInitialDataFetched, setIsInitialDataFetched] = React.useState(false);
  const [isLocationFiltersToggled, setIsLocationFiltersToggled] =
    React.useState(DEFAULT_CONTEXT_STATE_VALUES.isLocationFiltersToggled);
  const [isMoreFiltersToggled, setIsMoreFiltersToggled] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.isMoreFiltersToggled,
  );
  const [
    isSearchResultsUpdatedByFilterSearch,
    setIsSearchResultsUpdatedByFilterSearch,
  ] = React.useState(false);
  const [resultsPagination, setResultsPagination] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.resultsPagination,
  );
  const [isPeopleFiltersToggled, setIsPeopleFiltersToggled] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.isPeopleFiltersToggled,
  );
  const [isSearchTrayExpanded, setIsSearchTrayExpanded] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.isSearchTrayExpanded,
  );
  const [isTopicFiltersToggled, setIsTopicFiltersToggled] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.isTopicFiltersToggled,
  );
  const [keywords, setKeywords] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.keywords,
  );
  const [keywordLength, setKeywordLength] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.keywords.length,
  );
  const [meetingFrequency, setMeetingFrequency] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.meetingFrequency,
  );
  const [meetingType, setMeetingType] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.meetingType,
  );
  const [overrideAlgolia, setOverrideAlgolia] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.overrideAlgolia,
  );
  const [recommendedResults, setRecommendedResults] = React.useState([]);
  const [seasonOfLife, setSeasonOfLife] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.seasonOfLife,
  );
  const [searchResults, setSearchResults] = React.useState([]);
  const [topics, setTopics] = React.useState(
    DEFAULT_CONTEXT_STATE_VALUES.topics,
  );

  // -----------------------------------------------------------------------------
  //  Start: Toggle/Trigger Convenience Methods
  // -----------------------------------------------------------------------------
  /**
   * Convenience function to set the Location filters toggled state.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {boolean} params.value - Boolean flag denoting the toggle status.
   */
  const toggleLocationFilters = React.useCallback(
    ({ callback, value }) => {
      setIsLocationFiltersToggled(value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [isLocationFiltersToggled],
  );

  /**
   * Convenience function to set the More Filters toggled state.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {boolean} params.value - Boolean flag denoting the toggle status.
   */
  const toggleMoreFilters = React.useCallback(
    ({ callback, value }) => {
      setIsMoreFiltersToggled(value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [isMoreFiltersToggled],
  );

  /**
   * Convenience function to set the People filters toggled state.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {boolean} params.value - Boolean flag denoting the toggle status.
   */
  const togglePeopleFilters = React.useCallback(
    ({ callback, value }) => {
      setIsPeopleFiltersToggled(value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [isPeopleFiltersToggled],
  );

  /**
   * Convenience function to set the search tray expanded state.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {boolean} params.value - Boolean flag denoting the expanded status.
   */
  const toggleSearchTray = React.useCallback(
    ({ callback, value }) => {
      setIsSearchTrayExpanded(value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [isSearchTrayExpanded],
  );

  /**
   * Convenience function to set the Topics filters toggled state.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {boolean} params.value - Boolean flag denoting the toggle status.
   */
  const toggleTopicFilters = React.useCallback(
    ({ callback, value }) => {
      setIsTopicFiltersToggled(value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [isTopicFiltersToggled],
  );

  /**
   * Convenience function to set the keyword length, which acts as a trigger
   * when changed, to fetch LifeGroup data.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {string} params.value - The day value.
   */
  const triggerKeywordUpdate = React.useCallback(
    ({ callback, value }) => {
      setKeywordLength(value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [keywordLength],
  );
  // ---------------------------------------------------------------------------
  //  End: Toggle/Trigger Convenience Methods
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  //  Start: Setter/Resetter Methods
  // ---------------------------------------------------------------------------
  /**
   * Convenience function to set the Age value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {string} params.value - The Age value.
   */
  const storeAge = React.useCallback(
    ({ callback, value }) => {
      setAge(() => {
        return value;
      });
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [age],
  );

  /**
   * Convenience function to reset age options.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetAge = React.useCallback(
    ({ callback } = {}) => {
      setAge(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.age;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.age);
      }
    },
    [age],
  );

  /**
   * Convenience function to set the specified campus, adding it to the filtered list of campuses.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function triggered when logical functionality is done.
   * @param {string} params.campus - The campus location slug value.
   */
  const storeCampus = React.useCallback(
    ({ callback, campus }) => {
      const currentCampuses = [...filteredList];
      let newCampuses;
      if (Array.isArray(campus)) {
        newCampuses = [...currentCampuses, ...campus];
      } else {
        newCampuses = [...currentCampuses, campus];
      }
      setFilteredList(() => {
        return [...newCampuses];
      });
      if (callback && typeof callback === 'function') {
        callback(newCampuses);
      }
    },
    [filteredList],
  );

  /**
   * Convenience function to remove the specified campus from the filtered list of campus locations.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function triggered when logical functionality is done.
   * @param {LifeChurchCampus} params.campus - The campus location data object.
   */
  const removeCampus = React.useCallback(
    ({ callback, campus }) => {
      const currentCampuses = [...filteredList];
      let newCampuses;
      if (Array.isArray(campus)) {
        newCampuses = currentCampuses.filter((c) => !campus.includes(c));
      } else {
        newCampuses = currentCampuses.filter((c) => c !== campus);
      }
      setFilteredList(() => {
        return [...newCampuses];
      });
      if (callback && typeof callback === 'function') {
        callback(newCampuses);
      }
    },
    [filteredList],
  );

  /**
   * Convenience function to reset the filtered list of campuses to the default value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function triggered when logical functionality is done.
   */
  const resetCampuses = React.useCallback(
    ({ callback } = {}) => {
      setFilteredList(DEFAULT_CONTEXT_STATE_VALUES.filteredList);
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.filteredList);
      }
    },
    [filteredList],
  );

  /**
   * Convenience function to set the specified campus search value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function triggered when logical functionality is done.
   * @param {string} params.value - The campus search value.
   */
  const storeCampusSearch = React.useCallback(
    ({ callback, value }) => {
      setCampusSearch(() => {
        return value;
      });
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [campusSearch],
  );

  /**
   * Convenience function to set the children state array value with the addition of the specified value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The children value.
   */
  const storeChildren = React.useCallback(
    ({ callback, value }) => {
      let arr = [...childrenValue];
      if (Array.isArray(value)) {
        value.forEach((childValue) => {
          if (arr.includes(childValue)) {
            arr = arr.filter((c) => c !== childValue);
          } else {
            arr = [...arr, childValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((c) => c !== value);
      } else {
        arr = [...arr, value];
      }
      setChildrenValue(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [childrenValue],
  );

  /**
   * Convenience function to reset children options.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetChildren = React.useCallback(
    ({ callback } = {}) => {
      setChildrenValue(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.children;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.children);
      }
    },
    [childrenValue],
  );

  /**
   * Convenience function to set the specified Life.Church Online location.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function triggered when logical functionality is done.
   * @param {LifeChurchCampus} params.location - The Life.Church Online location data object.
   */
  const storeChurchOnlineLocation = React.useCallback(
    ({ callback, location }) => {
      if (location?.name && location?.slug) {
        setChurchOnlineLocation(() => {
          return location;
        });
        if (callback && typeof callback === 'function') {
          callback(location);
        }
      } else if (callback && typeof callback === 'function') {
        callback(churchOnlineLocation);
      }
    },
    [churchOnlineLocation],
  );

  /**
   * Convenience function to reset the Life.Church Online location to the default value.
   */
  const resetChurchOnlineLocation = React.useCallback(
    ({ callback } = {}) => {
      setChurchOnlineLocation(
        DEFAULT_CONTEXT_STATE_VALUES.churchOnlineLocation,
      );
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.churchOnlineLocation);
      }
    },
    [churchOnlineLocation],
  );

  /**
   * Convenience function to set the specified country search value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function triggered when logical functionality is done.
   * @param {string} params.value - The country search value.
   */
  const storeCountrySearch = React.useCallback(
    ({ callback, value }) => {
      setCountrySearch(() => {
        return value;
      });
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [countrySearch],
  );

  /**
   * Convenience function to set the days state array value with the addition of the specified day.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The day value.
   */
  const storeDay = React.useCallback(
    ({ callback, value }) => {
      let arr = [...days];
      if (Array.isArray(value)) {
        value.forEach((dayValue) => {
          if (arr.includes(dayValue)) {
            arr = arr.filter((day) => day !== dayValue);
          } else {
            arr = [...arr, dayValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((day) => day !== value);
      } else {
        arr = [...arr, value];
      }
      setDays(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [days],
  );

  /**
   * Convenience function to reset days.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetDays = React.useCallback(
    ({ callback } = {}) => {
      setDays(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.days;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.days);
      }
    },
    [days],
  );

  /**
   * Convenience function to set the gender state array value with the addition of the specified gender.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The gender value.
   */
  const storeGender = React.useCallback(
    ({ callback, value }) => {
      let arr = [...genders];
      if (Array.isArray(value)) {
        value.forEach((genderValue) => {
          if (arr.includes(genderValue)) {
            arr = arr.filter((gender) => gender !== genderValue);
          } else {
            arr = [...arr, genderValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((gender) => gender !== value);
      } else {
        arr = [...arr, value];
      }
      setGenders(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [genders],
  );

  /**
   * Convenience function to reset genders.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetGenders = React.useCallback(
    ({ callback } = {}) => {
      setGenders(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.genders;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.genders);
      }
    },
    [genders],
  );

  /**
   * Convenience function to set the Group Type value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {string} params.value - The Group Type value.
   */
  const storeGroupType = React.useCallback(
    ({ callback, value }) => {
      setGroupTypes(() => {
        return value;
      });
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [groupTypes],
  );

  /**
   * Convenience function to set the Keywords value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {string} params.value - The Keywords value.
   */
  const storeKeywords = React.useCallback(
    ({ callback, value }) => {
      setKeywords(() => {
        return value;
      });
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [keywords],
  );

  /**
   * Convenience function to set the meeting frequency state array value with the addition of the specified value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The meeting frequency value.
   */
  const storeMeetingFrequency = React.useCallback(
    ({ callback, value }) => {
      let arr = [...meetingFrequency];
      if (Array.isArray(value)) {
        value.forEach((freqValue) => {
          if (arr.includes(freqValue)) {
            arr = arr.filter((f) => f !== freqValue);
          } else {
            arr = [...arr, freqValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((f) => f !== value);
      } else {
        arr = [...arr, value];
      }
      setMeetingFrequency(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [meetingFrequency],
  );

  /**
   * Convenience function to reset meeting frequency.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetMeetingFrequency = React.useCallback(
    ({ callback } = {}) => {
      setMeetingFrequency(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.meetingFrequency;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.meetingFrequency);
      }
    },
    [meetingFrequency],
  );

  /**
   * Convenience function to set the meeting type state array value with the addition of the specified value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The meeting type value.
   */
  const storeMeetingType = React.useCallback(
    ({ callback, value }) => {
      let arr = [...meetingType];
      if (Array.isArray(value)) {
        value.forEach((typeValue) => {
          if (arr.includes(typeValue)) {
            arr = arr.filter((t) => t !== typeValue);
          } else {
            arr = [...arr, typeValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((t) => t !== value);
      } else {
        arr = [...arr, value];
      }
      setMeetingType(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [meetingType],
  );

  /**
   * Convenience function to reset meeting type.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetMeetingType = React.useCallback(
    ({ callback } = {}) => {
      setMeetingType(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.meetingType;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.meetingType);
      }
    },
    [meetingType],
  );

  /**
   * Convenience function to set the override Algolia API logic and use local data.
   *
   * Important: This functionality is only intended for internal testing and load tests.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {boolean} params.value - Boolean flag denoting whether or not to override Algolia and use local data.
   */
  const storeOverrideAlgolia = React.useCallback(
    ({ callback, value }) => {
      window.lcLtOverride = !ENVIRONMENT.production ? value : false;
      setOverrideAlgolia(() => {
        return value;
      });
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    },
    [overrideAlgolia],
  );

  /**
   * Convenience function to reset Algolia override value.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetOverrideAlgolia = React.useCallback(
    ({ callback } = {}) => {
      window.lcLtOverride = DEFAULT_CONTEXT_STATE_VALUES.overrideAlgolia;
      setOverrideAlgolia(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.overrideAlgolia;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.overrideAlgolia);
      }
    },
    [overrideAlgolia],
  );

  /**
   * Convenience function to set the seasonOfLife state array value with the addition of the specified season of life.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The season of life value.
   */
  const storeSeasonOfLife = React.useCallback(
    ({ callback, value }) => {
      let arr = [...seasonOfLife];
      if (Array.isArray(value)) {
        value.forEach((solValue) => {
          if (arr.includes(solValue)) {
            arr = arr.filter((sol) => sol !== solValue);
          } else {
            arr = [...arr, solValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((sol) => sol !== value);
      } else {
        arr = [...arr, value];
      }
      setSeasonOfLife(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [seasonOfLife],
  );

  /**
   * Convenience function to reset seasonOfLife.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetSeasonOfLife = React.useCallback(
    ({ callback } = {}) => {
      setSeasonOfLife(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.seasonOfLife;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.seasonOfLife);
      }
    },
    [seasonOfLife],
  );

  /**
   * Convenience function to set the topics state array value with the addition of the specified topic.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {Array|string} params.value - The topic value.
   */
  const storeTopic = React.useCallback(
    ({ callback, value }) => {
      let arr = [...topics];
      if (Array.isArray(value)) {
        value.forEach((topicValue) => {
          if (arr.includes(topicValue)) {
            arr = arr.filter((t) => t !== topicValue);
          } else {
            arr = [...arr, topicValue];
          }
        });
      } else if (arr.includes(value)) {
        arr = arr.filter((topic) => topic !== value);
      } else {
        arr = [...arr, value];
      }
      setTopics(() => {
        return arr;
      });
      if (callback && typeof callback === 'function') {
        callback(arr);
      }
    },
    [topics],
  );

  /**
   * Convenience function to reset topics.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetTopics = React.useCallback(
    ({ callback } = {}) => {
      setTopics(() => {
        return DEFAULT_CONTEXT_STATE_VALUES.topics;
      });
      if (callback && typeof callback === 'function') {
        callback(DEFAULT_CONTEXT_STATE_VALUES.topics);
      }
    },
    [topics],
  );

  /**
   * Convenience function to reset all filters.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const resetFilters = React.useCallback(
    ({ callback } = {}) => {
      resetAge();
      resetChildren();
      resetDays();
      resetGenders();
      storeKeywords({ value: '' });
      triggerKeywordUpdate({ value: 0 });
      resetMeetingFrequency();
      resetMeetingType();
      resetSeasonOfLife();
      resetTopics();
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
    [
      age,
      childrenValue,
      days,
      genders,
      keywords,
      meetingFrequency,
      meetingType,
      seasonOfLife,
      topics,
    ],
  );
  // ---------------------------------------------------------------------------
  //  End: Setter/Resetter Methods
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  //  Start: URL Sync and Search Methods
  // ---------------------------------------------------------------------------
  /**
   * Convenience function to sync the URL with the search parameters.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   * @param {object} params.params - Data object of params to write to query string.
   */
  const syncUrlWithSearch = React.useCallback(
    ({ callback, params } = {}) => {
      const existingQueryParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      const utmParams = Object.fromEntries(
        Object.entries(existingQueryParams).filter(([key]) =>
          key.startsWith('utm_'),
        ),
      );
      const overrideAlgoliaFlag = Object.fromEntries(
        Object.entries(existingQueryParams).filter(
          ([key]) => key === OVERRIDE_ALGOLIA_QUERY_KEY,
        ),
      );
      if (
        ['1', 'true'].includes(
          overrideAlgoliaFlag?.[OVERRIDE_ALGOLIA_QUERY_KEY],
        ) &&
        !ENVIRONMENT.production
      ) {
        window.lcLtOverride = true;
        setOverrideAlgolia(true);
      } else {
        window.lcLtOverride = false;
      }

      /**
       * If query params include group type of Local Partner, use LG Search API
       * friendly values.
       */
      let paramsForQuery = {};
      let isLocalPartnerAndLegacyLgSearchApi =
        params['filter[group_type]'] &&
        GROUP_TYPES_MAP.localPartner.includes(params['filter[group_type]']);
      if (isLocalPartnerAndLegacyLgSearchApi) {
        /**
         * Slightly simplified version of Algolia in 'else' block, only adding
         * to paramsForQuery if one of the few valid and supported filters for
         * Local Partners needs of legacy LG Search API.
         */
        Object.entries(params).forEach(([paramKey, paramValue]) => {
          if (LGS_API_CONFIG.filters[paramKey]) {
            paramsForQuery[LGS_API_CONFIG.filters[paramKey].queryKey] =
              paramValue;
          }
        });
      } else {
        /**
         * Iterate over provided params and add each key/value pair to a final
         * data object to pass in to cleanDeep() that does NOT have 'facets' as
         * part of the value, but uses the first main part of a facet key value,
         * with the help of ALGOLIA_CONFIG and its facets.[facetName].queryKey.
         *
         * Examples:
         * - 'facets.genders' => 'genders'.
         * - 'facets.topic.name' => 'topic'.
         */
        Object.entries(params).forEach(([paramKey, paramValue]) => {
          if (ALGOLIA_CONFIG.facets[paramKey]) {
            paramsForQuery[ALGOLIA_CONFIG.facets[paramKey].queryKey] =
              paramValue;
          } else if (paramKey === 'meetingLocation') {
            paramsForQuery.meetingLocation = paramValue;
          } else if (paramKey === 'age') {
            paramsForQuery.age = paramValue;
          }
        });
      }

      // Clean params, stringify, and replace window.history state.
      const cleanedParams = cleanDeep({
        ...paramsForQuery,
        keywords: keywordLength >= 3 ? keywords : null,
        ...utmParams,
        ...overrideAlgoliaFlag,
      });
      const search = qs.stringify(cleanedParams, { encode: false });
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?${search}`,
      );

      if (callback && typeof callback === 'function') {
        callback({ path: `${window.location.pathname}?${search}` });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Convenience function to parse the URL query search parameters.
   *
   * @param {object} params - The function params object.
   * @param {Function} params.callback - Optional callback function to invoke upon logical completion.
   */
  const parseQueryParams = React.useCallback(
    ({ callback }) => {
      const existingQueryParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      /**
       * Check for legacy params and attempt to set to new counterparts as able.
       * This includes iterating over the v1AttributeMap in Algolia Config data
       * object and checking the following:
       *
       * If item is Array of data objects:
       * - Iterate over array; check if existing query params have the v1 value.
       * - If v1 value exists in query params:
       *   - If params value is array, add Algolia value to array.
       *   - If params value isn't found, add Algolia value as new array.
       *
       * If item is Object, check for either algoliaCounterpart to use with
       * queryKey, or if only queryKey is on object, use it as new query key.
       */
      Object.entries(ALGOLIA_CONFIG.v1AttributeMap).forEach(
        ([v1QueryKey, v1DataObject]) => {
          if (Array.isArray(v1DataObject)) {
            v1DataObject.forEach((arrayDataObject) => {
              const { algoliaValue, queryKey, v1 } = arrayDataObject;
              if (existingQueryParams[v1QueryKey]?.includes(v1)) {
                if (
                  existingQueryParams[queryKey] &&
                  Array.isArray(existingQueryParams[queryKey])
                ) {
                  existingQueryParams[queryKey].push(algoliaValue);
                } else {
                  existingQueryParams[queryKey] = [algoliaValue];
                }
              }
            });
          } else if (existingQueryParams[v1QueryKey]) {
            if (v1DataObject.queryKey && v1DataObject.algoliaCounterpart) {
              existingQueryParams[v1DataObject.queryKey] =
                v1DataObject.algoliaCounterpart;
            } else if (v1DataObject.queryKey) {
              existingQueryParams[v1DataObject.queryKey] =
                existingQueryParams[v1QueryKey];
            }
          }
        },
      );

      /**
       * Reverse lookup of query string key to get facet name, and compare value
       * against algoliaFacets[facetName], both checks ignoring case for the
       * comparison but setting in state vars with proper capitalization.
       */
      const dataForStateVars = {};

      let queryParamsIncludesLocalPartner = false;
      Object.entries(existingQueryParams).forEach(([queryKey, queryValue]) => {
        if (GROUP_TYPES_MAP.localPartner.includes(queryKey)) {
          queryParamsIncludesLocalPartner = true;
        }
      });

      /**
       * If groupTypes is Local Partners, handle different than Algolia-based
       * facets to support legacy LG Search API.
       */
      if (queryParamsIncludesLocalPartner) {
        Object.entries(existingQueryParams).forEach(
          ([queryKey, queryValue]) => {
            const filterObject = Object.entries(LGS_API_CONFIG.filters).find(
              ([, filterData]) =>
                filterData.queryKey.toLowerCase() === queryKey.toLowerCase(),
            );
            const filterObjectValue =
              Array.isArray(filterObject) && filterObject?.length > 1
                ? filterObject[1]
                : null;
            if (
              filterObjectValue &&
              LGS_API_CONFIG.filters[filterObjectValue.name]
            ) {
              if (Array.isArray(queryValue)) {
                const newArray = [];
                queryValue.forEach((arrayValue) => {
                  Object.keys(LGS_API_CONFIG[filterObjectValue.name]).forEach(
                    (fKey) => {
                      let newValue = arrayValue;
                      if (facetObjectValue.name === 'filter[campus_code]') {
                        let campusObject;
                        if (newValue.length === 3) {
                          campusObject = campusList.find(
                            (campus) =>
                              campus.slug.toLowerCase() ===
                              newValue.toLowerCase(),
                          );
                        } else {
                          campusObject = campusList.find(
                            (campus) =>
                              campus.name.toLowerCase() ===
                              newValue.toLowerCase(),
                          );
                        }
                        if (
                          campusObject &&
                          fKey.toLowerCase() === campusObject.name.toLowerCase()
                        ) {
                          newArray.push(campusObject.slug);
                        }
                      } else if (
                        fKey.toLowerCase() === newValue?.toLowerCase()
                      ) {
                        newArray.push(fKey);
                      }
                    },
                  );
                });
                dataForStateVars[facetObjectValue.name] = newArray;
              } else {
                Object.keys(LGS_API_CONFIG[facetObjectValue.name]).forEach(
                  (fKey) => {
                    if (fKey.toLowerCase() === queryValue.toLowerCase()) {
                      dataForStateVars[facetObjectValue.name] = fKey;
                    }
                  },
                );
              }
            }
          },
        );
      } else {
        Object.entries(existingQueryParams).forEach(
          ([queryKey, queryValue]) => {
            const facetObject = Object.entries(ALGOLIA_CONFIG.facets).find(
              ([, facetData]) =>
                facetData.queryKey.toLowerCase() === queryKey.toLowerCase(),
            );
            const facetObjectValue =
              Array.isArray(facetObject) && facetObject?.length > 1
                ? facetObject[1]
                : null;
            if (facetObjectValue && algoliaFacets[facetObjectValue.name]) {
              /**
               * If an array, iterate over queryValue and push facet key to array
               * which is added to dataForStateVars after iteration is complete.
               */
              if (Array.isArray(queryValue)) {
                const newArray = [];
                queryValue.forEach((arrayValue) => {
                  Object.keys(algoliaFacets[facetObjectValue.name]).forEach(
                    (fKey) => {
                      let newValue = arrayValue;
                      /**
                       * If facet is campuses and value of currently-iterated over
                       * campus is 3 characters long, assume it is slug. Otherwise
                       * assume full name.
                       *
                       * Note: This is presently intentionally verbose with very
                       * separate and distinct conditionals to ensure accuracy.
                       * This will likely be updated to be more concise when doing
                       * the work for supporting legacy params and the more full
                       * support for all types of params is in place.
                       */
                      if (facetObjectValue.name === 'facets.campuses') {
                        let campusObject;
                        if (newValue.length === 3) {
                          campusObject = campusList.find(
                            (campus) =>
                              campus.slug.toLowerCase() ===
                              newValue.toLowerCase(),
                          );
                        } else {
                          campusObject = campusList.find(
                            (campus) =>
                              campus.name.toLowerCase() ===
                              newValue.toLowerCase(),
                          );
                        }
                        if (
                          campusObject &&
                          fKey.toLowerCase() === campusObject.name.toLowerCase()
                        ) {
                          newArray.push(campusObject.slug);
                        }
                      } else if (
                        fKey.toLowerCase() === newValue?.toLowerCase()
                      ) {
                        newArray.push(fKey);
                      }
                    },
                  );
                });
                dataForStateVars[facetObjectValue.name] = newArray;
              } else {
                Object.keys(algoliaFacets[facetObjectValue.name]).forEach(
                  (fKey) => {
                    if (fKey.toLowerCase() === queryValue.toLowerCase()) {
                      dataForStateVars[facetObjectValue.name] = fKey;
                    }
                    if (facetObjectValue.name.toLowerCase() === 'facets.type') {
                      if (
                        GROUP_TYPES_MAP.lifeGroups.includes(
                          queryValue.toLowerCase(),
                        ) ||
                        GROUP_TYPES_MAP.localPartner.includes(
                          queryValue.toLowerCase(),
                        )
                      ) {
                        dataForStateVars['facets.type'] = queryValue;
                      }
                    }
                  },
                );
              }
            } else if (queryKey.toLowerCase() === 'age') {
              dataForStateVars.age = queryValue;
            }
          },
        );
      }

      /**
       * Match up state vars data with setter to store in state.
       *
       * Important: Intentionally doing individual conditional checks to ensure
       * proper setters triggered for additional bulletproofed logic. And each
       * has its own conditional so as to NOT overwrite any existing state
       * if the URL data that is in the dataForStateVars is null/undefined.
       */
      // Age
      if (dataForStateVars.age) {
        storeAge({
          value: dataForStateVars.age,
        });
      }

      // Campuses
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.campuses'].name]) {
        storeCampus({
          campus:
            dataForStateVars[ALGOLIA_CONFIG.facets['facets.campuses'].name],
        });
      } else if (
        dataForStateVars[LGS_API_CONFIG.filters['filter[campus_code]'].name]
      ) {
        storeCampus({
          campus:
            dataForStateVars[
              LGS_API_CONFIG.filters['filter[campus_code]'].name
            ],
        });
      }

      // Children
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.children'].name]) {
        storeChildren({
          value:
            dataForStateVars[ALGOLIA_CONFIG.facets['facets.children'].name],
        });
      }

      // Genders
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.genders'].name]) {
        storeGender({
          value: dataForStateVars[ALGOLIA_CONFIG.facets['facets.genders'].name],
        });
      }

      // Days
      if (
        dataForStateVars[
          ALGOLIA_CONFIG.facets['facets.meetingDayOfWeekFull'].name
        ]
      ) {
        storeDay({
          value:
            dataForStateVars[
              ALGOLIA_CONFIG.facets['facets.meetingDayOfWeekFull'].name
            ],
        });
      }

      // Frequency
      if (
        dataForStateVars[ALGOLIA_CONFIG.facets['facets.meetingFrequency'].name]
      ) {
        storeMeetingFrequency({
          value:
            dataForStateVars[
              ALGOLIA_CONFIG.facets['facets.meetingFrequency'].name
            ],
        });
      }

      // Keywords
      if (dataForStateVars.keywords) {
        storeKeywords({ value: dataForStateVars.keywords });
      }

      // Meeting Location
      if (
        dataForStateVars[ALGOLIA_CONFIG.facets['meetingLocation.country'].name]
      ) {
        storeChurchOnlineLocation({
          location: churchOnlineLocations.find(
            (loc) =>
              loc.slug.toLowerCase() ===
              dataForStateVars[
                ALGOLIA_CONFIG.facets['meetingLocation.country'].name
              ].toLowerCase(),
          ),
        });
      }

      // Meeting Type
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.meetingType'].name]) {
        storeMeetingType({
          value:
            dataForStateVars[ALGOLIA_CONFIG.facets['facets.meetingType'].name],
        });
      }

      // Season of Life
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.seasonOfLife'].name]) {
        storeSeasonOfLife({
          value:
            dataForStateVars[ALGOLIA_CONFIG.facets['facets.seasonOfLife'].name],
        });
      }

      // Topic
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.topic.name'].name]) {
        storeTopic({
          value:
            dataForStateVars[ALGOLIA_CONFIG.facets['facets.topic.name'].name],
        });
      }

      // Type
      if (dataForStateVars[ALGOLIA_CONFIG.facets['facets.type'].name]) {
        storeGroupType({
          value: dataForStateVars[ALGOLIA_CONFIG.facets['facets.type'].name],
        });
      } else if (
        dataForStateVars[LGS_API_CONFIG.filters['filter[group_type]'].name]
      ) {
        storeGroupType({
          value:
            dataForStateVars[LGS_API_CONFIG.filters['filter[group_type]'].name],
        });
      }

      syncUrlWithSearch({
        callback: () => {
          if (callback && typeof callback === 'function') {
            callback();
          }
        },
        params: dataForStateVars,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      age,
      algoliaFacets,
      campusList,
      children,
      churchOnlineLocation,
      days,
      filteredList,
      genders,
      groupTypes,
      meetingFrequency,
      meetingType,
      overrideAlgolia,
      seasonOfLife,
      topics,
    ],
  );
  // ---------------------------------------------------------------------------
  //  End: URL Sync and Search Methods
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  //  Start: Algolia and Data Retrieval Methods
  // ---------------------------------------------------------------------------
  /**
   * Convenience function to initialize Algolia with an initial search to
   * retrieve and set facets in the context state.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   */
  const initAlgolia = React.useCallback(
    async ({ callback } = {}) => {
      try {
        if (
          (overrideAlgolia || window.lcLtOverride) &&
          !ENVIRONMENT.production
        ) {
          setAlgoliaFacets(ALGOLIA_OVERRIDES.algoliaFacets);
          if (callback && typeof callback === 'function') {
            callback({ algoliaFacets: ALGOLIA_OVERRIDES.algoliaFacets });
          }
          setIsAlgoliaDataFetched(true);
        } else {
          const algoliaStatus = await axios.get(process.env.ALGOLIA_STATUS_URL);

          window.isAlgoliaOutage =
            algoliaStatus?.status < 200 || algoliaStatus?.status > 299;

          const { results } = await client.search({
            requests: [
              {
                clickAnalytics: true,
                facets: ['*'],
                hitsPerPage: 1,
                indexName,
                query: '',
              },
            ],
          });
          setIsAlgoliaDataFetched(true);
          if (results?.[0]?.facets) {
            setAlgoliaFacets(results[0].facets);
            if (callback && typeof callback === 'function') {
              callback({ algoliaFacets: results[0].facets });
            }
          } else if (callback && typeof callback === 'function') {
            callback({ algoliaFacets: null });
          }
        }
      } catch (error) {
        logError(error);
        if (callback && typeof callback === 'function') {
          callback({ error });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Function to search and filter Algolia data.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   * @param {Function} [params.facetFilters] - Optional Array of facet filters to search against.
   * @param {object} [params.searchOptions] - Optional data object of search options to use to override default values (Example: { offset: 50, page: 2 }).
   * @param {Function} [params.searchQuery] - Optional search query to search against.
   * @param {boolean} [params.useLegacyLgSearchApi] - Optional boolean flag to override using Algolia in favor of legacy LG Search API.
   *
   * @returns {object} Data object containing Algolia Search results data and/or error data.
   *
   * Source: https://www.algolia.com/doc/rest-api/search/#tag/Search/operation/searchSingleIndex.
   */
  const searchAndFilterData = React.useCallback(
    async ({
      callback,
      facetFilters,
      lgSearchParams,
      searchOptions,
      searchQuery,
      useLegacyLgSearchApi,
    } = {}) => {
      /**
       * Slightly annoying to support LG Search API, but add explicit and
       * intentional conditional to handle API call differently with data shape
       * not quite the same for return data, but normalizing for the sake of
       * consuming components. This results in a fair amount of code that looks
       * duplicated but is needed for explicit separation in the short term
       * until Algolia has Local Partner support and integration.
       */
      if (useLegacyLgSearchApi) {
        try {
          if (
            (overrideAlgolia || window.lcLtOverride) &&
            !ENVIRONMENT.production
          ) {
            const resultsObject = {
              hits: LG_API_OVERRIDES.searchResults.hits,
              length: LG_API_OVERRIDES.searchResults.length,
              nbHits: LG_API_OVERRIDES.searchResults.nbHits,
              offset: LG_API_OVERRIDES.searchResults.offset,
            };
            setSearchResults(resultsObject);
            setResultsPagination(() => {
              return {
                ...LG_API_OVERRIDES.resultsPagination,
              };
            });

            if (callback && typeof callback === 'function') {
              callback({
                resultsPagination: {
                  ...LG_API_OVERRIDES.resultsPagination,
                },
                searchResults: resultsObject || null,
              });
            }
          } else {
            const lgApiResult = await getLifeGroups({
              params: cleanDeep(lgSearchParams),
            });
            const resultsObject = {
              hits: lgApiResult?.data ?? [],
              length: lgApiResult?.data?.length ?? 0,
              nbHits: lgApiResult?.meta['record-count'] ?? 0,
              offset: lgSearchParams['page[offset]'] ?? 0,
            };
            setSearchResults(resultsObject);
            setResultsPagination(() => {
              return {
                isMore:
                  resultsObject?.offset + resultsObject?.length <
                  resultsObject?.nbHits,
                offset: resultsObject?.offset,
                page: Math.ceil(
                  resultsObject?.offset /
                    Math.min(resultsObject?.length, resultsObject?.nbHits),
                ),
                resultsLength: Math.min(
                  resultsObject?.length,
                  resultsObject?.nbHits,
                ),
                total: resultsObject?.nbHits,
              };
            });
            if (callback && typeof callback === 'function') {
              callback({
                resultsPagination: {
                  isMore:
                    resultsObject?.offset + resultsObject?.length <
                    resultsObject?.nbHits,
                  offset: resultsObject?.offset,
                  page: Math.ceil(
                    resultsObject?.offset /
                      Math.min(resultsObject?.length, resultsObject?.nbHits),
                  ),
                  resultsLength: Math.min(
                    resultsObject?.length,
                    resultsObject?.nbHits,
                  ),
                  total: resultsObject?.nbHits,
                },
                searchResults: resultsObject || null,
              });
            }
          }
        } catch (error) {
          logError(error, {
            browserConsole: true,
            bugsnag: false,
            windowAlert: false,
          });
          if (callback && typeof callback === 'function') {
            callback({ error });
          }
        }
      } else {
        try {
          if (
            (overrideAlgolia || window.lcLtOverride) &&
            !ENVIRONMENT.production
          ) {
            setSearchResults(ALGOLIA_OVERRIDES.searchResults);
            setResultsPagination(() => {
              return {
                ...ALGOLIA_OVERRIDES.resultsPagination,
              };
            });
            if (callback && typeof callback === 'function') {
              callback({
                resultsPagination: {
                  ...ALGOLIA_OVERRIDES.resultsPagination,
                },
                searchResults: ALGOLIA_OVERRIDES.searchResults || null,
              });
            }
          } else {
            const algoliaResult = await client.searchSingleIndex({
              indexName,
              searchParams: {
                clickAnalytics: true,
                facetFilters,
                length:
                  searchOptions?.resultsLength ??
                  ALGOLIA_CONFIG.searchApiOptions.resultsLength,
                offset:
                  searchOptions?.offset ??
                  ALGOLIA_CONFIG.searchApiOptions.offset,
                page:
                  searchOptions?.page ?? ALGOLIA_CONFIG.searchApiOptions.page,
                query: searchQuery || '',
                sortFacetValuesBy: 'alpha', // Or 'count'.
                ...searchOptions, // Spreading in case incoming request has more not-directly-specified attributes.
              },
            });
            Log.log('Algolia Search Result Data:');
            Log.log(algoliaResult);
            setSearchResults(algoliaResult);
            try {
              window?.localStorage?.setItem(
                'algolia_query_id',
                algoliaResult?.queryID,
              );
            } catch (error) {
              Log.error(error);
            }
            setResultsPagination(() => {
              return {
                isMore:
                  algoliaResult?.offset + algoliaResult?.length <
                  algoliaResult?.nbHits,
                offset: algoliaResult?.offset,
                page: Math.ceil(
                  algoliaResult?.offset /
                    Math.min(algoliaResult?.length, algoliaResult?.nbHits),
                ),
                resultsLength: Math.min(
                  algoliaResult?.length,
                  algoliaResult?.nbHits,
                ),
                total: algoliaResult?.nbHits,
              };
            });
            if (callback && typeof callback === 'function') {
              callback({
                resultsPagination: {
                  isMore:
                    algoliaResult?.offset + algoliaResult?.length <
                    algoliaResult?.nbHits,
                  offset: algoliaResult?.offset,
                  page: Math.ceil(
                    algoliaResult?.offset /
                      Math.min(algoliaResult?.length, algoliaResult?.nbHits),
                  ),
                  resultsLength: Math.min(
                    algoliaResult?.length,
                    algoliaResult?.nbHits,
                  ),
                  total: algoliaResult?.nbHits,
                },
                searchResults: algoliaResult || null,
              });
            }
          }
        } catch (error) {
          logError(error, {
            browserConsole: true,
            bugsnag: false,
            windowAlert: false,
          });
          if (callback && typeof callback === 'function') {
            callback({ error });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Function to search Algolia for a specific object.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   * @param {string} params.objectID - The unique objectID value of the record to retrieve.
   *
   * @returns {object} Data object containing Algolia Search results data and/or error data.
   */
  const getObject = React.useCallback(async ({ callback, objectID }) => {
    try {
      if ((overrideAlgolia || window.lcLtOverride) && !ENVIRONMENT.production) {
        setSearchResults(ALGOLIA_OVERRIDES.searchResults.hits[0]);
        if (callback && typeof callback === 'function') {
          callback({
            searchResults: ALGOLIA_OVERRIDES.searchResults.hits[0] || null,
          });
        }
      } else {
        const algoliaResult = await client.getObject({
          indexName,
          objectID,
        });
        setSearchResults(algoliaResult);
        if (callback && typeof callback === 'function') {
          callback({ searchResults: algoliaResult || null });
        }
      }
    } catch (error) {
      Log.error(error);
      if (callback && typeof callback === 'function') {
        callback({ error });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience function to fetch recommendations for the specified objectID.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   * @param {Function} [params.facetFilters] - Optional Array of facet filters to search against.
   * @param {boolean} params.objectID - The Algolia data object ID value.
   * @param {object} [params.searchOptions] - Optional data object of search options to use to override default values (Example: { offset: 50, page: 2 }).
   * @param {string} [params.searchQuery] - Optional search query value to include with the search request.
   */
  const fetchRecommendations = React.useCallback(
    async ({
      callback,
      facetFilters,
      objectID,
      searchOptions,
      searchQuery,
    }) => {
      if (!objectID) {
        return null;
      }

      try {
        if (
          (overrideAlgolia || window.lcLtOverride) &&
          !ENVIRONMENT.production
        ) {
          const overrideResults = {
            ...ALGOLIA_OVERRIDES.searchResults,
            hits: [
              ALGOLIA_OVERRIDES.searchResults.hits[0],
              ALGOLIA_OVERRIDES.searchResults.hits[1],
              ALGOLIA_OVERRIDES.searchResults.hits[2],
              ALGOLIA_OVERRIDES.searchResults.hits[3],
            ],
          };
          setRecommendedResults(overrideResults);
          if (callback && typeof callback === 'function') {
            callback({
              recommendedResults: overrideResults || null,
            });
          }
        } else {
          const algoliaResult = await client.searchSingleIndex({
            indexName,
            searchParams: {
              clickAnalytics: true,
              facetFilters,
              filters: `NOT objectID:${objectID}`,
              length:
                ALGOLIA_CONFIG.searchApiOptions.recommendationsResultsLength,
              offset: 0,
              page: 0,
              query: searchQuery || '',
              sortFacetValuesBy: 'alpha', // Or 'count'.
              ...searchOptions, // Spreading in case incoming request has more not-directly-specified attributes.
            },
          });
          Log.log('Algolia Recommendations Search Result Data:');
          Log.log(algoliaResult);
          setRecommendedResults(algoliaResult);
          if (callback && typeof callback === 'function') {
            callback({
              recommendedResults: algoliaResult || null,
            });
          }
        }
      } catch (error) {
        logError(error, {
          browserConsole: true,
          bugsnag: false,
          windowAlert: false,
        });
        if (callback && typeof callback === 'function') {
          callback({ error, recommendedResults: null });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Convenience function to fetch LifeGroup data using the latest state values.
   *
   * Note: If the context state for parseAndUpdateUrl is false, this returns and does not fetch data.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   * @param {boolean} [params.isTriggeredManually] - Optional boolean flag denoting whether or not the fetch call was triggered manually from a component (true) or automatically from context useEffect() (false).
   * @param {object} [params.paramsOverride] - Optional data object of params to override.
   * @param {boolean} [params.scrollToTop] - Optional boolean flag denoting whether or not to auto-scroll to the top of the page following fetching data.
   * @param {object} [params.searchOptions] - Optional data object of search options to use to override default values (Example: { offset: 50, page: 2 }).
   */
  const fetchLifeGroupData = React.useCallback(
    ({
      callback,
      isTriggeredManually,
      paramOverrides,
      scrollToTop = true,
      searchOptions,
    } = {}) => {
      if (!parseAndUpdateUrl) {
        return;
      }
      setIsSearchResultsUpdatedByFilterSearch(!isTriggeredManually);

      /**
       * For the short term, if groupTypes is Local Partners, we need to use the
       * legacy LG Search API rather than Algolia.
       *
       * Note: LG Search API param building taken directly from original LG
       * Search App project, with slight adaptation as needed.
       */
      let params = {};
      let filters;
      if (GROUP_TYPES_MAP.localPartner.includes(groupTypes)) {
        // Handle different params depending if church online is selected
        const buildGroupLocationParams = () => {
          let slugValue = churchOnlineLocation?.slug ?? '';
          if (churchOnlineLocation?.slug === 'all') {
            slugValue = '';
          }
          if (filteredList?.includes('int')) {
            return {
              'filter[group_location]': slugValue,
            };
          }
          return {};
        };

        const campusCodeForOthers =
          !filteredList || filteredList.length === 0
            ? ''
            : filteredList.join(',');
        // Only location, group type, and keywords needed.
        params = {
          'filter[campus_code]': filteredList?.includes('all')
            ? ''
            : campusCodeForOthers,
          'filter[group_type]': 'local-partner',
          'filter[keywords]': keywords,
          'page[limit]':
            searchOptions?.limit ||
            LGS_API_CONFIG.searchApiOptions.resultsLength,
          'page[offset]':
            searchOptions?.offset || LGS_API_CONFIG.searchApiOptions.offset,
        };

        params = {
          ...params,
          ...buildGroupLocationParams(),
          ...paramOverrides,
        };
      } else {
        /**
         * Get object of facet params to sync with URL and set for Algolia search
         * and facetFilters key/value pairs.
         */
        params = {
          'facets.children': childrenValue,
          'facets.campuses': filteredList
            ? filteredList.map((slug) => {
                return campusList.find((campus) => slug === campus.slug)?.name;
              })
            : null,
          'facets.genders': genders,
          'facets.meetingDayOfWeekFull': days,
          'facets.meetingFrequency': meetingFrequency,
          'facets.meetingType': meetingType,
          'facets.seasonOfLife': seasonOfLife,
          'facets.topic.name': topics,
          'facets.type': groupTypes,
          ...paramOverrides,
        };
        if (age?.length) {
          filters = `facets.ageRangeBottom <= ${age} AND facets.ageRangeTop >= ${age}`;
        }
        if (
          filteredList &&
          filteredList.length === 1 &&
          filteredList[0] === 'int' &&
          churchOnlineLocation &&
          churchOnlineLocation.slug !== 'all'
        ) {
          params['meetingLocation.country'] = churchOnlineLocation.slug;
        }
      }

      /**
       * If churchOnlineLocation is set, and not to "All Countries", add it on
       * to a new object for the sync with search. This is not needed in the
       * params object above, as it's specific to URL syncing and not facet
       * filtering for Algolia.
       */
      const syncParams = {
        ...params,
        'facets.campuses': filteredList,
      };
      if (age?.length && !GROUP_TYPES_MAP.localPartner.includes(groupTypes)) {
        syncParams.age = age;
      }
      if (churchOnlineLocation && churchOnlineLocation.slug !== 'all') {
        syncParams.meetingLocation = churchOnlineLocation.slug;
      }

      /**
       * Use filteredList for campus codes rather than full location names for
       * cleaner and more concise query string values. Note that this is ONLY
       * overridden in the params object for THIS method invocation, as the
       * params data object set above is used and iterated over below when
       * creating the array of filter values by the user, and there is a need
       * for the two to use different values - URL using slug, otherwise, using
       * the campus location name.
       */
      syncUrlWithSearch({
        params: syncParams,
      });

      /**
       * Iterate over each parameter and create a unique array of filter values
       * selected by the user. Ultimately the Algolia Search API will be able to
       * take an array of arrays as the `facetFilters` attribute. For example:
       *
       * facetFilters: [['facets.genders=Men', 'facets.genders=Women'], ['facets.topic=Marriage]].
       *
       * This allows the ability to fulfill the following rule and scenario:
       *
       * Rule: Any LG that has at least one of the selected topics should appear.
       * It's sort of an "or" within a given filter. Parents OR Prayer OR Mentor.
       * But it is an "and" across filters.
       *
       * If a user makes a selection for Topic (Parents, Prayer, Mentor) as well
       * as Gender (Women) and Day (Saturday), then all of the options must meet
       * at least one of the criteria from all three filters. In this specific
       * example, all groups returned in the results should be for women, and all
       * groups must be on Saturday. But they can be tagged with any one of the
       * three topic selections.
       */
      const facetFilters = [];
      Object.entries(params).forEach(([key, value]) => {
        const individualFacetFilters = [];
        if (value && Array.isArray(value)) {
          value.forEach((v) => {
            individualFacetFilters.push(`${key}:${v}`);
          });
        } else if (value) {
          individualFacetFilters.push(`${key}:${value}`);
        }
        if (individualFacetFilters.length) {
          facetFilters.push(individualFacetFilters);
        }
      });

      /**
       * Daisy-chaining callback, as this function takes an optional callback
       * to invoke on logical completion. Additionally, in this instance where
       * the function is called by virtue of useEffect() and changed filter
       * values, it's helpful to call the scrollToTop() helper method to allow
       * the user to see the first part of the results list rather than possibly
       * being partly down on the page.
       */
      searchAndFilterData({
        callback: ({ resultsPagination, searchResults }) => {
          if (scrollToTop) {
            scrollToTopOfWindow();
          }
          if (callback && typeof callback === 'function') {
            callback({ resultsPagination, searchResults });
          }
        },
        facetFilters,
        lgSearchParams: GROUP_TYPES_MAP.localPartner.includes(groupTypes)
          ? params
          : null,
        searchOptions: {
          ...searchOptions,
          filters,
        },
        searchQuery: keywords,
        useLegacyLgSearchApi: GROUP_TYPES_MAP.localPartner.includes(groupTypes),
      });
    },
    [
      age,
      childrenValue,
      churchOnlineLocation,
      days,
      filteredList,
      genders,
      groupTypes,
      isInitialDataFetched,
      keywordLength,
      meetingFrequency,
      meetingType,
      resultsPagination,
      seasonOfLife,
      topics,
    ],
  );

  /**
   * Data fetching function to fetch Life.Church campus locations.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   */
  const fetchLocationData = React.useCallback(
    async ({ callback } = {}) => {
      const response = await getMagnoliaItem({
        caller: 'src/context/MagnoliaContext.js > fetchLocationData',
        mgnlEnvVars: MGNL_ENV_VARS,
        path: encodeURI(`/.rest/delivery/location?public=true`),
        workspaceMap: ENDPOINT_WORKSPACE_MAP,
      }); // NOSONAR
      let newList = response.results
        .sort((a, b) => {
          if (a.name.trim() < b.name.trim()) {
            return -1;
          }
          if (a.name.trim() > b.name.trim()) {
            return 1;
          }
          return 0;
        })
        .filter((item) => item.name.trim() !== 'Central Support Office')
        .map((item) => ({
          id: item.legacyId,
          metro: getMetroGroupForLocation(item.campusCode) || 'none',
          name: CAMPUS_VARIATIONS[item.campusCode]
            ? CAMPUS_VARIATIONS[item.campusCode].algolia
            : item.name.trim(),
          slug: item.campusCode,
          state: states.getStateNameByStateCode(item.primaryAddress?.state),
        }));
      newList = [
        {
          id: 'int',
          metro: '',
          name: 'Life.Church Online',
          slug: 'int',
          state: '',
        },
        ...newList,
      ];
      setCampusList(newList);

      if (callback && typeof callback === 'function') {
        callback();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [campusList],
  );

  /**
   * Data fetching function to retrieve and set Life.Church Online locations.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   */
  const fetchChurchOnlineLocations = ({ callback } = {}) => {
    let list = LIFECHURCH_ONLINE_LOCATIONS.map((item) => ({
      id: uuidv4(),
      name: getCountryFromCode(item) || item,
      slug: item.toLowerCase(),
    }));
    list = _.sortBy(list, [
      (o) => {
        return o.name;
      },
    ]);
    list = [{ id: 'all', name: 'All Countries', slug: 'all' }, ...list];
    setChurchOnlineLocations(list);

    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  /**
   * Initialization method called from consuming component.
   *
   * @param {object} params - The function params object.
   * @param {boolean} [params.isOverrideAlgolia] - Optional boolean flag denoting whether or not to override integration with the Algolia API and use local data instead.
   * @param {boolean} [params.isSearchPage] - Optional boolean flag denoting whether or not the consuming component is in the context of a search page that would require searching and filtering Algolia data.
   */
  const init = React.useCallback(
    (
      { isOverrideAlgolia = false, isSearchPage = true } = {
        isOverrideAlgolia: false,
        isSearchPage: true,
      },
    ) => {
      setParseAndUpdateUrl(isSearchPage);
      storeOverrideAlgolia({
        callback: () => {
          initAlgolia();
        },
        value: !ENVIRONMENT.production ? isOverrideAlgolia : false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // ---------------------------------------------------------------------------
  //  End: Algolia and Data Retrieval Methods
  // ---------------------------------------------------------------------------

  /**
   * Convenience function to gather and return data useful for Segment events.
   */
  const getDataForAnalytics = React.useCallback(() => {
    return {
      form_fields: {
        adults_only: childrenValue.includes(
          ALGOLIA_CONFIG.facets['facets.children'].options.adultsOnly,
        ),
        age_search: age,
        campuses: filteredList,
        campuses_search: campusSearch,
        country_search: countrySearch,
        childcare_available: childrenValue.includes(
          ALGOLIA_CONFIG.facets['facets.children'].options.childcareAvailable,
        ),
        children_welcome: childrenValue.includes(
          ALGOLIA_CONFIG.facets['facets.children'].options.childrenWelcome,
        ),
        genders,
        group_type: groupTypes,
        keyword_search: keywords,
        meeting_day_of_week_full: days,
        meeting_frequency: meetingFrequency,
        meeting_type: meetingType,
        season_of_life: seasonOfLife,
        topics,
      },
      indexName,
      queryId:
        searchResults.queryID ||
        window?.localStorage?.getItem('algolia_query_id'),
    };
  }, [
    age,
    campusList,
    campusSearch,
    childrenValue,
    countrySearch,
    days,
    filteredList,
    genders,
    groupTypes,
    keywords,
    meetingFrequency,
    meetingType,
    searchResults,
    seasonOfLife,
    topics,
  ]);

  /**
   * Convenience effect to continue data retrieval once Algolia initialized and
   * data parsed, while initial data fetched flag is still false.
   *
   * This is intentionally separated from callback functions to ensure all of
   * the state vars are set and updated in the sequential order needed.
   *
   * Note: There is need to check `paseAndUpdateUrl` value and kick off calls to
   * fetch data BEFORE parseQueryParams is called (in separate useEffect()) to
   * ensure there is campus list data. When there's no need to parse and update
   * the URL (e.g. for Detail page), also set initial data and url parse states.
   * While a bit repetitive, leaving as-is for now with the conditional to make
   * sure all works entirely properly without question or edge case issues.
   */
  React.useEffect(() => {
    if (isAlgoliaDataFetched && !isInitialDataFetched) {
      if (parseAndUpdateUrl) {
        fetchLocationData();
        fetchChurchOnlineLocations();
      } else {
        fetchLocationData();
        fetchChurchOnlineLocations();
        setIsInitialDataFetched(true);
        setIsUrlParsed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlgoliaDataFetched, isInitialDataFetched]);

  /**
   * Convenience effect to trigger parseQueryParams when an intentional and
   * necessary set of data is present: algolia data is fetched, initial data
   * NOT being completely fetched, true value of parseAndUpdateUrl, and array
   * value for campusList being set with length. This is necessary because the
   * parseQueryParams function needs to do a check and convert for campus slug
   * if that is the value type of the campuses query param.
   *
   * Also note that there's no need to call fetchLifeGroupData() here since it
   * is affected and automatically called by virtue of the context state vars
   * being updated when parsing query params and syncing URL.
   */
  React.useEffect(() => {
    if (
      isAlgoliaDataFetched &&
      !isInitialDataFetched &&
      parseAndUpdateUrl &&
      campusList.length > 0
    ) {
      parseQueryParams({
        callback: () => {
          setIsInitialDataFetched(true);
          setIsUrlParsed(true);
        },
      });
    }
  }, [campusList, isAlgoliaDataFetched, isInitialDataFetched]);

  /**
   * Convenience effect to fetch data on facet filtering change, and when a set
   * of conditions is met (initial data fetched, URL is parsed, and state var
   * for parsing and updating URL is true). This effect helps to remove the need
   * to manually trigger re-fetch of data in consuming child components.
   */
  React.useEffect(() => {
    if (isInitialDataFetched && isUrlParsed && parseAndUpdateUrl) {
      setIsSearchResultsUpdatedByFilterSearch(true);
      fetchLifeGroupData();
    }
  }, [
    age,
    childrenValue,
    churchOnlineLocation,
    days,
    filteredList,
    genders,
    groupTypes,
    isInitialDataFetched,
    keywordLength,
    meetingFrequency,
    meetingType,
    seasonOfLife,
    topics,
  ]);

  const value = React.useMemo(
    () => ({
      age,
      algoliaFacets,
      campusList,
      campusSearch,
      childrenValue,
      churchOnlineLocation,
      churchOnlineLocations,
      countrySearch,
      days,
      fetchLifeGroupData,
      fetchLocationData,
      fetchRecommendations,
      filteredList,
      genders,
      getDataForAnalytics,
      getObject,
      groupTypes,
      indexName,
      init,
      isAlgoliaDataFetched,
      isLocationFiltersToggled,
      isMoreFiltersToggled,
      isPeopleFiltersToggled,
      isSearchResultsUpdatedByFilterSearch,
      isSearchTrayExpanded,
      isTopicFiltersToggled,
      keywords,
      meetingFrequency,
      meetingType,
      overrideAlgolia,
      recommendedResults,
      removeCampus,
      resetAge,
      resetCampuses,
      resetChurchOnlineLocation,
      resetChildren,
      resetDays,
      resetFilters,
      resetGenders,
      resetMeetingFrequency,
      resetMeetingType,
      resetOverrideAlgolia,
      resetSeasonOfLife,
      resetTopics,
      resultsPagination,
      seasonOfLife,
      searchAndFilterData,
      searchResults,
      storeAge,
      storeCampus,
      storeCampusSearch,
      storeChildren,
      storeChurchOnlineLocation,
      storeCountrySearch,
      storeDay,
      storeGender,
      storeGroupType,
      storeKeywords,
      storeMeetingFrequency,
      storeMeetingType,
      storeOverrideAlgolia,
      storeSeasonOfLife,
      storeTopic,
      toggleLocationFilters,
      toggleMoreFilters,
      togglePeopleFilters,
      toggleSearchTray,
      toggleTopicFilters,
      topics,
      triggerKeywordUpdate,
    }),
    [
      age,
      algoliaFacets,
      campusList,
      campusSearch,
      childrenValue,
      churchOnlineLocation,
      churchOnlineLocations,
      countrySearch,
      days,
      fetchLifeGroupData,
      fetchLocationData,
      fetchRecommendations,
      filteredList,
      genders,
      getDataForAnalytics,
      getObject,
      groupTypes,
      indexName,
      init,
      isAlgoliaDataFetched,
      isLocationFiltersToggled,
      isMoreFiltersToggled,
      isPeopleFiltersToggled,
      isSearchResultsUpdatedByFilterSearch,
      isSearchTrayExpanded,
      isTopicFiltersToggled,
      keywords,
      meetingFrequency,
      meetingType,
      overrideAlgolia,
      recommendedResults,
      removeCampus,
      resetAge,
      resetCampuses,
      resetChurchOnlineLocation,
      resetChildren,
      resetDays,
      resetFilters,
      resetGenders,
      resetMeetingFrequency,
      resetMeetingType,
      resetOverrideAlgolia,
      resetSeasonOfLife,
      resetTopics,
      resultsPagination,
      seasonOfLife,
      searchAndFilterData,
      searchResults,
      storeAge,
      storeCampus,
      storeCampusSearch,
      storeChildren,
      storeChurchOnlineLocation,
      storeCountrySearch,
      storeDay,
      storeGender,
      storeGroupType,
      storeKeywords,
      storeMeetingFrequency,
      storeMeetingType,
      storeOverrideAlgolia,
      storeSeasonOfLife,
      storeTopic,
      toggleLocationFilters,
      toggleMoreFilters,
      togglePeopleFilters,
      toggleSearchTray,
      toggleTopicFilters,
      topics,
      triggerKeywordUpdate,
    ],
  );

  return (
    <AlgoliaSearchContext.Provider value={value} {...props}>
      {children}
    </AlgoliaSearchContext.Provider>
  );
}

AlgoliaSearchProvider.propTypes = {
  /**
   * The React children.
   */
  children: PropTypes.node,
};
