/**
 * @module Location
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import CountryContainer from '../../CountryContainer';
import LocationsContainer from '../../LocationsContainer';
import Chip from '../../Chip';
import { getIcon } from '../../ui/icon-helper';
import { TAG_COLORS } from '../../../utils/constants';
import { normalize } from '../../../utils/helpers';
import { STRINGS } from '../../../utils/strings';

/**
 * Represents the Location container for mobile filters and search.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The Location component.
 */
function Location(props) {
  const {
    algoliaFacets,
    churchOnlineLocation,
    churchOnlineLocations,
    filteredList,
    meetingType,
  } = useAlgoliaSearch();
  const { onMeetingTypeChanged } = props;

  /**
   * Convenience function to iterate over people facets and add interactive Chip components for each.
   *
   * @returns {React.ReactElement} Series of Chip components for all people facet keys.
   */
  const renderCheckboxes = (facet) => {
    if (!algoliaFacets?.[`facets.${facet}`]) {
      return null;
    }

    const facetArray = Object.keys(algoliaFacets[`facets.${facet}`]);
    const isChecked = (value) => meetingType?.includes(value);

    /**
     * Handler function for Chip click.
     *
     * @param {object} params - The function params object.
     * @param {Event} params.event - The Event object associated with the click.
     * @param {string} params.label - The Chip label.
     */
    function handleChipClick({ event, label }) {
      if (onMeetingTypeChanged && typeof onMeetingTypeChanged === 'function') {
        onMeetingTypeChanged({
          ...event,
          target: {
            ...event.target,
            value: label,
          },
        });
      }
    }

    return facetArray.map((facetKey) => (
      <Chip
        color={isChecked(facetKey) ? TAG_COLORS.topics : TAG_COLORS.default}
        icon={getIcon(
          normalize(facetKey),
          isChecked(facetKey)
            ? TAG_COLORS.topics.content
            : TAG_COLORS.default.content,
        )}
        isBordered={true}
        isInteractive={true}
        key={normalize(facetKey)}
        label={facetKey}
        onClick={(event) => {
          event.stopPropagation();
          handleChipClick({ event, label: facetKey });
        }}
      />
    ));
  };

  return (
    <div className="lg-search-location" data-testid="lg-search-location">
      <div className="lg-filter-section">
        <h3>{STRINGS.searchFilters.labels.meetingType}</h3>
        <div className="chip-container">{renderCheckboxes('meetingType')}</div>
      </div>
      <div className="lg-filter-section">
        <LocationsContainer />
      </div>
      {filteredList?.includes('int') ? (
        <div className="lg-filter-section">
          <CountryContainer
            filterFeature="country"
            id="lg-campus-code"
            mobileLabel={STRINGS.searchFilters.labels.country}
            options={churchOnlineLocations}
            value={churchOnlineLocation?.slug}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Location;
