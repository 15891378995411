/**
 * @module SearchPage
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { callSegmentPage } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import qs from 'qs';
import ScrollToTop from 'react-scroll-up';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import CategoryBasedSearch from '../CategoryBasedSearch';
import SearchContainer from '../SearchContainer';
import ListContainer from '../ListContainer';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import useMagnolia from '../../hooks/useMagnolia';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import {
  APP_NAME,
  ENVIRONMENT,
  EXPERIMENTS,
  EXPERIMENT_URL_SEARCH_OVERRIDES,
  OVERRIDE_ALGOLIA_QUERY_KEY,
} from '../../utils/constants';
import { logError } from '../../utils/errorLogger';
import { getExperimentalCookieValue } from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import ScrollToTopImage from '../../images/scroll_to_top.svg';
import './SearchPage.scss';

/**
 * Represents the main UI view of the Search page with elements for search/filtering and listing of results.
 *
 * @returns {React.ReactElement} The SearchPage component.
 */
function SearchPage() {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { breakpoints, width } = useWindowSize();
  const { footerData, menuData } = useMagnolia();
  const { getDataForAnalytics, init } = useAlgoliaSearch();

  /**
   * Style object is optional, overridden here with values to ensure visibility
   * and more proper placement.
   *
   * For more information, see: https://www.npmjs.com/package/react-scroll-up}.
   */
  const scrollToTopStyle = {
    bottom: 90,
    cursor: 'pointer',
    position: 'fixed',
    right: 20,
    transitionDelay: '0s',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'linear',
  };

  /**
   * Convenience state vars to keep track of experimental flag check and values.
   */
  const [isExperimentalFlagChecked, setIsExperimentalFlagChecked] =
    React.useState(false);
  const [isInCategoryBasedSearch, setIsInCategoryBasedSearch] =
    React.useState(false);

  /**
   * Handler function for search completion.
   */
  function handleCategoryBasedSearchEnd() {
    setIsInCategoryBasedSearch(false);
  }

  /**
   * Single-run effect to determine whether or not experiments are enabled via
   * ENV var value. If enabled, check for experiment cookie and value, matching
   * up with the appropriate values and state value to set.
   */
  React.useEffect(() => {
    if (!isExperimentalFlagChecked && EXPERIMENTS.enabled) {
      const queryParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      /**
       * Only consider setting category-based search if no query params are set,
       * as a way to ensure existing URLs with query params are honored and do not
       * put the user into the experimental search flow.
       */
      if (
        !queryParams ||
        Object.keys(queryParams).length < 1 ||
        EXPERIMENT_URL_SEARCH_OVERRIDES.includes(window.location.search)
      ) {
        const experimentalCookieValue = getExperimentalCookieValue();
        const status =
          experimentalCookieValue ===
          EXPERIMENTS.values.categoryBasedSearch.cookieValue;
        setIsInCategoryBasedSearch(status);
      }
      setIsExperimentalFlagChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EXPERIMENTS, isExperimentalFlagChecked]);

  /**
   * Handler function for window scroll event.
   *
   * Note: Ignoring at present, can revisit and cover later if functionality to
   * stick mobile filter bar is kept and there is desire to update in tests.
   */
  /* istanbul ignore next */
  const handleScroll = () => {
    const filterBarElement = document.getElementById(
      'id-lg-search-options-mobile',
    );
    const filterBarPlaceholderElement = document.getElementById(
      'id-lg-search-options-mobile-placeholder',
    );
    const filterBarWrapperElement = document.getElementById(
      'id-lg-search-options-mobile-wrapper',
    );
    if (
      width <= breakpoints.medium &&
      filterBarElement &&
      filterBarPlaceholderElement
    ) {
      const isMobileFilterBarAboveViewportTop =
        filterBarWrapperElement.offsetTop < window.scrollY;
      if (isMobileFilterBarAboveViewportTop) {
        filterBarElement.style.boxShadow = '0 1px 6px rgba(0, 0, 0, 0.15)';
        filterBarElement.style.position = 'fixed';
        filterBarElement.style.top = '0px';
      } else {
        filterBarElement.style.boxShadow = 'none';
        filterBarElement.style.position = 'relative';
        filterBarElement.style.top = '0px';
      }
    }
  };

  /**
   * Single-run convenience effect to add class name to body tag for specific
   * App class attribution and styles (by virtue of SearchPage.scss), and to
   * initialize the AlgoliaSearchContext.
   *
   * Additionally, it fetches the user from local storage to pass along to
   * Segment for analytics and the page call.
   *
   * Also, this adds a scroll listener to help the mobile filters menu to be
   * made visible and fixed when a user starts to scroll up to make updating
   * filters easier after having scrolled down.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    document?.body?.classList.add('search-page-container');

    /* istanbul ignore next */
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    /* istanbul ignore next */
    const overrideAlgolia =
      ((queryParams &&
        ['1', 'true'].includes(queryParams?.[OVERRIDE_ALGOLIA_QUERY_KEY])) ||
        window.lcLtOverride) &&
      !ENVIRONMENT.production;
    window.lcLtOverride = overrideAlgolia;

    init({ isOverrideAlgolia: overrideAlgolia, isSearchPage: true });

    let storedUser;
    try {
      storedUser = window?.localStorage?.getItem('user_profile')
        ? JSON.parse(window?.localStorage?.getItem('user_profile'))
        : null;

      // Trigger Segment page call.
      callSegmentPage({
        category: '',
        name: APP_NAME,
        properties: {
          experimental_flag: getExperimentalCookieValue(),
          logged_in: !!storedUser,
          path: window?.location?.pathname,
          preferred_campus: userProfileData?.preferred_campus_code || null,
          referrer: document?.referrer || null,
          title: `${APP_NAME} App`,
          url: window.location.href,
          user_id: storedUser?.['https://www.life.church/rock_person_alias_id'],
        },
      });
    } catch (error) {
      logError(error, { bugsnag: false });
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {menuData ? <Header menuData={menuData} /> : null}
      <div className="search-page" data-testid="lg-search-page">
        {isExperimentalFlagChecked ? (
          <>
            {isInCategoryBasedSearch ? (
              <CategoryBasedSearch
                onSearchComplete={handleCategoryBasedSearchEnd}
              />
            ) : (
              <>
                <SearchContainer />
                <ListContainer />
                {width >= breakpoints.medium ? (
                  <div
                    className="lg-scroll-to-top"
                    onClick={() => {
                      const dataForAnalytics = getDataForAnalytics();
                      triggerSegmentTrack({
                        dataForAnalytics,
                        event: ANALYTICS.events.buttonAction,
                        properties: {
                          action: ANALYTICS.actions.clicked,
                          component: ANALYTICS.components.searchPage,
                          component_url: null,
                          label: STRINGS.global.scrollToTop,
                        },
                        user,
                        userProfileData,
                      });
                    }}
                  >
                    <ScrollToTop showUnder={200} style={scrollToTopStyle}>
                      <img
                        alt={STRINGS.global.scrollToTop}
                        data-no-retina={true}
                        src={ScrollToTopImage}
                      />
                    </ScrollToTop>
                  </div>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </div>
      {footerData ? <Footer footerData={footerData} /> : null}
    </>
  );
}

export default SearchPage;
