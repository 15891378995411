/**
 * @module PlaceholderLoader
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import LgCardPlaceholder from '../LgCard/LgCardPlaceholder';
import CircularLoader from '../ui/CircularLoader';
import './PlaceholderLoader.scss';

/**
 * Represents a placeholder loader component with grid of "loading" cards and circular loader with label.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isLegacy - Optional boolean flag denoting whether to use the wireframe and structure of a LgLegacyCard.
 *
 * @returns {React.ReactElement} The PlaceholderLoader component.
 */
function PlaceholderLoader({ isLegacy }) {
  return (
    <div
      className="placeholder-loader container"
      data-testid="lg-placeholder-loader"
    >
      <div
        className={`lg-placeholder-loader-cards ${
          isLegacy ? 'local-partner' : ''
        }`.trim()}
      >
        <LgCardPlaceholder isLegacy={isLegacy} />
        <LgCardPlaceholder isLegacy={isLegacy} />
        <LgCardPlaceholder isLegacy={isLegacy} />
        <LgCardPlaceholder isLegacy={isLegacy} />
      </div>
      <CircularLoader />
      <p>Loading groups...</p>
    </div>
  );
}

export default PlaceholderLoader;
