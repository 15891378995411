/**
 * @module Chip
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { normalize } from '../../utils/helpers';
import './Chip.scss';

/**
 * Represents a small tag-style chip with label and optional color and icon, useful for displaying tags and categories.
 *
 * @param {object} props - The component props object.
 * @param {ChipColors} [props.color] - Optional ChipColors data object with values to override the default chip colors.
 * @param {React.ReactElement} [props.icon] - Optional icon component element to include to the left of the label text.
 * @param {boolean} [props.isBordered] - Boolean flag denoting whether or not the component should include a border. This also requires the `color` prop object to have a `border` value..
 * @param {boolean} [props.isInteractive] - Boolean flag denoting whether or not the component is able to be interacted with.
 * @param {string} props.label - The text value to include on the Chip.
 * @param {Function} [props.onClick] - Optional click handler function for when the component is set to receive interaction.
 *
 * @returns {React.ReactElement} The Chip component.
 */
function Chip({ color, icon, isBordered, isInteractive, label, onClick }) {
  const styles = {};
  if (color?.background) {
    styles.backgroundColor = color.background;
  }
  if (color?.border && isBordered) {
    styles.borderColor = `${color.border}`;
  }
  if (color?.content) {
    styles.color = color?.content;
  }

  return (
    <div
      className={`icon-chip ${isInteractive ? 'interactive' : 'static'} ${
        isBordered ? 'bordered' : ''
      }`.trim()}
      data-label={`chip-${normalize(label?.toLowerCase())}`}
      data-testid="lg-icon-chip"
      onClick={onClick}
      style={styles}
    >
      {icon || null}
      <span className="icon-text">{label}</span>
    </div>
  );
}

export default Chip;
