/**
 * @module CampusCheckbox
 */
/* eslint-disable no-shadow */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import './CampusCheckbox.scss';

/**
 * Represents a checkbox element for a campus.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isMetroChecked] - Boolean flag denoting whether or not a metro area is selected.
 * @param {string} props.name - The name attribute to apply to the HTML form checkbox element.
 * @param {Function} props.setIsMetroChecked - Handler and setter function to set the isMetroChecked value.
 * @param {Function} props.setIsPartial - Handler and setter function to set an isPartial state value in a parent component.
 * @param {string} props.slug - The campus slug value.
 *
 * @returns {React.ReactElement} The CampusCheckbox component.
 */
function CampusCheckbox({
  isMetroChecked,
  name,
  setIsMetroChecked,
  setIsPartial,
  slug,
}) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { filteredList, getDataForAnalytics, removeCampus, storeCampus } =
    useAlgoliaSearch();

  let isChecked = filteredList?.includes(slug);

  /**
   * Handler function for the click event of the checkbox container.
   */
  function handleClick() {
    if (isChecked) {
      removeCampus({
        callback: /* istanbul ignore next */ (newCampuses) => {
          const dataForAnalytics = getDataForAnalytics();
          triggerSegmentTrack({
            dataForAnalytics,
            event: ANALYTICS.events.searchSubmitted,
            properties: {
              action: isChecked
                ? ANALYTICS.actions.toggledOff
                : ANALYTICS.actions.toggledOn,
              component: ANALYTICS.components.locationContainerLocationCheckbox,
              component_url: null,
              eventType: ANALYTICS.eventTypes.view,
              form_fields: {
                ...dataForAnalytics?.form_fields,
                campuses: newCampuses,
              },
              form_name: ANALYTICS.forms.search,
              label: name,
            },
            user,
            userProfileData,
          });
        },
        campus: slug,
      });
    } else {
      storeCampus({
        callback: /* istanbul ignore next */ (newCampuses) => {
          const dataForAnalytics = getDataForAnalytics();
          triggerSegmentTrack({
            dataForAnalytics,
            event: ANALYTICS.events.searchSubmitted,
            properties: {
              action: isChecked
                ? ANALYTICS.actions.toggledOff
                : ANALYTICS.actions.toggledOn,
              component: ANALYTICS.components.locationContainerLocationCheckbox,
              component_url: null,
              eventType: ANALYTICS.eventTypes.view,
              form_fields: {
                ...dataForAnalytics?.form_fields,
                campuses: newCampuses,
              },
              form_name: ANALYTICS.forms.search,
              label: name,
            },
            user,
            userProfileData,
          });
        },
        campus: slug,
      });
    }
    isChecked = !isChecked;

    if (typeof setIsMetroChecked === 'function') {
      setIsMetroChecked(false);
    }
    if (typeof setIsPartial === 'function') {
      setIsPartial(true);
    }
  }

  return (
    <div
      className="campus-checkbox"
      data-testid="lg-campus-checkbox"
      onClick={handleClick}
    >
      <label className={`lg-location-checkbox`} htmlFor={name}>
        {name}
      </label>
      <span
        className={`checkbox ${
          isMetroChecked || isChecked ? 'campus-active' : ''
        }`.trim()}
      />
    </div>
  );
}

export default CampusCheckbox;
