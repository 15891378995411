/**
 * @module FilteredCampusesContainer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import CampusCheckbox from '../CampusCheckbox';
import FilteredMetroGroup from '../FilteredMetroGroup';
import './FilteredCampusesContainer.scss';

/**
 * Represents a container for filtered campuses checkbox elements.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.locations - Array of locations.
 *
 * @returns {React.ReactElement} The FilteredCampusesContainer component.
 */
function FilteredCampusesContainer({ locations }) {
  return (
    <>
      {locations?.length ? (
        <div
          className="checkbox location-checkbox"
          data-testid="lg-filtered-campuses-container"
        >
          {locations.map((location) => {
            if (typeof location === 'string') {
              return (
                <FilteredMetroGroup
                  key={[location, 'Metro'].join(' ')}
                  name={[location, 'Metro'].join(' ')}
                />
              );
            }
            return (
              <CampusCheckbox
                key={location.name}
                name={location.name}
                slug={location.slug}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}

export default FilteredCampusesContainer;
