/**
 * @module IconHelper
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import BibleStudyIcon from '../ui/BibleStudyIcon';
import BookClubIcon from '../ui/BookClubIcon';
import CalendarIcon from '../ui/CalendarIcon';
import DiscipleshipIcon from '../ui/DiscipleshipIcon';
import FamilyIcon from '../ui/FamilyIcon';
import FinancialCoachingIcon from '../ui/FinancialCoachingIcon';
import FriendshipIcon from '../ui/FriendshipIcon';
import GlobeIcon from '../ui/GlobeIcon';
import HobbiesIcon from '../ui/HobbiesIcon';
import LeadershipCoachingIcon from '../ui/LeadershipCoachingIcon';
import LifeChurchOnlineWatchPartyIcon from '../ui/LifeChurchOnlineWatchPartyIcon';
import LocationIcon from '../ui/LocationIcon';
import MarriageIcon from '../ui/MarriageIcon';
import MentalHealthIcon from '../ui/MentalHealthIcon';
import MentoringIcon from '../ui/MentoringIcon';
import MessageBubbleIcon from '../ui/MessageBubbleIcon';
import MessageDiscussionIcon from '../ui/MessageDiscussionIcon';
import ParentingIcon from '../ui/ParentingIcon';
import PeopleIcon from '../ui/PeopleIcon';
import PrayerIcon from '../ui/PrayerIcon';
import RecoveryIcon from '../ui/RecoveryIcon';
import SistersIcon from '../ui/SistersIcon';
import VolunteeringIcon from '../ui/VolunteeringIcon';
import { TAG_COLORS } from '../../utils/constants';
import { normalize } from '../../utils/helpers';

/**
 * Convenience helper to return the topic-specific icon for the specified topic.
 * Note: Ignore directive added since all icons tested individually.
 *
 * @param {string} topic - Normalized string value of the topic. (Example: 'bible-study', 'friendship'.)
 * @param {string} color - Optional color value to set as the prop value of the returned icon component. (Example: 'rgba(0, 200, 255, 1)'.)
 *
 * @returns {React.ReactElement}
 */
/* istanbul ignore next */
const getIcon = (topic, color) => {
  switch (normalize(topic)) {
    case 'bible-study':
      return <BibleStudyIcon color={color || TAG_COLORS.default.content} />;
    case 'book-club':
      return <BookClubIcon color={color || TAG_COLORS.default.content} />;
    case 'calendar':
      return <CalendarIcon color={color || TAG_COLORS.default.content} />;
    case 'discipleship':
      return <DiscipleshipIcon color={color || TAG_COLORS.default.content} />;
    case 'family':
      return <FamilyIcon color={color || TAG_COLORS.default.content} />;
    case 'financial-coaching':
    case 'finances':
      return (
        <FinancialCoachingIcon color={color || TAG_COLORS.default.content} />
      );
    case 'friendship':
      return <FriendshipIcon color={color || TAG_COLORS.default.content} />;
    case 'hobby':
    case 'hobbies':
      return <HobbiesIcon color={color || TAG_COLORS.default.content} />;
    case 'in-person':
      return <LocationIcon color={color || TAG_COLORS.default.content} />;
    case 'leadership':
    case 'leadership-coaching':
      return (
        <LeadershipCoachingIcon color={color || TAG_COLORS.default.content} />
      );
    case 'lifechurch-online-watch-party':
    case 'life.church-online-watch-party':
    case 'life-church-online-watch-party':
      return (
        <LifeChurchOnlineWatchPartyIcon
          color={color || TAG_COLORS.default.content}
        />
      );
    case 'location':
      return <LocationIcon color={color || TAG_COLORS.default.content} />;
    case 'marriage':
      return <MarriageIcon color={color || TAG_COLORS.default.content} />;
    case 'mental-health':
      return <MentalHealthIcon color={color || TAG_COLORS.default.content} />;
    case 'mentoring':
      return <MentoringIcon color={color || TAG_COLORS.default.content} />;
    case 'message-bubble':
      return <MessageBubbleIcon color={color || TAG_COLORS.default.content} />;
    case 'message-discussion':
    case 'message-discussions':
      return (
        <MessageDiscussionIcon color={color || TAG_COLORS.default.content} />
      );
    case 'online':
      return <GlobeIcon color={color || TAG_COLORS.default.content} />;
    case 'parenting':
      return <ParentingIcon color={color || TAG_COLORS.default.content} />;
    case 'people':
      return <PeopleIcon color={color || TAG_COLORS.default.content} />;
    case 'prayer':
      return <PrayerIcon color={color || TAG_COLORS.default.content} />;
    case 'recovery':
      return <RecoveryIcon color={color || TAG_COLORS.default.content} />;
    case 'sisters':
      return <SistersIcon color={color || TAG_COLORS.default.content} />;
    case 'volunteering':
      return <VolunteeringIcon color={color || TAG_COLORS.default.content} />;
    default:
      return null;
  }
};

export { getIcon };
