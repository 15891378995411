/**
 * @module CustomCheckBox
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './CheckBox.scss';
/**
 * Represents a custom checkbox element with accompanying label.
 *
 * @param {object} props - The component props object.
 * @param {string} props.className - The class name to attribute to the wrapper div around the checkbox element.
 * @param {string} props.filterFeature - The corresponding filter attributed to the checkbox.
 * @param {string} props.id - The unique id to use as the value for the checkbox input element.
 * @param {boolean} props.isChecked - Boolean flag denoting whether or not the checkbox is checked.
 * @param {string} props.labelClassName - Class name value to use for the checkbox class label element.
 * @param {string} props.name - The name attribute value to use for the checkbox.
 * @param {Function} props.onChange - Handler function for the checkbox change event.
 * @param {string} props.value - The value of the checkbox input element.
 *
 * @returns {React.ReactElement} The CustomCheckBox component.
 */
function CustomCheckBox({
  className,
  filterFeature,
  id,
  isChecked,
  labelClassName,
  name,
  onChange,
  value,
}) {
  const classNameValue =
    labelClassName + (isChecked?.toString() === 'true' ? ' lg-active' : '');
  return (
    <div
      className={`checkbox custom-checkbox ${className || ''}`.trim()}
      data-testid="lg-custom-checkbox"
    >
      <input
        checked={isChecked?.toString() !== 'false'}
        className="lg-hidden-checkbox"
        data-cy-filter-feature={filterFeature}
        id={id}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <label className={`lg-custom-checkbox ${classNameValue}`} htmlFor={id}>
        {name}
      </label>
    </div>
  );
}

export default CustomCheckBox;
