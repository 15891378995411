/**
 * @module AgeAndFrequency
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import FamilyIcon from '../ui/FamilyIcon';
import LifeChurchLogomarkIcon from '../ui/LifeChurchLogomarkIcon';
import { ALGOLIA_CONFIG } from '../../utils/constants';
import './DetailPage.scss';

/**
 * Represents a section of a LifeGroup detail page including information on children and campus location.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 *
 * @returns {React.ReactElement} - The ChildrenAndCampusLocation component.
 */
function ChildrenAndCampusLocation({ groupData }) {
  return (
    <div
      className="lg-detail-children-campus"
      data-testid="lg-detail-children-campus"
    >
      {/* Children */}
      {groupData?.facets?.children ? (
        <div className="lg-attribute-wrap">
          <div className="lg-attribute-icon">
            <FamilyIcon />
          </div>
          <div className="lg-attribute-text">
            <h3>{groupData.facets.children}</h3>
            {groupData.facets.children.toLowerCase() ===
              ALGOLIA_CONFIG.facets[
                'facets.children'
              ].options.childrenWelcome.toLowerCase() &&
            groupData.childrenWelcomeDescription ? (
              <p>{groupData?.childrenWelcomeDescription}</p>
            ) : null}
            {groupData.facets?.children.toLowerCase() ===
              ALGOLIA_CONFIG.facets[
                'facets.children'
              ].options.childcareAvailable.toLowerCase() &&
            groupData.childrenCareDescription ? (
              <p>{groupData.childrenCareDescription}</p>
            ) : null}
          </div>
        </div>
      ) : null}
      {/* Life.Church Campus Location */}
      <div className="lg-attribute-wrap">
        <div className="lg-attribute-icon">
          <LifeChurchLogomarkIcon />
        </div>
        <div
          className="lg-attribute-text"
          data-testid="lg-detail-children-campus-location"
        >
          <h3>
            {`${
              groupData.campus?.name?.indexOf('Life.Church') >= 0
                ? ''
                : 'Life.Church '
            }${groupData.campus?.name ?? ''}`.trim()}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default ChildrenAndCampusLocation;
