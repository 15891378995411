/**
 * @module LifeChurchOnlineCheckbox
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import './LifeChurchOnlineCheckbox.scss';

/**
 * Represents a checkbox element for a checkbox component for Life.Church Online.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isChecked] - Boolean flag denoting whether or not to set the checkbox as checked.
 * @param {Function} props.setIsChecked - Handler and setter function to set the isChecked value.
 *
 * @returns {React.ReactElement} The LifeChurchOnlineCheckbox component.
 */
function LifeChurchOnlineCheckbox({ isChecked, setIsChecked }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics, filteredList, removeCampus, storeCampus } =
    useAlgoliaSearch();

  /**
   * Handler function for the click event of the checkbox container.
   *
   * Note: Ignoring analytics call simply because the triggers are baked into
   * the nested callbacks needed to ensure 100% context state accuracy when
   * calling multiple methods from the same component without rerenders.
   */
  function handleClick() {
    /* istanbul ignore next */
    const callAnalytics = (campuses) => {
      const dataForAnalytics = getDataForAnalytics();
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchSubmitted,
        properties: {
          action: ANALYTICS.actions.updated,
          component: ANALYTICS.components.locationContainerLocationCheckbox,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
            campuses,
          },
          form_name: ANALYTICS.forms.search,
          label: STRINGS.global.lifeChurchOnline,
        },
        user,
        userProfileData,
      });
    };

    /* istanbul ignore next */
    if (isChecked) {
      removeCampus({
        callback: /* istanbul ignore next */ (newCampuses) => {
          callAnalytics(newCampuses);
        },
        campus: 'int',
      });
      setIsChecked(false);
    } else {
      let campusesToRemove = [];
      filteredList.forEach((slug) => {
        if (slug !== 'int') {
          campusesToRemove.push(slug);
        }
      });
      if (campusesToRemove.length) {
        removeCampus({
          callback: /* istanbul ignore next */ () => {
            storeCampus({
              callback: (newCampuses) => {
                callAnalytics(newCampuses);
              },
              campus: 'int',
            });
          },
          campus: campusesToRemove,
        });
      } else {
        storeCampus({
          callback: /* istanbul ignore next */ (newCampuses) => {
            callAnalytics(newCampuses);
          },
          campus: 'int',
        });
      }
      setIsChecked(true);
    }
  }

  /**
   * Convenience effect to trigger the setIsChecked handler/setter.
   */
  React.useEffect(() => {
    const anyOfRegex = /\b(?:int|all)\b/gi;
    const isLifeChurchOnlineChecked = filteredList?.some((item) =>
      anyOfRegex.test(item),
    );
    setIsChecked(isLifeChurchOnlineChecked);
  }, [filteredList, setIsChecked]);

  return (
    <div
      className="checkbox location-checkbox"
      data-testid="lg-lifechurch-online-checkbox"
      key="Global"
    >
      <label className="lg-location-checkbox state-text" htmlFor="Global">
        {STRINGS.global.global}
      </label>
      <div className="campus-checkbox lco-checkbox" onClick={handleClick}>
        <div className={`lg-location-checkbox`}>
          {STRINGS.global.lifeChurchOnline}
        </div>
        <span className={`checkbox ${isChecked ? 'campus-active' : ''}`} />
      </div>
    </div>
  );
}

export default LifeChurchOnlineCheckbox;
