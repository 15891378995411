/**
 * @module StickyBar
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { v4 as uuidv4 } from 'uuid';
import Button from '../ButtonItem/ButtonItem';
import { ANALYTICS } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import './DetailPage.scss';

/**
 * Represents a "sticky" bar portion of the LifeGroup Detail page containing group name, leader avatars, and contact button.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.groupLeaders - Array of group leaders data objects.
 * @param {string} props.groupName - The group name.
 * @param {Function} props.onClick - Handler function for Contact button click event.
 *
 * @returns {React.ReactElement} The StickyBar component.
 */
function StickyBar({ groupLeaders, groupName, onClick }) {
  const { isMobile } = useWindowSize();

  /**
   * Handler function for Contact Us button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event, {
        component: ANALYTICS.components.lifeGroupDetailPageStickBar,
      });
    }
  }

  return (
    <div className="lg-sticky-bar" data-testid="lg-group-detail-sticky-bar">
      <div className="container">
        {!isMobile ? (
          <div className="lg-text-and-avatars">
            <p>{`Connect with ${groupName || 'this group'}`}</p>
            <div className="lg-leader-avatars-wrap flex-align-content-center">
              {groupLeaders?.map((leaderData, index) => {
                /* Max show of 2 leaders, per Product Design decision. */
                if (index < 2) {
                  return (
                    <div
                      className="lg-leader-avatar"
                      key={`${leaderData?.firstName}-${uuidv4()}`}
                    >
                      <div
                        className="image"
                        style={
                          leaderData.publicAvatarUrl
                            ? {
                                backgroundImage: `url(${leaderData.publicAvatarUrl})`,
                              }
                            : {}
                        }
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        ) : null}
        <div className="button-container">
          <Button
            buttonSize={`${isMobile ? 'medium' : 'large'}`}
            className={`button-primary button-${isMobile ? 'medium' : 'large'}`}
            onClick={handleButtonClick}
            style="btn-primary"
            text={STRINGS.global.contactUs}
          />
        </div>
      </div>
    </div>
  );
}

export default StickyBar;
