/**
 * @module LifeGroupsApi
 */
import { apiClient } from '../core';

/**
 * Retrieve LifeGroups data
 *
 * @param {object} params - The function params object.
 * @param {object} [params.params] - Optional params data object to pass with the GET request.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getLifeGroups({ params } = {}) {
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    },
    params,
    url: `${process.env.LIFEGROUPS_API_DOMAIN}v1/lifegroups`,
  });
}
