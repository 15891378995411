/**
 * @module Keywords
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import { STRINGS } from '../../../utils/strings';

/**
 * Represents a search and filtering options section for Keywords.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isMobile] - Boolean flag denoting whether or not the page is within the constraints of a mobile viewport.
 * @param {Function} props.onKeywordChanged - Handler function for the component keywords input field change event.
 *
 * @returns {React.ReactElement} The Keywords component.
 */
function Keywords({ isMobile, onKeywordChanged = () => {} }) {
  const { keywords } = useAlgoliaSearch();

  return (
    <div className="lg-search-keywords">
      {isMobile ? (
        <>
          <div className="lg-filter-section">
            <input
              data-testid="lg-search-filters-keywords"
              onChange={onKeywordChanged}
              placeholder={STRINGS.searchFilters.placeholders.keywords}
              type="text"
              value={keywords}
            />
          </div>
        </>
      ) : (
        <input
          data-testid="lg-search-filters-keywords"
          onChange={onKeywordChanged}
          placeholder={STRINGS.searchFilters.placeholders.keywords}
          type="text"
          value={keywords}
        />
      )}
    </div>
  );
}

export default Keywords;
