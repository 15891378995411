/**
 * @module AgeAndFrequency
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { formatDate } from '@lifechurch/web-tools-io/dist/utils/helpers/date';
import CalendarIcon from '../ui/CalendarIcon';
import HourglassIcon from '../ui/HourglassIcon';
import PeopleIcon from '../ui/PeopleIcon';
import { generateAgeLabel, generateFrequencyLabel } from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import './DetailPage.scss';

/**
 * Represents a section of a LifeGroup detail page including age and meeting frequency.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 *
 * @returns {React.ReactElement} - The AgeAndFrequency component.
 */
function AgeAndFrequency({ groupData }) {
  return (
    <div
      className="lg-detail-age-frequency"
      data-testid="lg-detail-age-frequency"
    >
      {/* Age and Season of Life */}
      <div className="lg-attribute-wrap">
        <div className="lg-attribute-icon">
          <PeopleIcon />
        </div>
        <div className="lg-attribute-text">
          <h3>
            {generateAgeLabel({
              max: groupData?.facets?.ageRangeTop,
              min: groupData?.facets?.ageRangeBottom,
            })}
          </h3>
          {groupData?.facets?.seasonOfLife ? (
            <p>{groupData.facets.seasonOfLife.join(', ')}</p>
          ) : null}
        </div>
      </div>
      {/* Frequency: Intentionally breaking up time and day of week into separate parts for UI needs. */}
      {groupData?.meetingTime ? (
        <div className="lg-attribute-wrap">
          <div className="lg-attribute-icon">
            <CalendarIcon />
          </div>
          <div className="lg-attribute-text">
            <h3>
              {generateFrequencyLabel({
                data: {
                  ...groupData,
                  meetingTime: null,
                },
                useFullDayOfWeek: true,
              })}
            </h3>
            <p>
              {generateFrequencyLabel({
                data: {
                  meetingTime: groupData?.meetingTime,
                  timeZone: groupData?.timeZone,
                },
              })}
            </p>
          </div>
        </div>
      ) : null}
      {/* Short Term group info. */}
      {groupData?.facets?.isShortTermGroup &&
      groupData.shortTermGroupEndDate ? (
        <div className="lg-attribute-wrap">
          <div className="lg-attribute-icon">
            <HourglassIcon />
          </div>
          <div className="lg-attribute-text">
            <h3>{STRINGS.groupDetail.shortTermLifeGroup}</h3>
            <p>
              {`${STRINGS.groupDetail.meetsUntil} ${formatDate({
                date: new Date(groupData.shortTermGroupEndDate),
                displayFullMonth: true,
              })}`}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AgeAndFrequency;
