/**
 * @module MagnoliaContext
 */
import React from 'react';
import PropTypes from 'prop-types';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia';
import _ from 'lodash';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../utils/constants';

/**
 * @typedef {object} MagnoliaContext
 * @property {object} footerData - Object of footer data.
 * @property {object} menuData - Object of menu data.
 * @property {object} navData - Object of nav bar data.
 */

export const MagnoliaContext = React.createContext({
  footerData: null,
  menuData: null,
  navBarData: null,
});
MagnoliaContext.displayName = 'MagnoliaContext';

/**
 * React Context Provider for Magnolia service.
 *
 * @param {object} props - The context component props object.
 * @param {React.ReactNode} props.children - The React children around which the context provider is wrapped.
 *
 * @returns {React.ReactElement} The Magnolia Context Provider.
 */
export function MagnoliaProvider({ children, ...props }) {
  /**
   * Data object to store footer data.
   */
  const [footerData, setFooterData] = React.useState([]);

  /**
   * Data object to store header data.
   */
  const [menuData, setMenuData] = React.useState([]);

  /**
   * Data object to store header data.
   */
  const [navBarData, setNavBarData] = React.useState([]);

  // Convenience state for initial data retrieval check.
  const [isInitialDataFetched, setIsInitialDataFetched] = React.useState(false);

  /**
   * Convenience function to fetch nav bar data.
   *
   * @param {string} path - The path value for the page rendering the nav bar (Example: '/media/messages').
   *
   * @returns {object} Data object of nav bar data.
   */
  const getNavBarData = async (path) => {
    const tmpNavBar = [];
    /**
     * Note: The following is just ensuring we have a bulletproof way to avoid
     * continuing the logical flow if there's any reason why path is null.
     */
    if (!path) {
      return tmpNavBar;
    }
    const pathParts = path.split('/').filter((e) => e);
    const data = await getMagnoliaItem({
      caller: 'src/context/MagnoliaContext.js > getNavBarData',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: `/.rest/delivery/pagenav/v1/${pathParts[0]}`,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
    tmpNavBar.push(data || false);
    return tmpNavBar;
  };

  /**
   * Convenience function to fetch menu data.
   *
   * @returns {object} Data object of menu data.
   */
  async function getMenuData() {
    const data = await getMagnoliaItem({
      caller: 'src/context/MagnoliaContext.js > getMenuData',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: '/.rest/delivery/menu/@nodes',
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
    const menuData = data ? [...data] : [];
    const menuFolder =
      (!!data &&
        data.filter(
          (menuItem) =>
            menuItem['@name'] === 'menu' &&
            menuItem['@nodeType'] === 'mgnl:folder',
        )[0]) ||
      [];
    if (menuFolder?.length === 0) {
      Log.warn(
        'No folder named "menu" was found in the cms, no items will be shown in the hamburger menu.',
      );
    }
    return menuData;
  }

  /**
   * Convenience function to fetch footer data.
   *
   * @returns {object} Data object of footer data.
   */
  const getFooterData = async () => {
    const data = await getMagnoliaItem({
      caller: 'src/context/MagnoliaContext.js > getFooterData',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: '/.rest/delivery/footer',
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
    return data.results ? { ...data.results[0] } : {};
  };

  /**
   * Data fetching function to fetch necessary page data, including nav bar,
   * menu, and footer.
   */
  const fetchPageData = async () => {
    const navBarDataResult = await getNavBarData('/lifegroups/search');
    const menuDataResult = await getMenuData();
    const footerDataResult = await getFooterData();

    setFooterData(footerDataResult);
    setMenuData(menuDataResult);
    setNavBarData(navBarDataResult);
  };

  /**
   * Single-run convenience effect to fetch data and set up.
   */
  React.useEffect(() => {
    if (!isInitialDataFetched) {
      setIsInitialDataFetched(true);
      fetchPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialDataFetched]);

  const value = React.useMemo(
    () => ({
      footerData,
      menuData,
      navBarData,
    }),
    [footerData, menuData, navBarData],
  );

  return (
    <MagnoliaContext.Provider value={value} {...props}>
      {children}
    </MagnoliaContext.Provider>
  );
}

MagnoliaProvider.propTypes = {
  /**
   * The React children.
   */
  children: PropTypes.node,
};
