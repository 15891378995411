/**
 * @module LocationSelector
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import SearchBarInput from '../SearchBarInput';
import CampusCheckboxContainer from '../CampusCheckboxContainer';
import FilteredCampusesContainer from '../FilteredCampusesContainer';
import LifeChurchOnlineCheckbox from '../LifeChurchOnlineCheckbox';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import '../LocationsContainer/LocationsContainer.scss';
import './CategoryBasedSearch.scss';

function LocationSelector({ campusList }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const [searchText, setSearchText] = React.useState('');
  const [campuses, setCampuses] = React.useState([]);
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const [isIntChecked, setIsIntChecked] = React.useState(true);
  const { getDataForAnalytics } = useAlgoliaSearch();

  const metros = [];
  campusList.forEach((campus) => {
    const metro = campus.metro.slice(0, -6);
    if (metro !== '' && !metros?.includes(metro)) {
      metros.push(metro);
    }
  });

  const searchCampusList = campusList.filter((campus) => {
    return campus.slug !== 'all' && campus.slug !== 'int';
  });

  const allowedCharacters = /[^a-z_ ]/gi;

  function handleSearch(event) {
    const text = event.target.value.replace(allowedCharacters, '');
    setSearchText(text);
    if (text === '') {
      setFilteredLocations([]);
    } else {
      const firstRegex = `^(${text})`;
      const containsRegex = `${text}`;

      const firstMetros = metros.filter((metro) =>
        metro.match(new RegExp(firstRegex, 'i')),
      );
      const newMetroList = metros.filter((ele) => {
        return !firstMetros?.includes(ele);
      });

      const firstResults = searchCampusList
        .filter((campus) => campus.name.match(new RegExp(firstRegex, 'i')))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      const newCampusList = searchCampusList.filter((ele) => {
        return !firstResults?.includes(ele);
      });

      const containsMetros = newMetroList.filter((metro) =>
        metro.match(new RegExp(containsRegex, 'i')),
      );

      const containsResults = newCampusList
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((campus) => campus.name.match(new RegExp(containsRegex, 'i')));
      setFilteredLocations([
        ...firstMetros,
        ...firstResults,
        ...containsMetros,
        ...containsResults,
      ]);
    }

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.searchSubmitted,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.components.locationsContainerSearchInput,
        component_url: null,
        eventType: ANALYTICS.eventTypes.view,
        form_fields: {
          ...dataForAnalytics?.form_fields,
          campuses_search: text,
        },
        form_name: ANALYTICS.forms.search,
      },
      user,
      userProfileData,
    });
  }

  React.useEffect(() => {
    function sortCampuses() {
      const map = {};
      const locationList =
        filteredLocations.length === 0 ? campusList : filteredLocations;

      const sortedList = locationList
        .filter((a) => a.state)
        .sort((a, b) => (a.metro > b.metro ? 1 : -1));

      sortedList.forEach((campus) => {
        if (map[campus.state]) {
          if (!map[campus.state][campus.metro]) {
            map[campus.state][campus.metro] = [campus];
          } else {
            map[campus.state][campus.metro].push(campus);
          }
        } else {
          map[campus.state] = { [campus.metro]: [campus] };
        }
      });
      setCampuses(map);
    }
    sortCampuses();
  }, [campusList, filteredLocations]);

  return (
    <div className="dropdown-outer-container dropdown-outer-closed text-left">
      <SearchBarInput handleSearch={handleSearch} searchText={searchText} />
      {filteredLocations.length === 0 ? (
        <>
          {searchText !== '' ? (
            <div className="m-a-1">
              <p className="no-result font-weight-semibold">
                {STRINGS.locationSelector.zeroResultsFound.count}
              </p>
              <p className="no-result-description">
                {STRINGS.locationSelector.zeroResultsFound.description}
              </p>
              <p className="no-result-description">
                {STRINGS.locationSelector.zeroResultsFound.descriptionTryAgain}
              </p>
            </div>
          ) : (
            <div className="campuses-container">
              <LifeChurchOnlineCheckbox
                isChecked={isIntChecked}
                key="lco"
                setIsChecked={setIsIntChecked}
              />
              {Object.keys(campuses).length
                ? Object.keys(campuses)
                    .sort() // NOSONAR
                    .map((state) => (
                      <CampusCheckboxContainer
                        campuses={campuses[state]}
                        key={state}
                        state={state}
                      />
                    ))
                : null}
            </div>
          )}
        </>
      ) : (
        <div className="campuses-container">
          <FilteredCampusesContainer locations={filteredLocations} />
        </div>
      )}
    </div>
  );
}

export { LocationSelector };
