/**
 * @module AboutGroup
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { useCollapse } from 'react-collapsed';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import './DetailPage.scss';

/**
 * Represents a section of a LifeGroup detail page including the description information about the group.
 *
 * Note: The value used for collapsedHeight is based on the following formula:
 * 16 (font size in px) x 3 (number of lines to show) + (12 (px between lines) * 2 (line gaps)) = 72.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 *
 * @returns {React.ReactElement} - The AboutGroup component.
 */
function AboutGroup({ groupData, ...passThroughProps }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics } = useAlgoliaSearch();
  const collapsedHeight = 72;
  const [collapseContentInnerHeight, setCollapseContentInnerHeight] =
    React.useState(
      /* istanbul ignore next */ passThroughProps?.collapseContentInnerHeight ??
        0,
    );
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    collapsedHeight,
    isExpanded,
  });

  /**
   * Handler function for toggle click event.
   */
  function handleToggle(event) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
    }
    setIsExpanded(!isExpanded);

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupDetailPage,
        component_url: null,
        label: event.currentTarget.textContent,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Handler function for resize event.
   */
  /* istanbul ignore next */
  const handleResize = () => {
    const collapseContent = document.getElementById(
      'id-collapse-section-content',
    );
    if (collapseContent) {
      const collapseText = collapseContent.querySelector('p');
      const height = collapseText.offsetHeight;
      setCollapseContentInnerHeight(height);
    }
  };

  /**
   * Single-run convenience effect to add resize handler and calculate height.
   *
   * Note: To account for the brief time during which the styles are rendering
   * with the content of the page, adding setTimeout to ensure accurate height
   * is calculated and isn't too small of a value.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    setTimeout(() => {
      handleResize();
    }, 100);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="lg-attribute-collapsible bg-gray5 full-width lg-attribute-wrap rounded"
      data-testid="lg-detail-about-group"
    >
      <div className="lg-attribute-text full-width">
        <h3>{STRINGS.groupDetail.pageTitle}</h3>
        <div className="lg-group-description collapse-wrapper">
          <section {...getCollapseProps()}>
            <div
              className="collapse-section-content"
              id="id-collapse-section-content"
            >
              <p
                className={
                  /* istanbul ignore next */ `${
                    isExpanded || passThroughProps?.isExpanded
                      ? 'expanded'
                      : 'collapsed'
                  }`
                }
              >
                {groupData.description}
              </p>
            </div>
          </section>
          {/**
           * Note: 2 added to collapsed height in case of minor extra couple
           * pixels of height not requiring more visibility.
           * Since this is using a third-party component with local state vars,
           * ignoring for test coverage.
           **/}
          {collapseContentInnerHeight > collapsedHeight + 2 ? (
            <a
              className="collapse-inline-toggle button-tertiary button-small"
              data-testid="lg-detail-about-description-more-less-btn"
              onClick={handleToggle}
              {...getToggleProps({
                onClick: handleToggle,
              })}
            >
              {`${STRINGS.global.view} ${
                isExpanded ? STRINGS.global.less : STRINGS.global.more
              }`}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AboutGroup;
