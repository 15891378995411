/**
 * @module Topics
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import Chip from '../../Chip';
import { getIcon } from '../../ui/icon-helper';
import { TAG_COLORS } from '../../../utils/constants';
import {
  normalize,
  toggleCheckboxGroup,
  toggleCheckboxGroupArrow,
  toggleCheckboxGroupColor,
} from '../../../utils/helpers';
import { STRINGS } from '../../../utils/strings';
import ResetButton from './ResetButton';

/**
 * Represents a search and filtering options section for Topics facets.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.classToggle] - Boolean flag denoting the toggle status of the component's elements.
 * @param {boolean} [props.isMobile] - Boolean flag denoting whether or not the page is within the constraints of a mobile viewport.
 * @param {Function} props.onBlur - Handler function for the component onBlur event.
 * @param {Function} props.onChange - Handler function for the component onChange event.
 * @param {Function} props.onClick - Handler function for the component onClick event.
 * @param {Function} props.onReset - Handler function for the component reset event.
 *
 * @returns {React.ReactElement} The Topics component.
 */
function Topics({
  classToggle,
  isMobile,
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
  onReset = () => {},
}) {
  const { algoliaFacets, topics } = useAlgoliaSearch();
  const topicsLength = topics
    ? topics.filter((topic) => topic !== '??').length
    : 0;
  const topicsLengthValue = topicsLength > 0 ? `(${topicsLength})` : '';
  const lgCheckboxGroupTopicsClassName =
    topicsLength > 0 ? 'lg-black' : toggleCheckboxGroupColor(classToggle);

  /**
   * Convenience function to iterate over topics facets and add interactive Chip components for each.
   *
   * @returns {React.ReactElement} Series of Chip components for all topics facet keys.
   */
  const renderCheckboxes = () => {
    if (
      !algoliaFacets?.['facets.topic'] &&
      !algoliaFacets?.['facets.topic.name']
    ) {
      return null;
    }

    const topicsArray = algoliaFacets['facets.topic.name']
      ? Object.keys(algoliaFacets['facets.topic.name'])
      : Object.keys(algoliaFacets['facets.topic']);
    const isChecked = (value) => {
      return topics?.includes(value);
    };

    return topicsArray.map((topic) => (
      <Chip
        color={
          isChecked(
            /* istanbul ignore next */ typeof topic === 'string'
              ? topic
              : topic.name,
          )
            ? TAG_COLORS.topics
            : TAG_COLORS.default
        }
        icon={getIcon(
          normalize(
            /* istanbul ignore next */ typeof topic === 'string'
              ? topic
              : topic.name,
          ),
          isChecked(
            /* istanbul ignore next */ typeof topic === 'string'
              ? topic
              : topic.name,
          )
            ? TAG_COLORS.topics.content
            : TAG_COLORS.default.content,
        )}
        isBordered={true}
        isInteractive={true}
        key={normalize(
          /* istanbul ignore next */ typeof topic === 'string'
            ? topic
            : topic.name,
        )}
        label={
          /* istanbul ignore next */ typeof topic === 'string'
            ? topic
            : topic.name
        }
        onClick={(event) => {
          event.stopPropagation();
          onChange({
            event,
            value:
              /* istanbul ignore next */ typeof topic === 'string'
                ? topic
                : topic.name,
          });
        }}
      />
    ));
  };

  /**
   * Handler function for container click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleContainerClick(event) {
    if (event.target.closest('#id-topics-container')) {
      onClick(event);
    }
  }

  /**
   * Handler function for reset button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleResetClick(event) {
    if (onReset && typeof onReset === 'function') {
      onReset(event);
    }
  }

  /**
   * Handler function for outside click awareness.
   *
   * @param {Event} event - The Event object associated with the blur.
   */
  const handleContainerBlur = React.useCallback((event) => {
    if (
      !event.target.closest('#id-topics-checkboxes') &&
      !event.target.closest('#id-topics-container') &&
      onBlur &&
      typeof onBlur === 'function'
    ) {
      onBlur(event);
    }
  }, []);

  /**
   * Single-run convenience effect to add handler for outside click awareness.
   */
  React.useEffect(() => {
    document.addEventListener('mouseup', handleContainerBlur);
    return () => {
      document.removeEventListener('mouseup', handleContainerBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="lg-search-topics" data-testid="lg-search-topics">
      {isMobile ? (
        <div className="chip-container">{renderCheckboxes()}</div>
      ) : (
        <div
          className="lg-checkbox-group-container"
          data-testid="lg-topics-checkboxes"
          id="id-topics-checkboxes"
          onClick={handleContainerClick}
          tabIndex={-1}
        >
          <div
            className={`lg-checkbox-group topics ${lgCheckboxGroupTopicsClassName}`}
            id="id-topics-container"
          >
            <div
              className="menu-text"
              data-testid="lg-topics-container-menu-text"
            >
              {`${STRINGS.searchFilters.labels.topic} ${topicsLengthValue}`.trim()}
            </div>
            <span
              className={`lg-${toggleCheckboxGroupArrow(
                classToggle,
              )}-arrow-zone`}
            >
              <span
                className={`lg-${toggleCheckboxGroupArrow(classToggle)}-arrow`}
              />
            </span>
          </div>
          <div
            className={`lg-checkbox-container topics ${toggleCheckboxGroup(
              classToggle,
            )}`}
            id="id_topic_filters_container"
          >
            <div className="lg-desktop-filter-header-wrap">
              <h4 className="lg-checkbox-group-text desktop-topics-text">
                {STRINGS.searchFilters.labels.topic}
              </h4>
              <ResetButton onClick={handleResetClick} />
            </div>
            <div className="chip-container">{renderCheckboxes()}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Topics;
