/**
 * @module ListContainer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import InfiniteScroll from 'react-infinite-scroller';
import { v4 as uuidv4 } from 'uuid';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import PlaceholderLoader from '../PlaceholderLoader';
import StartAGroupMessage from './components/StartAGroupMessage';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { APP_NAME, GROUP_TYPES_MAP } from '../../utils/constants';
import LgCard from '../LgCard';
import LgLegacyCard from '../LgCard/LgLegacyCard';
import SwitchMessage from './components/SwitchMessage';
import './ListContainer.scss';

/**
 * Represents a container in which to display LgCard components for search results listings.
 *
 * @returns {React.ReactElement} The ListContainer component.
 */
function ListContainer() {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { breakpoints, width } = useWindowSize();
  const {
    age,
    fetchLifeGroupData,
    filteredList,
    getDataForAnalytics,
    groupTypes,
    isSearchResultsUpdatedByFilterSearch,
    resultsPagination,
    searchResults,
  } = useAlgoliaSearch();
  const resultsLength = searchResults?.nbHits || 0;
  const [hasMoreItems, setHasMoreItems] = React.useState(
    resultsPagination?.isMore,
  );
  const [isFetching, setIsFetching] = React.useState(false);
  const [groupResultsData, setGroupResultsData] = React.useState(
    /* istanbul ignore next */ searchResults?.hits || null,
  );

  /**
   * Convenience method to load more results.
   *
   * Note: Ignoring due to InfiniteScroll not needing to be tested (third-party).
   */
  /* istanbul ignore next */
  const loadMore = () => {
    if (!isFetching) {
      setIsFetching(true);
      fetchLifeGroupData({
        isTriggeredManually: true,
        scrollToTop: false,
        searchOptions: {
          length: resultsPagination.resultsLength,
          offset: resultsPagination.offset + resultsPagination.resultsLength,
        },
      });
    }
  };

  /**
   * Convenience method to render the list of LgCard components.
   */
  const renderList = groupResultsData?.map((listItem, index) =>
    listItem.objectID ? (
      <LgCard
        data={listItem}
        index={index}
        key={`lg-card-lifegroup-${uuidv4()}`}
      />
    ) : (
      <LgLegacyCard
        data={listItem}
        index={index}
        key={`lg-legacy-card-local-partner-${uuidv4()}`}
      />
    ),
  );

  /**
   * Handler function for the Start a Group message component button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleStartAGroupMessageButtonClick(event) {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        category: APP_NAME,
        component: ANALYTICS.components.searchResults,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Handler function for the Switch message component button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleSwitchMessageButtonClick(event) {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        category: APP_NAME,
        component: ANALYTICS.components.searchResults,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Convenience method to return age-specific messaging.
   *
   * @returns {React.ReactElement} Markup for either Switch or Start a LifeGroup.
   */
  const renderNoResultsText = () => {
    if (age && age < 18) {
      return <SwitchMessage onClick={handleSwitchMessageButtonClick} />;
    }
    return /* istanbul ignore next */ !hasMoreItems ? (
      <StartAGroupMessage
        filteredList={filteredList}
        onClick={handleStartAGroupMessageButtonClick}
      />
    ) : null;
  };

  /**
   * Convenience effect ot set local isFetching to false when results pagination
   * is updated from AlgoliaSearchContext, and to set local group results data.
   * Also set hasMoreItems state var when resultsPagination value changes.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    setHasMoreItems(resultsPagination.isMore);
    if (isFetching) {
      setGroupResultsData((prevData) => {
        return [...prevData, ...searchResults.hits];
      });
      setIsFetching(false);
    }
  }, [resultsPagination]);

  /**
   * Convenience effect to set local results data when searchResults update.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (isSearchResultsUpdatedByFilterSearch && searchResults?.hits) {
      setGroupResultsData([...searchResults.hits]);
    }
  }, [isSearchResultsUpdatedByFilterSearch, searchResults]);

  return (
    <div
      className={`list-container p-b-1  ${
        GROUP_TYPES_MAP.localPartner.includes(groupTypes) ? 'p-t-2' : 'p-t-1'
      }`}
      data-testid="lg-list-container"
    >
      <div className="container">
        {width >= breakpoints.medium ? (
          <p
            className="lg-found-text font-weight-semibold m-b-half m-l-half m-r-half"
            data-testid="lg-list-container-search-results-label"
          >
            {`${resultsLength} Result${resultsLength === 1 ? '' : 's'} Found`}
          </p>
        ) : null}
      </div>
      <InfiniteScroll
        hasMore={hasMoreItems}
        loadMore={loadMore}
        loader={
          <PlaceholderLoader
            isLegacy={GROUP_TYPES_MAP.localPartner.includes(groupTypes)}
            key={0}
          />
        }
        pageStart={0}
      >
        {groupResultsData ? (
          <div
            className={`container lg-list-card-container ${
              GROUP_TYPES_MAP.localPartner.includes(groupTypes)
                ? 'local-partner'
                : ''
            }`.trim()}
            data-testid="lg-list-card-container"
          >
            {renderList}
          </div>
        ) : (
          <PlaceholderLoader
            isLegacy={GROUP_TYPES_MAP.localPartner.includes(groupTypes)}
            key={0}
          />
        )}
      </InfiniteScroll>
      {renderNoResultsText()}
    </div>
  );
}

export default ListContainer;
