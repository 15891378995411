/**
 * @module DesktopFilters
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import MoreFilters from './components/MoreFilters';
import People from './components/People';
import Topics from './components/Topics';
import CountryContainer from '../CountryContainer';
import LocationsContainer from '../LocationsContainer';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { ALGOLIA_CONFIG, GROUP_TYPES_MAP } from '../../utils/constants';
import { STRINGS } from '../../utils/strings';

/**
 * Represents the desktop filters container with sections and filter controls for searching.
 *
 * @returns {React.ReactElement} The DesktopFilters component.
 */
function DesktopFilters() {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const {
    churchOnlineLocation,
    churchOnlineLocations,
    filteredList,
    getDataForAnalytics,
    groupTypes,
    isMoreFiltersToggled,
    isPeopleFiltersToggled,
    isTopicFiltersToggled,
    resetAge,
    resetChildren,
    resetDays,
    resetFilters,
    resetGenders,
    resetMeetingFrequency,
    resetMeetingType,
    resetSeasonOfLife,
    resetTopics,
    storeAge,
    storeChildren,
    storeDay,
    storeGender,
    storeGroupType,
    storeKeywords,
    storeMeetingFrequency,
    storeMeetingType,
    storeSeasonOfLife,
    storeTopic,
    toggleMoreFilters,
    togglePeopleFilters,
    toggleTopicFilters,
    triggerKeywordUpdate,
  } = useAlgoliaSearch();

  /**
   * Handler function for age change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onAgeChanged(event) {
    const { value } = event.target;
    storeAge({
      callback: /* istanbul ignore next */ (newAge) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.peopleFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              age_search: newAge,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for children change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onChildrenChanged(event) {
    const { value } = event.target;
    storeChildren({
      callback: /* istanbul ignore next */ (newChildren) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.peopleFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              adults_only: newChildren.includes(
                ALGOLIA_CONFIG.facets['facets.children'].options.adultsOnly,
              ),
              childcare_available: newChildren.includes(
                ALGOLIA_CONFIG.facets['facets.children'].options
                  .childcareAvailable,
              ),
              children_welcome: newChildren.includes(
                ALGOLIA_CONFIG.facets['facets.children'].options
                  .childrenWelcome,
              ),
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for day checked event.
   *
   * @param {string} label - The label value of the facet changed.
   */
  function onDayChecked(label) {
    storeDay({
      callback: /* istanbul ignore next */ (newDays) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.moreFiltersContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              meeting_day_of_week_full: newDays,
            },
            form_name: ANALYTICS.forms.search,
            label,
          },
          user,
          userProfileData,
        });
      },
      value: label,
    });
  }

  /**
   * Handler function for gender change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onGenderChanged(event) {
    const { value } = event.target;
    storeGender({
      callback: /* istanbul ignore next */ (newGenders) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.peopleFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              genders: newGenders,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for group type checked event.
   *
   * @param {Event} event - The function Event object.
   */
  function onGroupTypeChecked(event) {
    storeGroupType({
      callback: /* istanbul ignore next */ (newGroupTypes) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.moreFiltersContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              group_type: newGroupTypes,
            },
            form_name: ANALYTICS.forms.search,
            label: event.target.value,
          },
          user,
          userProfileData,
        });
      },
      value: event.target.value,
    });
    resetFilters();
  }

  /**
   * Handler function for keyword change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onKeywordChanged(event) {
    const { value } = event.target;
    storeKeywords({
      callback: /* istanbul ignore next */ (newKeywords) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.moreFiltersContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              keyword_search: newKeywords,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
    if (value.length >= 3 || value.length === 0) {
      triggerKeywordUpdate({ value: value.length });
    }
  }

  /**
   * Handler function for meeting frequency changed event.
   *
   * @param {Event} event - The function Event object.
   */
  function onMeetingFrequencyChanged(event) {
    storeMeetingFrequency({
      callback: /* istanbul ignore next */ (newMeetingFrequency) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.moreFiltersContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              meeting_frequency: newMeetingFrequency,
            },
            form_name: ANALYTICS.forms.search,
            label: event.target.value,
          },
          user,
          userProfileData,
        });
      },
      value: event.target.value,
    });
  }

  /**
   * Handler function for meeting type changed event.
   *
   * @param {Event} event - The function Event object.
   */
  function onMeetingTypeChanged(event) {
    storeMeetingType({
      callback: /* istanbul ignore next */ (newMeetingType) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.moreFiltersContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              group_type: newMeetingType,
            },
            form_name: ANALYTICS.forms.search,
            label: event.target.value,
          },
          user,
          userProfileData,
        });
      },
      value: event.target.value,
    });
  }

  /**
   * Handler function for More Filters group blur event.
   *
   * Note: Ignoring conditional as blur handler tested in sub-component.
   */
  function onMoreFiltersBlur() {
    const dataForAnalytics = getDataForAnalytics();
    const moreFiltersContainer = document.getElementById(
      'id_more_filters_container',
    );
    /* istanbul ignore next */
    if (
      moreFiltersContainer &&
      moreFiltersContainer.classList.contains('lg-show-dropdown')
    ) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.moreFiltersContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    }
    toggleMoreFilters({ value: false });
  }

  /**
   * Handler function for More Filters group click event.
   *
   * @param {Event} event - The function Event object.
   */
  function onMoreFiltersClicked(event) {
    const dataForAnalytics = getDataForAnalytics();
    if (!isMoreFiltersToggled) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchStarted,
        properties: {
          action: ANALYTICS.actions.expanded,
          component: ANALYTICS.components.moreFiltersContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
          },
          form_name: ANALYTICS.forms.search,
          label: ANALYTICS.actions.expanded,
        },
        user,
        userProfileData,
      });
    } else {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.moreFiltersContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    }
    toggleMoreFilters({ value: !isMoreFiltersToggled });
  }

  /**
   * Handler function for More Filters group reset event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function onMoreFiltersReset(event) {
    const dataForAnalytics = getDataForAnalytics();
    event.preventDefault();
    resetMeetingType({
      callback: /* istanbul ignore next */ (newMeetingType) => {
        resetDays({
          callback: (newDays) => {
            resetMeetingFrequency({
              callback: (newMeetingFrequency) => {
                storeKeywords({ value: '' });
                triggerKeywordUpdate({ value: 0 });

                triggerSegmentTrack({
                  dataForAnalytics,
                  event: ANALYTICS.events.buttonAction,
                  properties: {
                    action: ANALYTICS.actions.clicked,
                    component: ANALYTICS.components.moreFiltersContainer,
                    component_url: null,
                    eventType: ANALYTICS.eventTypes.track,
                    label: event?.currentTarget?.textContent,
                  },
                  user,
                  userProfileData,
                });

                // Additional call for search submitted.
                triggerSegmentTrack({
                  dataForAnalytics,
                  event: ANALYTICS.events.searchSubmitted,
                  properties: {
                    action: ANALYTICS.actions.updated,
                    component: ANALYTICS.components.moreFiltersContainer,
                    component_url: null,
                    eventType: ANALYTICS.eventTypes.view,
                    form_fields: {
                      ...dataForAnalytics?.form_fields,
                      keyword_search: '',
                      meeting_day_of_week_full: newDays,
                      meeting_frequency: newMeetingFrequency,
                      meeting_type: newMeetingType,
                    },
                    form_name: ANALYTICS.forms.search,
                    label: event?.currentTarget?.textContent,
                  },
                  user,
                  userProfileData,
                });
              },
            });
          },
        });
      },
    });
  }

  /**
   * Handler function for People filter group blur event.
   *
   * Note: Ignoring conditional as blur handler tested in sub-component.
   */
  function onPeopleFiltersBlur() {
    const dataForAnalytics = getDataForAnalytics();
    const peopleFiltersContainer = document.getElementById(
      'id_people_filters_container',
    );
    /* istanbul ignore next */
    if (
      peopleFiltersContainer &&
      peopleFiltersContainer.classList.contains('lg-show-dropdown')
    ) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.peopleFilterContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    }
    togglePeopleFilters({ value: false });
  }

  /**
   * Handler function for People filter group click event.
   */
  function onPeopleFiltersClicked() {
    const dataForAnalytics = getDataForAnalytics();
    if (!isPeopleFiltersToggled) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchStarted,
        properties: {
          action: ANALYTICS.actions.expanded,
          component: ANALYTICS.components.peopleFilterContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
          },
          form_name: ANALYTICS.forms.search,
          label: ANALYTICS.actions.expanded,
        },
        user,
        userProfileData,
      });
    } else {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.peopleFilterContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    }
    togglePeopleFilters({ value: !isPeopleFiltersToggled });
  }

  /**
   * Handler function for People filter group reset event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function onPeopleFiltersReset(event) {
    const dataForAnalytics = getDataForAnalytics();
    event.preventDefault();
    resetGenders({
      callback: /* istanbul ignore next */ (newGenders) => {
        resetSeasonOfLife({
          callback: (newSeasonOfLife) => {
            resetChildren({
              callback: (newChildren) => {
                resetAge({
                  callback: (newAge) => {
                    triggerSegmentTrack({
                      dataForAnalytics,
                      event: ANALYTICS.events.buttonAction,
                      properties: {
                        action: ANALYTICS.actions.clicked,
                        component: ANALYTICS.components.peopleFilterContainer,
                        component_url: null,
                        eventType: ANALYTICS.eventTypes.track,
                        label: event?.currentTarget?.textContent,
                      },
                      user,
                      userProfileData,
                    });

                    // Additional call for search submitted.
                    triggerSegmentTrack({
                      dataForAnalytics,
                      event: ANALYTICS.events.searchSubmitted,
                      properties: {
                        action: ANALYTICS.actions.updated,
                        component: ANALYTICS.components.peopleFilterContainer,
                        component_url: null,
                        eventType: ANALYTICS.eventTypes.view,
                        form_fields: {
                          ...dataForAnalytics?.form_fields,
                          adults_only: newChildren.includes(
                            ALGOLIA_CONFIG.facets['facets.children'].options
                              .adultsOnly,
                          ),
                          age_search: newAge,
                          childcare_available: newChildren.includes(
                            ALGOLIA_CONFIG.facets['facets.children'].options
                              .childcareAvailable,
                          ),
                          children_welcome: newChildren.includes(
                            ALGOLIA_CONFIG.facets['facets.children'].options
                              .childrenWelcome,
                          ),
                          genders: newGenders,
                          season_of_life: newSeasonOfLife,
                        },
                        form_name: ANALYTICS.forms.search,
                        label: event?.currentTarget?.textContent,
                      },
                      user,
                      userProfileData,
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  /**
   * Handler function for season of life change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onSeasonOfLifeChanged(event) {
    const { value } = event.target;
    storeSeasonOfLife({
      callback: /* istanbul ignore next */ (newSeasonOfLife) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.topicsFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              seasonOfLife: newSeasonOfLife,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for Topic filter change event.
   *
   * @param {object} event - The data object with event and data target information.
   */
  function onTopicChanged(data) {
    storeTopic({
      callback: /* istanbul ignore next */ (newTopics) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.topicsFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              topics: newTopics,
            },
            form_name: ANALYTICS.forms.search,
            label: data?.value,
          },
          user,
          userProfileData,
        });
      },
      value: data?.value,
    });
  }

  /**
   * Handler function for Topic filter group blur event.
   *
   * Note: Ignoring conditional as blur handler tested in sub-component.
   */
  function onTopicFiltersBlur() {
    const dataForAnalytics = getDataForAnalytics();
    const topicFiltersContainer = document.getElementById(
      'id_topic_filters_container',
    );
    /* istanbul ignore next */
    if (
      topicFiltersContainer &&
      topicFiltersContainer.classList.contains('lg-show-dropdown')
    ) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.topicsFilterContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    }
    toggleTopicFilters({ value: false });
  }

  /**
   * Handler function for Topic filter group click event.
   */
  function onTopicFiltersClicked() {
    const dataForAnalytics = getDataForAnalytics();
    if (!isTopicFiltersToggled) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchStarted,
        properties: {
          action: ANALYTICS.actions.expanded,
          component: ANALYTICS.components.topicsFilterContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
          },
          form_name: ANALYTICS.forms.search,
          label: ANALYTICS.actions.expanded,
        },
        user,
        userProfileData,
      });
    } else {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.topicsFilterContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    }
    toggleTopicFilters({ value: !isTopicFiltersToggled });
  }

  /**
   * Handler function for Topic filter group reset event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function onTopicFiltersReset(event) {
    const dataForAnalytics = getDataForAnalytics();
    event.preventDefault();
    resetTopics({
      callback: /* istanbul ignore next */ (newTopics) => {
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.components.topicsFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.track,
            label: event?.currentTarget?.textContent,
          },
          user,
          userProfileData,
        });

        // Additional call for search submitted.
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.topicsFilterContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              topics: newTopics,
            },
            form_name: ANALYTICS.forms.search,
            label: event?.currentTarget?.textContent,
          },
          user,
          userProfileData,
        });
      },
    });
  }

  return (
    <div
      className="lg-search-options lg-search-options-desktop"
      data-testid="lg-search-options"
    >
      <div className="container p-l-half p-r-half">
        <h2 className="lg-header">
          {STRINGS.searchFilters.titles.find.base}{' '}
          {!GROUP_TYPES_MAP.localPartner.includes(groupTypes)
            ? STRINGS.searchFilters.titles.find.lifeGroup
            : STRINGS.searchFilters.titles.find.localPartner}
        </h2>
        <div className="lg-search-filters" data-testid="lg-search-filters">
          <LocationsContainer />
          {filteredList?.includes('int') ? (
            <CountryContainer
              filterFeature="country"
              id="lg-campus-code"
              mobileLabel={STRINGS.searchFilters.labels.country}
              options={churchOnlineLocations}
              value={churchOnlineLocation.slug}
            />
          ) : null}
          {!GROUP_TYPES_MAP.localPartner.includes(groupTypes) ? (
            <>
              <Topics
                classToggle={isTopicFiltersToggled}
                onBlur={onTopicFiltersBlur}
                onChange={onTopicChanged}
                onClick={onTopicFiltersClicked}
                onReset={onTopicFiltersReset}
              />
              <People
                classToggle={isPeopleFiltersToggled}
                onAgeChanged={onAgeChanged}
                onBlur={onPeopleFiltersBlur}
                onChildrenChanged={onChildrenChanged}
                onClick={onPeopleFiltersClicked}
                onGenderChanged={onGenderChanged}
                onSeasonOfLifeChanged={onSeasonOfLifeChanged}
                onReset={onPeopleFiltersReset}
              />
            </>
          ) : null}
          <MoreFilters
            classToggle={isMoreFiltersToggled}
            onBlur={onMoreFiltersBlur}
            onClick={onMoreFiltersClicked}
            onDayChecked={onDayChecked}
            onGroupTypeChange={onGroupTypeChecked}
            onKeywordChanged={onKeywordChanged}
            onMeetingFrequencyChanged={onMeetingFrequencyChanged}
            onMeetingTypeChanged={onMeetingTypeChanged}
            onReset={onMoreFiltersReset}
          />
        </div>
      </div>
    </div>
  );
}

export default DesktopFilters;
