/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LeadershipCoachingIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Leadership Coaching icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Leadership Coaching Icon element.
 */
export default function BookClubIcon({ color = '#000000' }) {
  return (
    <div
      className="icon"
      data-color={color}
      data-testid="leadership-coaching-icon"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.19914 9.24375L6.8612 7.88231C6.97658 7.64856 7.14408 7.46394 7.3637 7.32844C7.58345 7.19281 7.82264 7.125 8.08126 7.125H9.13708V2.553C9.13708 2.35962 9.20176 2.19831 9.33114 2.06906C9.46039 1.93969 9.62164 1.875 9.81489 1.875H13.3543C13.4775 1.875 13.5729 1.92837 13.6406 2.03512C13.7084 2.14187 13.7135 2.25294 13.6558 2.36831L13.3082 3.03319C13.2832 3.08606 13.2707 3.1375 13.2707 3.1875C13.2707 3.2375 13.2832 3.28894 13.3082 3.34181L13.6558 4.00669C13.7135 4.12206 13.7084 4.23313 13.6406 4.33988C13.5729 4.44663 13.4775 4.5 13.3543 4.5H10.2621V7.125H11.2616C11.5203 7.125 11.7556 7.19281 11.9676 7.32844C12.1796 7.46394 12.3471 7.64469 12.4703 7.87069L13.1625 9.26981L11.1578 10.6039L9.69958 9.86531L8.24139 10.6039L6.19914 9.24375ZM4.9862 16.125C4.48133 16.125 4.09645 15.9123 3.83158 15.4868C3.5667 15.0613 3.54726 14.6226 3.77326 14.1707L5.6972 10.2548L8.15776 11.8961L9.69958 11.1347L11.2414 11.8961L13.6716 10.2923L15.6145 14.1591C15.8405 14.6111 15.821 15.0517 15.556 15.4809C15.2911 15.9103 14.9038 16.125 14.3942 16.125H4.9862Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
