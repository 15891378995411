/**
 * @module People
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import Chip from '../../Chip';
import CustomCheckBox from '../../CheckBox/CustomCheckBox';
import { getIcon } from '../../ui/icon-helper';
import { TAG_COLORS } from '../../../utils/constants';
import {
  normalize,
  toggleCheckboxGroup,
  toggleCheckboxGroupArrow,
  toggleCheckboxGroupColor,
} from '../../../utils/helpers';
import { STRINGS } from '../../../utils/strings';
import ResetButton from './ResetButton';

/**
 * Represents a search and filtering options section for People facets.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.classToggle] - Boolean flag denoting the toggle status of the component's elements.
 * @param {boolean} [props.isMobile] - Boolean flag denoting whether or not the page is within the constraints of a mobile viewport.
 * @param {Function} [props.onAgeChanged] - Handler function for the component age input field change event.
 * @param {Function} [props.onBlur] - Handler function for the component onBlur event.
 * @param {Function} [props.onChange] - Handler function for the component onChange event.
 * @param {Function} [props.onChildrenChanged] - Handler function for the component children field change event.
 * @param {Function} [props.onGenderChanged] - Handler function for the component gender field change event.
 * @param {Function} [props.onSeasonOfLifeChanged] - Handler function for the component season of life change event.
 * @param {Function} [props.onClick] - Handler function for the component onClick event.
 * @param {Function} [props.onReset] - Handler function for the component reset event.
 *
 * @returns {React.ReactElement} The People component.
 */
function People({
  classToggle,
  isMobile,
  onAgeChanged = () => {},
  onBlur = () => {},
  onChildrenChanged = () => {},
  onGenderChanged = () => {},
  onSeasonOfLifeChanged = () => {},
  onClick = () => {},
  onReset = () => {},
}) {
  const {
    age,
    algoliaFacets,
    childrenValue: children,
    genders,
    seasonOfLife,
  } = useAlgoliaSearch();

  let peopleFilterLength = 0;
  if (age?.length) {
    peopleFilterLength += 1;
  }
  peopleFilterLength += children?.length ?? 0;
  peopleFilterLength += genders?.length ?? 0;
  peopleFilterLength += seasonOfLife?.length ?? 0;

  const peopleLengthValue =
    peopleFilterLength > 0 ? `(${peopleFilterLength})` : '';
  const lgCheckboxGroupPeopleClassName =
    peopleFilterLength > 0 ? 'lg-black' : toggleCheckboxGroupColor(classToggle);

  /**
   * Convenience function to iterate over people facets and add interactive Chip components for each.
   *
   * Note: Ignore added to default return since each switch statement needs to
   * have a default, but in our instance, all chips have corresponding facets
   * that are covered and will never result in the default return.
   *
   * @returns {React.ReactElement} Series of Chip components for all people facet keys.
   */
  const renderCheckboxes = (facet) => {
    if (!algoliaFacets?.[`facets.${facet}`]) {
      return null;
    }

    const facetArray = Object.keys(algoliaFacets[`facets.${facet}`]);
    const isChecked = (facetType, value) => {
      switch (facetType) {
        case 'children':
          return children?.includes(value);
        case 'genders':
          return genders?.includes(value);
        case 'seasonOfLife':
          return seasonOfLife?.includes(value);
        /* istanbul ignore next */
        default:
          return false;
      }
    };

    /**
     * Handler function for Chip click.
     *
     * Note: Ignore added to default return since each switch statement needs to
     * have a default, but in our instance, all chips have corresponding facets
     * that are covered and will never result in the default return.
     *
     * @param {object} params - The function params object.
     * @param {Event} params.event - The Event object associated with the click.
     * @param {string} params.label - The Chip label.
     */
    function handleChipClick({ event, label }) {
      switch (facet) {
        case 'children':
          return onChildrenChanged({
            ...event,
            target: {
              ...event.target,
              value: label,
            },
          });
        case 'genders':
          return onGenderChanged({
            ...event,
            target: {
              ...event.target,
              value: label,
            },
          });
        case 'seasonOfLife':
          return onSeasonOfLifeChanged({
            ...event,
            target: {
              ...event.target,
              value: label,
            },
          });
        /* istanbul ignore next */
        default:
          return;
      }
    }

    /**
     * If Children facet, render checkboxes. Otherwise, Chips.
     */
    if (facet === 'children') {
      return facetArray.map((facetKey) => (
        <CustomCheckBox
          className="children"
          filterFeature="children"
          key={normalize(facetKey)}
          id={normalize(facetKey)}
          isChecked={isChecked(facet, facetKey)}
          labelClassName="lg-checkbox-children"
          name={facetKey}
          onChange={(event) => {
            event.stopPropagation();
            handleChipClick({ event, label: facetKey });
          }}
          value={facetKey}
        />
      ));
    }

    return facetArray.map((facetKey) => (
      <Chip
        color={
          isChecked(facet, facetKey)
            ? facet === 'genders'
              ? TAG_COLORS[normalize(facetKey)]
              : TAG_COLORS.topics
            : TAG_COLORS.default
        }
        icon={getIcon(
          normalize(facetKey),
          isChecked(facet, facetKey)
            ? facet === 'genders'
              ? TAG_COLORS[normalize(facetKey)].content
              : TAG_COLORS.topics.content
            : TAG_COLORS.default.content,
        )}
        isBordered={true}
        isInteractive={true}
        key={normalize(facetKey)}
        label={facetKey}
        onClick={(event) => {
          event.stopPropagation();
          handleChipClick({ event, label: facetKey });
        }}
      />
    ));
  };

  /**
   * Handler function for container click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleContainerClick(event) {
    if (event.target.closest('#id-people-container')) {
      onClick(event);
    }
  }

  /**
   * Handler function for reset button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleResetClick(event) {
    if (onReset && typeof onReset === 'function') {
      onReset(event);
    }
  }

  /**
   * Handler function for outside click awareness.
   *
   * @param {Event} event - The Event object associated with the blur.
   */
  const handleContainerBlur = React.useCallback((event) => {
    if (
      !event.target.closest('#id-people-checkboxes') &&
      !event.target.closest('#id-people-container') &&
      onBlur &&
      typeof onBlur === 'function'
    ) {
      onBlur(event);
    }
  }, []);

  /**
   * Single-run convenience effect to add handler for outside click awareness.
   */
  React.useEffect(() => {
    document.addEventListener('mouseup', handleContainerBlur);
    return () => {
      document.removeEventListener('mouseup', handleContainerBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="lg-search-people" data-testid="lg-search-people">
      {isMobile ? (
        <>
          <div className="lg-filter-section">
            <h3>{STRINGS.searchFilters.labels.genders}</h3>
            <div className="chip-container">{renderCheckboxes('genders')}</div>
          </div>
          <div className="lg-filter-section">
            <h3>{STRINGS.searchFilters.labels.seasonOfLife}</h3>
            <div className="chip-container">
              {renderCheckboxes('seasonOfLife')}
            </div>
          </div>
          <div className="lg-filter-section">
            <h3>{STRINGS.searchFilters.labels.children}</h3>
            <div className="checkbox-container">
              {renderCheckboxes('children')}
            </div>
          </div>
          <div className="lg-filter-section">
            <h3>{STRINGS.searchFilters.labels.yourAge}</h3>
            <input
              data-testid="lg-search-people-age"
              onChange={onAgeChanged}
              placeholder={STRINGS.searchFilters.labels.age}
              type="text"
              value={age}
            />
          </div>
        </>
      ) : (
        <div
          className="lg-checkbox-group-container"
          data-testid="lg-people-checkboxes"
          id="id-people-checkboxes"
          onClick={handleContainerClick}
          tabIndex={-1}
        >
          <div
            className={`lg-checkbox-group people ${lgCheckboxGroupPeopleClassName}`}
            id="id-people-container"
          >
            <div
              className="menu-text"
              data-testid="lg-people-container-menu-text"
            >
              {`${STRINGS.searchFilters.labels.people} ${peopleLengthValue}`.trim()}
            </div>
            <span
              className={`lg-${toggleCheckboxGroupArrow(
                classToggle,
              )}-arrow-zone`}
            >
              <span
                className={`lg-${toggleCheckboxGroupArrow(classToggle)}-arrow`}
              />
            </span>
          </div>
          <div
            className={`lg-checkbox-container people ${toggleCheckboxGroup(
              classToggle,
            )}`}
            id="id_people_filters_container"
          >
            <div className="lg-desktop-filter-header-wrap">
              <h4 className="lg-checkbox-group-text desktop-topics-text">
                {STRINGS.searchFilters.labels.people}
              </h4>
              <ResetButton onClick={handleResetClick} />
            </div>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.genders}</h3>
              <div className="chip-container">
                {renderCheckboxes('genders')}
              </div>
            </div>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.seasonOfLife}</h3>
              <div className="chip-container">
                {renderCheckboxes('seasonOfLife')}
              </div>
            </div>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.children}</h3>
              <div className="checkbox-container">
                {renderCheckboxes('children')}
              </div>
            </div>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.yourAge}</h3>
              <input
                data-testid="lg-search-people-age"
                onChange={onAgeChanged}
                placeholder={STRINGS.searchFilters.labels.age}
                type="text"
                value={age}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default People;
