/**
 * @module CountrySelector
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import '../CampusCheckbox/CampusCheckbox.scss';
import './CountrySelector.scss';

function CountrySelector({ name, setCountry, setToggleDropdown, slug, value }) {
  const isSelected = value === slug;

  function handleClick() {
    if (!isSelected) {
      setCountry(slug);
    }
    setToggleDropdown(false);
  }

  return (
    <div
      className={`campus-checkbox ${isSelected ? 'country-active' : ''}`}
      data-testid="lg-country-selector"
      onClick={handleClick}
    >
      <label className={'lg-location-checkbox'} htmlFor={name}>
        {name}
      </label>
      <span
        className={`checkbox ${isSelected ? 'campus-active' : ''}`.trim()}
      />
    </div>
  );
}

export default CountrySelector;
