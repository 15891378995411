/**
 * @module LgCard
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { camelToSnake } from '@lifechurch/web-tools-io/dist/utils/helpers/strings';
import { transformKeys } from '@lifechurch/web-tools-io/dist/utils/helpers/transformKeys';
import { v4 as uuidv4 } from 'uuid';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import Chip from '../Chip';
import GlobeIcon from '../ui/GlobeIcon';
import LocationIcon from '../ui/LocationIcon';
import { getIcon } from '../ui/icon-helper';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import {
  APP_CONFIG,
  SNAKE_CASE_EXCEPTIONS,
  TAG_COLORS,
} from '../../utils/constants';
import {
  generateFrequencyLabel,
  generateLocationLabel,
  normalize,
} from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import './LgCard.scss';

/**
 * Represents a card with LifeGroup data, including a hero cover image and key data attributes.
 *
 * @param {object} props - The component props object.
 * @param {LifeGroupAlgoliaData} props.data - The data object of the Algolia-supplied LifeGroup.
 * @param {number} props.index - The index at which the card shows in the list from search.
 *
 * @returns {React.ReactElement} The LgCard component.
 */
function LgCard({ data, index }) {
  const { user } = useAuth();
  const { breakpoints, width } = useWindowSize();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics } = useAlgoliaSearch();
  const [frequencyDayAndTime, setFrequencyDayAndTime] = React.useState(
    generateFrequencyLabel({ data }),
  );
  const [isOnlineMeetingType, setIsOnlineMeetingType] = React.useState(false);

  /**
   * Convenience function to generate and return markup for the LifeGroup card location area.
   *
   * @returns {React.ReactElement} Markup for the LifeGroup location area.
   */
  const generateLgLocation = () => {
    if (isOnlineMeetingType) {
      return (
        <div className="lg-location">
          <span className="lg-location-description">Meets Online</span>
          <GlobeIcon color="#404041" />
        </div>
      );
    } else if (
      generateLocationLabel({
        locationData: data?.meetingLocation,
      }) ||
      data?.meetingLocation?.locationType
    ) {
      return (
        <div className="lg-location">
          <span className="lg-location-description">
            {generateLocationLabel({
              locationData: data?.meetingLocation,
            }) || data?.meetingLocation?.locationType}
          </span>
          <LocationIcon color="#404041" />
        </div>
      );
    }
    return null;
  };

  /**
   * Convenience helper to generate and return a URL to use for the card's anchor tag href attribute.
   *
   * @returns {string} The URL to use for the href attribute, complete with UTM Params implemented.
   */
  const getHref = () => {
    const href =
      data?.facets?.type.toLowerCase() !== 'lifegroups'
        ? `${APP_CONFIG.myLifeChurchLgDomain}/LocalPartners/${data.lgId}`
        : `${window?.location?.origin}/lifegroups/search/lifegroup/${data.objectID}/`;
    return implementUtmParams(href, window?.location);
  };

  /**
   * Handler function for the card click event.
   *
   * @param {Event} event - The Event data object associated with the click.
   */
  function handleCardClick(event) {
    const dataForAnalytics = getDataForAnalytics();
    const lgAnalyticsData = transformKeys(
      {
        ...data,
        position: index,
      },
      camelToSnake,
      SNAKE_CASE_EXCEPTIONS,
    );
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupCard,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: data?.name,
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Convenience effect to set the state value for frequency/day/time label.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (data?.facets) {
      setFrequencyDayAndTime(generateFrequencyLabel({ data }));
    }
  }, [data?.facets]);

  /**
   * Convenience effect to keep track and store meeting type as boolean.
   */
  React.useEffect(() => {
    if (Array.isArray(data?.facets?.meetingType)) {
      setIsOnlineMeetingType(
        data.facets?.meetingType?.[0]?.toLowerCase() === 'online',
      );
    } else {
      setIsOnlineMeetingType(
        data?.facets?.meetingType?.toLowerCase() === 'online',
      );
    }
  }, [data]);

  return (
    <div className="lg-card-container" data-testid="lg-card-component">
      {data ? (
        <a
          data-testid="lg-card-anchor-link"
          href={implementUtmParams(getHref(), window?.location)}
          onClick={handleCardClick}
          rel="noopener noreferrer"
          target={width >= breakpoints.medium ? '_blank' : '_self'}
        >
          <div
            className={`lg-card border-radius-md lg-card-category-${normalize(
              data?.facets?.genders?.[0],
            )}`}
            data-testid="lg-card-category-container"
          >
            {data?.publicCoverImageUrl ? (
              <div
                className="card-hero"
                style={{
                  backgroundColor: TAG_COLORS[
                    normalize(data?.facets?.genders?.[0])
                  ]
                    ? TAG_COLORS[normalize(data?.facets?.genders?.[0])]
                        .background
                    : TAG_COLORS.default.background,
                  backgroundImage: `url(${data.publicCoverImageUrl})`,
                }}
              ></div>
            ) : (
              <div
                className="card-hero"
                style={{
                  backgroundColor: TAG_COLORS[
                    normalize(data?.facets?.genders?.[0])
                  ]
                    ? TAG_COLORS[normalize(data?.facets?.genders?.[0])]
                        .background
                    : TAG_COLORS.anyone.background,
                  backgroundSize: '100%',
                }}
              >
                {data?.groupLeaders ? (
                  <>
                    <div
                      className="lg-leader-avatar-bg"
                      style={{
                        backgroundImage: /* istanbul ignore next */ data
                          ?.groupLeaders?.[0]?.publicAvatarUrl
                          ? `url(${data.groupLeaders[0].publicAvatarUrl})`
                          : '',
                      }}
                    ></div>
                    <div
                      className="lg-leader-avatar-color-overlay"
                      style={{
                        backgroundColor: /* istanbul ignore next */ TAG_COLORS[
                          normalize(data?.facets?.genders?.[0])
                        ]
                          ? TAG_COLORS[normalize(data?.facets?.genders?.[0])]
                              .overlay
                          : TAG_COLORS.default.overlay,
                      }}
                    ></div>
                    <div className="lg-leader-avatar-grid">
                      {data?.groupLeaders.map((leaderData, index) => {
                        /* Max show of 2 leaders, per Product Design decision. */
                        /* istanbul ignore next */
                        if (index < 2 && leaderData.publicAvatarUrl) {
                          return (
                            <img
                              alt={
                                leaderData.firstName ??
                                STRINGS.global.groupLeader
                              }
                              key={`${leaderData.firstName}-${uuidv4()}`}
                              src={leaderData.publicAvatarUrl}
                            />
                          );
                        } else {
                          /* istanbul ignore next */
                          return null;
                        }
                      })}
                    </div>
                  </>
                ) : null}
              </div>
            )}
            <div className="card-content">
              <p className="lg-frequency">{frequencyDayAndTime}</p>
              <h3 className="lg-name">{data?.name}</h3>
              <div className="lg-chips">
                <div className="lg-topics">
                  {data.facets?.topic?.map((topicData) => (
                    <Chip
                      color={TAG_COLORS.topics}
                      icon={getIcon(
                        normalize(
                          typeof topicData === 'string'
                            ? topicData
                            : topicData.name,
                        ),
                        TAG_COLORS.topics.content,
                      )}
                      key={normalize(
                        typeof topicData === 'string'
                          ? topicData
                          : topicData.name,
                      )}
                      label={
                        typeof topicData === 'string'
                          ? topicData
                          : topicData.name
                      }
                    />
                  ))}
                </div>
                {data?.facets?.genders?.[0] || generateLgLocation() ? (
                  <div className="lg-season-location">
                    <Chip
                      color={
                        data?.facets?.genders?.[0]
                          ? TAG_COLORS[normalize(data?.facets?.genders?.[0])]
                          : TAG_COLORS.transparent
                      }
                      label={data?.facets?.genders?.[0] ?? ' '}
                    />
                    {generateLgLocation()}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </a>
      ) : null}
    </div>
  );
}

export default LgCard;
