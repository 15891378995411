/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module HourglassIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an Hourglass icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Hourglass Icon element.
 */
export default function HourglassIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="hourglass-icon">
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 14.4H9V12C9 11.12 8.70625 10.3667 8.11875 9.74C7.53125 9.11333 6.825 8.8 6 8.8C5.175 8.8 4.46875 9.11333 3.88125 9.74C3.29375 10.3667 3 11.12 3 12V14.4ZM6 7.2C6.825 7.2 7.53125 6.88667 8.11875 6.26C8.70625 5.63333 9 4.88 9 4V1.6H3V4C3 4.88 3.29375 5.63333 3.88125 6.26C4.46875 6.88667 5.175 7.2 6 7.2ZM0.75 16C0.5375 16 0.359375 15.9233 0.215625 15.77C0.071875 15.6167 0 15.4267 0 15.2C0 14.9733 0.071875 14.7833 0.215625 14.63C0.359375 14.4767 0.5375 14.4 0.75 14.4H1.5V12C1.5 11.1867 1.67813 10.4233 2.03438 9.71C2.39062 8.99667 2.8875 8.42667 3.525 8C2.8875 7.57333 2.39062 7.00333 2.03438 6.29C1.67813 5.57667 1.5 4.81333 1.5 4V1.6H0.75C0.5375 1.6 0.359375 1.52333 0.215625 1.37C0.071875 1.21667 0 1.02667 0 0.8C0 0.573333 0.071875 0.383333 0.215625 0.23C0.359375 0.0766667 0.5375 0 0.75 0H11.25C11.4625 0 11.6406 0.0766667 11.7844 0.23C11.9281 0.383333 12 0.573333 12 0.8C12 1.02667 11.9281 1.21667 11.7844 1.37C11.6406 1.52333 11.4625 1.6 11.25 1.6H10.5V4C10.5 4.81333 10.3219 5.57667 9.96563 6.29C9.60938 7.00333 9.1125 7.57333 8.475 8C9.1125 8.42667 9.60938 8.99667 9.96563 9.71C10.3219 10.4233 10.5 11.1867 10.5 12V14.4H11.25C11.4625 14.4 11.6406 14.4767 11.7844 14.63C11.9281 14.7833 12 14.9733 12 15.2C12 15.4267 11.9281 15.6167 11.7844 15.77C11.6406 15.9233 11.4625 16 11.25 16H0.75Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
