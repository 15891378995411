/**
 * @module MoreLikeThis
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import LgCard from '../LgCard';
import { STRINGS } from '../../utils/strings';
import './DetailPage.scss';

/**
 * Represents a section of a LifeGroup detail page including additional related groups similar to the selected group.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.relatedGroups - Array of LifeGroupData data objects.
 *
 * @returns {React.ReactElement} - The MoreLikeThis component.
 */
function MoreLikeThis({ relatedGroups }) {
  if (!relatedGroups || !Array.isArray(relatedGroups)) {
    return null;
  }

  const renderList = relatedGroups.map((group) => (
    <LgCard data={group} key={group.objectID} />
  ));

  return (
    <div
      className="lg-detail-more-like-this"
      data-testid="lg-group-detail-more-like-this"
    >
      <div className="container">
        <h2>{STRINGS.groupDetail.moreLikeThis.title}</h2>
        <div className="lg-detail-cards" data-testid="lg-detail-cards">
          {renderList}
        </div>
      </div>
    </div>
  );
}

export default MoreLikeThis;
