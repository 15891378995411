/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LocationIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Location icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Location Icon element.
 */
export default function LocationIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="location-icon">
      <svg
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.71726 13.8814C8.17637 12.6391 9.31822 11.4037 10.1428 10.175C10.9674 8.94636 11.3797 7.85221 11.3797 6.8926C11.3797 5.5714 10.9315 4.44403 10.035 3.51049C9.13862 2.57696 8.03269 2.11019 6.71726 2.11019C5.40183 2.11019 4.2959 2.57696 3.39947 3.51049C2.50305 4.44403 2.05483 5.5714 2.05483 6.8926C2.05483 7.85221 2.46713 8.94322 3.29171 10.1656C4.1163 11.388 5.25815 12.6266 6.71726 13.8814ZM6.71726 15.5C6.52055 15.5 6.32697 15.4685 6.13653 15.4054C5.94596 15.3422 5.76629 15.2388 5.59754 15.0951C4.83476 14.4103 4.13724 13.7226 3.50498 13.0321C2.87284 12.3416 2.33015 11.6545 1.87693 10.9707C1.42357 10.2869 1.07033 9.60269 0.817197 8.91802C0.564066 8.23323 0.4375 7.55809 0.4375 6.8926C0.4375 5.1692 1.03974 3.67292 2.24421 2.40375C3.44881 1.13458 4.93983 0.5 6.71726 0.5C8.4947 0.5 9.98571 1.13458 11.1903 2.40375C12.3948 3.67292 12.997 5.1692 12.997 6.8926C12.997 7.55809 12.8705 8.23197 12.6173 8.91426C12.3642 9.59668 12.0122 10.281 11.5614 10.9671C11.1104 11.6533 10.5688 12.3404 9.93669 13.0285C9.30455 13.7167 8.60703 14.4031 7.84413 15.0878C7.67713 15.2314 7.49728 15.3361 7.30458 15.4016C7.112 15.4672 6.91623 15.5 6.71726 15.5ZM6.71858 8.35365C7.14297 8.35365 7.50681 8.20157 7.81009 7.89741C8.11349 7.59325 8.2652 7.22898 8.2652 6.80458C8.2652 6.38019 8.11312 6.01629 7.80896 5.71289C7.5048 5.40961 7.14046 5.25796 6.71594 5.25796C6.29155 5.25796 5.92771 5.41004 5.62443 5.7142C5.32103 6.01836 5.16932 6.3827 5.16932 6.80722C5.16932 7.23161 5.3214 7.59545 5.62556 7.89873C5.92972 8.20201 6.29406 8.35365 6.71858 8.35365Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
