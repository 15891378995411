/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module ParentingIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Parenting icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Parenting Icon element.
 */
export default function ParentingIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="parenting-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.47656 1.5C6.30156 1.5 6.97656 2.175 6.97656 3C6.97656 3.825 6.30156 4.5 5.47656 4.5C4.65156 4.5 3.97656 3.825 3.97656 3C3.97656 2.175 4.65156 1.5 5.47656 1.5ZM12.2266 7.125C12.2266 7.7475 12.7291 8.25 13.3516 8.25C13.9741 8.25 14.4766 7.7475 14.4766 7.125C14.4766 6.5025 13.9741 6 13.3516 6C12.7291 6 12.2266 6.5025 12.2266 7.125ZM14.4766 9H12.3466C11.9116 9.0075 11.4916 9.24 11.2591 9.645L10.5691 10.635L7.89156 6C7.61406 5.5275 7.11906 5.2575 6.60906 5.25H4.35156C3.52656 5.25 2.85156 5.925 2.85156 6.75V10.5C2.85156 10.9125 3.18906 11.25 3.60156 11.25H3.97656V15.75C3.97656 16.1625 4.31406 16.5 4.72656 16.5H6.97656C7.38906 16.5 7.72656 16.1625 7.72656 15.75V8.7075L9.40656 11.625C9.54156 11.8575 9.78906 12 10.0591 12H10.8841C11.1316 12 11.3566 11.88 11.4991 11.6775L11.8516 11.175V15.75C11.8516 16.1625 12.1891 16.5 12.6016 16.5H14.1016C14.5141 16.5 14.8516 16.1625 14.8516 15.75V12.75C15.2641 12.75 15.6016 12.4125 15.6016 12V10.125C15.6016 9.51 15.0991 9 14.4766 9Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
