/**
 * @module useAlgoliaSearch
 */
import React from 'react';
import { AlgoliaSearchContext } from '../../context/AlgoliaSearchContext';

/**
 * React hook for accessing `AlgoliaSearchContext`. Must be used inside of a `AlgoliaSearchProvider` context.
 *
 * @throws {Error} - Throws an error if used outside of a `AlgoliaSearchProvider` context.
 *
 * @returns {React.Context} - The AlgoliaSearchContext values.
 *
 * @example
 * import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
 *
 * function SampleComponent() {
 *   const { searchResults } = useAlgoliaSearch();
 *
 *   return (
 *     <SomeComponent searchResults={searchResults} />
 *   )
 * }
 */
function useAlgoliaSearch() {
  const context = React.useContext(AlgoliaSearchContext);
  if (context === undefined) {
    throw new Error(
      'useAlgoliaSearch must be used within a AlgoliaSearchProvider',
    );
  }
  return context;
}

export default useAlgoliaSearch;
