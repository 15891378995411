/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module VolunteeringIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Volunteering icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Volunteering Icon element.
 */
export default function VolunteeringIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="volunteering-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0262 7.8025L7.27625 5.125C6.75125 4.75 6.05375 4.75 5.53625 5.125L1.78625 7.8025C1.38875 8.0875 1.15625 8.5375 1.15625 9.025V15.0025C1.15625 15.415 1.49375 15.7525 1.90625 15.7525H4.90625V11.2525H7.90625V15.7525H10.9062C11.3188 15.7525 11.6562 15.415 11.6562 15.0025V9.025C11.6562 8.5375 11.4237 8.0875 11.0262 7.8025Z"
          fill={color}
        />
        <path
          d="M16.1788 2.25H9.38375C8.56625 2.25 7.90625 2.91 7.90625 3.7275L7.97375 3.795C8.03375 3.8325 8.09375 3.8625 8.15375 3.9L11.9037 6.5775C12.4738 6.9825 12.8788 7.5825 13.0588 8.25H14.6562V9.75H13.1562V11.25H14.6562V12.75H13.1562V15V15.75H16.1788C16.9963 15.75 17.6562 15.09 17.6562 14.2725V3.7275C17.6562 2.91 16.9963 2.25 16.1788 2.25ZM14.6562 6.75H13.1562V5.25H14.6562V6.75Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
