/**
 * @module Schedule
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import Chip from '../../Chip';
import CustomCheckBox from '../../CheckBox/CustomCheckBox';
import { getIcon } from '../../ui/icon-helper';
import { DAYS_OF_WEEK_MAP, TAG_COLORS } from '../../../utils/constants';
import { normalize, orderDaysOfWeek } from '../../../utils/helpers';
import { STRINGS } from '../../../utils/strings';

/**
 * Represents a search and filtering options section for Schedule facets.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isMobile] - Boolean flag denoting whether or not the page is within the constraints of a mobile viewport.
 * @param {Function} [props.onDayChecked] - Handler function for the component days field change event.
 * @param {Function} [props.onMeetingFrequencyChanged] - Handler function for the component frequency field change event.
 *
 * @returns {React.ReactElement} The Schedule component.
 */
function Schedule({
  isMobile,
  onDayChecked = () => {},
  onMeetingFrequencyChanged = () => {},
}) {
  const { algoliaFacets, days, meetingFrequency } = useAlgoliaSearch();

  /**
   * Convenience function to iterate over topics facets and add interactive Chip components for each.
   *
   * Note: Ignore added to default return since each switch statement needs to
   * have a default, but in our instance, all chips have corresponding facets
   * that are covered and will never result in the default return.
   *
   * @returns {React.ReactElement} Series of Chip components for all topics facet keys.
   */
  const renderCheckboxes = (facet) => {
    /* istanbul ignore next */
    if (!algoliaFacets?.[`facets.${facet}`]) {
      return null;
    }

    let facetArray = Object.keys(algoliaFacets[`facets.${facet}`]);

    // If Meeting Day of Week Full facet, render with result of ordered days.
    if (facet === 'meetingDayOfWeekFull') {
      facetArray = orderDaysOfWeek(algoliaFacets[`facets.${facet}`]);
    }

    const isChecked = (facetType, value) => {
      switch (facetType) {
        case 'meetingDayOfWeekFull':
          return days?.includes(value);
        case 'meetingFrequency':
          return meetingFrequency?.includes(value);
        /* istanbul ignore next */
        default:
          return false;
      }
    };

    /**
     * Handler function for Chip click.
     *
     * Note: Ignore added to default return since each switch statement needs to
     * have a default, but in our instance, all chips have corresponding facets
     * that are covered and will never result in the default return.
     *
     * @param {object} params - The function params object.
     * @param {Event} params.event - The Event object associated with the click.
     * @param {string} params.label - The Chip label.
     */
    function handleChipClick({ event, label }) {
      switch (facet) {
        case 'meetingFrequency':
          return onMeetingFrequencyChanged({
            ...event,
            target: {
              ...event.target,
              value: label,
            },
          });
        case 'meetingDayOfWeekFull':
          return onDayChecked(label);
        /* istanbul ignore next */
        default:
          return;
      }
    }

    /**
     * If Meeting Frequency facet, render checkboxes. Otherwise, Chips.
     */
    if (facet === 'meetingFrequency') {
      return facetArray.map((facetKey) => (
        <CustomCheckBox
          className="meetingFrequency"
          filterFeature="meetingFrequency"
          key={normalize(facetKey)}
          id={normalize(facetKey)}
          isChecked={isChecked(facet, facetKey)}
          labelClassName="lg-checkbox-meeting-frequency"
          name={facetKey}
          onChange={(event) => {
            event.stopPropagation();
            handleChipClick({ event, label: facetKey });
          }}
          value={facetKey}
        />
      ));
    }

    return facetArray.map((facetKey) => (
      <Chip
        color={
          isChecked(facet, facetKey) ? TAG_COLORS.topics : TAG_COLORS.default
        }
        icon={getIcon(
          normalize(facetKey),
          isChecked(facet, facetKey)
            ? TAG_COLORS.topics.content
            : TAG_COLORS.default.content,
        )}
        isBordered={true}
        isInteractive={true}
        key={normalize(facetKey)}
        label={DAYS_OF_WEEK_MAP[facetKey.toLowerCase()]}
        onClick={(event) => {
          event.stopPropagation();
          handleChipClick({ event, label: facetKey });
        }}
      />
    ));
  };

  return (
    <div className="lg-search-schedule" data-testid="lg-search-schedule">
      {isMobile ? (
        <>
          <div className="lg-filter-section">
            <h3>{STRINGS.searchFilters.labels.meetingDays}</h3>
            <div className="chip-container">
              {renderCheckboxes('meetingDayOfWeekFull')}
            </div>
          </div>
          <div className="lg-filter-section">
            <h3>{STRINGS.searchFilters.labels.meetingFrequency}</h3>
            <div className="checkbox-container">
              {renderCheckboxes('meetingFrequency')}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Schedule;
