/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LifeChurchLogomarkIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Life.Church logomark icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Life.Church logomark Icon element.
 */
export default function LifeChurchLogomarkIcon({ color = '#000000' }) {
  return (
    <div
      className="icon"
      data-color={color}
      data-testid="lifechurch-logomark-icon"
    >
      <svg
        width="20"
        height="12"
        viewBox="0 0 20 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95104 4.85834C9.81771 5.08256 9.06215 6.20367 8.48438 7.10055L11.9066 6.60726L12.1733 6.56242C12.7955 6.47273 13.0177 5.88976 13.0622 5.75522C13.7733 4.18567 14.4844 4.09598 15.8177 3.82692C18.6622 3.19909 19.9955 0.912032 19.9955 0.867188C18.3955 1.13625 16.0399 1.53985 16.0399 1.53985C11.6844 2.34705 10.7066 3.60269 9.95104 4.85834Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0234 9.88068C11.0234 9.88068 14.8012 9.43224 16.179 8.98379C18.5346 8.17659 19.3346 6.38281 19.3346 6.38281C19.3346 6.38281 15.7346 6.78641 14.1346 7.50393C13.8234 7.63846 12.8012 7.86268 11.0234 9.88068Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.84444 10.1493C11.1111 9.07306 11.6889 7.68289 11.6889 7.68289C11.6889 7.68289 11.1111 7.72773 10.3111 7.86226L7.46667 8.26586C7.2 8.62462 6.71111 9.2076 6.57778 9.34213C6.22222 9.74573 6 9.74573 5.11111 9.83542C4.88889 9.83542 4.8 9.70088 4.93333 9.43182L5.86667 7.9968C5.95556 7.81742 6.08889 7.63804 6.13333 7.54835L9.24444 2.75C6.22222 3.42267 4.26667 4.81284 3.42222 6.11333L0 11.5844L8.17778 10.4184C8.4 10.3287 8.66667 10.239 8.84444 10.1493Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
