/**
 * @module SearchContainer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import DesktopFilters from '../SearchFilters/DesktopFilters';
import MobileFilters from '../SearchFilters/MobileFilters';
import StartLifeGroupBanner from '../StartLifeGroupBanner';
import './SearchContainer.scss';

/**
 * Represents a container for holding search elements.
 *
 * @returns {React.ReactElement} The SearchContainer component.
 */
function SearchContainer() {
  const { breakpoints, width } = useWindowSize();

  return (
    <div
      className="lifegroups-search"
      data-testid="lg-search-container"
      id="lg-to-the-top"
    >
      <StartLifeGroupBanner />
      {width <= breakpoints.medium ? <MobileFilters /> : <DesktopFilters />}
    </div>
  );
}

export default SearchContainer;
