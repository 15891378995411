/**
 * @module BackButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { filterAttributesForElement } from '@lifechurch/web-tools-io/dist/utils/helpers/attributes';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';

/**
 * Represents a Back button element, containing a left-facing arrow.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the button element.
 * @param {string} [props.color] - Optional hex color value, used to color the arrow (Default: #000000).
 * @param {Function} props.onClick - Function to invoke on click event.
 * @param {object} [props.style] - Optional style object to apply to the button element.
 * @param {object} [props.passThroughProps] - Optional additional props to pass along to the button element. This allows additional attributes, such as autofocus, disabled, type, etc., to be used.
 *
 * @returns {React.ReactElement} The BackButton component.
 */
const BackButton = ({
  className,
  color,
  onClick,
  style,
  ...passThroughProps
}) => {
  /**
   * Button styles, which are used by default, but can be overwritten if any
   * styles are passed in as a prop. Used in place of SCSS to avoid need for
   * inner-component class declaration and separate file, and allow for maximum
   * extensibility and being as style- and class-agnostic as possible.
   *
   * @see {@link https://www.svgbackgrounds.com/how-to-add-svgs-with-css-background-image/}.
   * @see {@link https://www.svgbackgrounds.com/tools/svg-to-css/}.
   */
  const buttonStyles = {
    backgroundImage: `url("data:image/svg+xml,%3Csvg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M25.3307 14.8307H11.1324L17.6541 8.30906L15.9974 6.66406L6.66406 15.9974L15.9974 25.3307L17.6424 23.6857L11.1324 17.1641H25.3307V14.8307Z' fill='${encodeURIComponent(
      color || '#666667',
    )}' /%3E%3C/svg%3E")`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    height: '28px',
    padding: '0.5rem',
    width: '28px',
  };

  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'button',
  });

  return (
    <button
      className={convertValueToClassName(className)}
      data-color={color || '#666667'}
      data-testid="back-button"
      onClick={onClick}
      style={{ ...buttonStyles, ...style }}
      type="button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...filteredProps}
    ></button>
  );
};

export default BackButton;
