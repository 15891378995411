/**
 * @module ConnectionHelp
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Button from '../ButtonItem/ButtonItem';
import { STRINGS } from '../../utils/strings';
import './DetailPage.scss';

/**
 * Represents a section of UI with verbiage about getting connected and button to message LG pastor.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onBackToSearchClick - Handler function for Back to Search button click event.
 * @param {Function} props.onInterestMessageClick - Handler function for LG Pastor Message button click event.
 *
 * @returns {React.ReactElement} The ConnectionHelp component.
 */
function ConnectionHelp({ onBackToSearchClick, onInterestMessageClick }) {
  const baseUrl = window?.location?.href.split('/lifegroup/')[0];

  /**
   * Handler function for Interest and Contact LG pastor button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleInterestMessageClick(event) {
    if (
      onInterestMessageClick &&
      typeof onInterestMessageClick === 'function'
    ) {
      onInterestMessageClick(event);
    }
  }

  /**
   * Handler function for Back to Search button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleBackToSearchClick(event) {
    if (onBackToSearchClick && typeof onBackToSearchClick === 'function') {
      onBackToSearchClick(event);
    }
  }

  return (
    <div
      className="lg-detail-interest container lg-detail-connection-help"
      data-testid="lg-detail-connection-help"
    >
      <div className="lg-connect-help-wrap">
        <h2>{STRINGS.groupDetail.connectionHelpTitle}</h2>
        <div className="button-container">
          <div className="group align-center">
            <Button
              buttonSize="medium"
              className="button-secondary button-medium"
              icon={{
                externalIcon: `icon-message-bubble`,
                field: 'external-icons',
              }}
              onClick={handleInterestMessageClick}
              style="btn-secondary"
              text={STRINGS.groupDetail.messageLifeGroupPastor}
            />
          </div>
        </div>
        <div className="button-container">
          <div className="group align-center">
            <Button
              buttonSize="medium"
              className="button-tertiary button-medium"
              icon={{
                directionalIcon: `icon-angle-left`,
                field: 'directional-icons',
              }}
              onClick={handleBackToSearchClick}
              style="btn-tertiary"
              text={STRINGS.groupDetail.backToSearch}
              url={baseUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectionHelp;
