/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module RecoveryIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Recovery icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Recovery Icon element.
 */
export default function RecoveryIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="recovery-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8199 15.098C10.2499 15.6155 9.37237 15.6155 8.80237 15.0905L8.71987 15.0155C4.78237 11.453 2.20987 9.12049 2.30737 6.21049C2.35237 4.93549 3.00487 3.71299 4.06237 2.99299C6.04237 1.64299 8.48737 2.27299 9.80737 3.81799C11.1274 2.27299 13.5724 1.63549 15.5524 2.99299C16.6099 3.71299 17.2624 4.93549 17.3074 6.21049C17.4124 9.12049 14.8324 11.453 10.8949 15.0305L10.8199 15.098Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
