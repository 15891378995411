/**
 * @module SwitchMessage
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import { URLS } from '../../../utils/constants';
import { STRINGS } from '../../../utils/strings';

/**
 * Represents a simple container with Switch-specific verbiage and call-to-action button.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onClick - Handler function for button click.
 *
 * @returns {React.ReactElement} The SwitchMessage component.
 */
function SwitchMessage({ onClick }) {
  /**
   * Handler function for the button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  }

  return (
    <div className="container" data-testid="lg-switch-container">
      <div className="text-center">
        <h1 className="title">{STRINGS.switchMessage.title}</h1>
        <p className="subtitle">
          <a
            href={implementUtmParams(URLS.switch, window?.location)}
            onClick={handleButtonClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            {STRINGS.switchMessage.cta}
          </a>
        </p>
      </div>
    </div>
  );
}

export default SwitchMessage;
