/**
 * @module InterestContact
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { v4 as uuidv4 } from 'uuid';
import Button from '../ButtonItem/ButtonItem';
import { ANALYTICS } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import './DetailPage.scss';

/**
 * Represents a section of UI with interest and contact verbiage and Contact Us button.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.groupLeaders - Array of group leaders data objects.
 * @param {string} props.groupName - The group name.
 * @param {Function} props.onClick - Handler function for Contact button click event.
 *
 * @returns {React.ReactElement} The InterestContact component.
 */
function InterestContact({ groupLeaders, groupName, onClick }) {
  const { isMobile } = useWindowSize();

  /**
   * Handler function for Contact Us button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event, { component: ANALYTICS.components.lifeGroupDetailPage });
    }
  }

  return (
    <div
      className="lg-interest-contact-wrap"
      data-testid="lg-group-detail-interest-contact"
    >
      <div className="lg-interest-text">
        {!isMobile ? (
          <h3>{STRINGS.groupDetail.interestContact.title}</h3>
        ) : null}
        <p>
          {`Contact the leader${groupLeaders?.length > 1 ? 's' : ''}${
            groupName ? ' to connect with ' + groupName + '' : ''
          }`}
        </p>
        <div className="button-container">
          <div className={`group ${isMobile ? 'align-center' : 'align-left'}`}>
            <Button
              buttonSize={`${isMobile ? 'medium' : 'large'}`}
              className={`button-primary button-${
                isMobile ? 'medium' : 'large'
              }`}
              onClick={handleButtonClick}
              style="btn-primary"
              text={STRINGS.global.contactUs}
            />
          </div>
        </div>
      </div>
      {groupLeaders ? (
        <div className="lg-leader-avatars-wrap flex-align-content-center">
          {groupLeaders.map((leaderData, index) => {
            /* Max show of 2 leaders, per Product Design decision. */
            if (index < 2) {
              return (
                <div
                  className="lg-leader-avatar"
                  key={`${
                    leaderData.firstName ?? STRINGS.global.groupLeader
                  }-${uuidv4()}`}
                >
                  <div
                    className="image"
                    style={
                      leaderData?.publicAvatarUrl
                        ? {
                            backgroundImage: `url(${leaderData.publicAvatarUrl})`,
                          }
                        : {}
                    }
                  />
                  <h4>{leaderData?.firstName ?? STRINGS.global.leader}</h4>
                </div>
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
}

export default InterestContact;
