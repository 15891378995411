/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MentoringIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Mentoring icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Mentoring Icon element.
 */
export default function MentoringIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="mentoring-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.82812 4.125C8.65312 4.125 9.32812 3.45 9.32812 2.625C9.32812 1.8 8.65312 1.125 7.82812 1.125C7.00313 1.125 6.32812 1.8 6.32812 2.625C6.32812 3.45 7.00313 4.125 7.82812 4.125ZM5.01562 6.675L3.12563 16.3575C3.03563 16.8225 3.38813 17.25 3.86063 17.25H3.92813C4.28063 17.25 4.58813 17.0025 4.66313 16.6575L5.84062 11.25L7.45312 12.75V16.5C7.45312 16.9125 7.79063 17.25 8.20312 17.25C8.61562 17.25 8.95312 16.9125 8.95312 16.5V11.895C8.95312 11.6925 8.87063 11.505 8.73563 11.3625L7.41562 10.05L7.86563 7.8C8.66813 8.79 9.80062 9.4725 11.0981 9.6825C11.5481 9.7575 11.9531 9.39 11.9531 8.9325C11.9531 8.565 11.6831 8.2575 11.3231 8.1975C10.2056 8.01 9.26062 7.335 8.69062 6.4125L7.97812 5.2125C7.71562 4.7625 7.22813 4.5 6.70312 4.5C6.51562 4.5 6.32812 4.5375 6.14062 4.6125L2.66812 6.0375C2.39062 6.15 2.20312 6.4275 2.20312 6.7275V9C2.20312 9.4125 2.54062 9.75 2.95312 9.75C3.36563 9.75 3.70312 9.4125 3.70312 9V7.2375L5.01562 6.675ZM16.4531 1.5H11.2031C10.7906 1.5 10.4531 1.8375 10.4531 2.25V6C10.4531 6.4125 10.7906 6.75 11.2031 6.75H13.2656V16.6875C13.2656 16.995 13.5206 17.25 13.8281 17.25C14.1356 17.25 14.3906 16.995 14.3906 16.6875V6.75H16.4531C16.8656 6.75 17.2031 6.4125 17.2031 6V2.25C17.2031 1.8375 16.8656 1.5 16.4531 1.5ZM15.8156 4.3875L14.8556 5.355C14.6231 5.595 14.2181 5.4225 14.2181 5.0925V4.6875H12.1481C11.8406 4.6875 11.5856 4.4325 11.5856 4.125C11.5856 3.8175 11.8406 3.5625 12.1481 3.5625H14.2181V3.1575C14.2181 2.82 14.6231 2.655 14.8556 2.895L15.8156 3.8625C15.9581 4.005 15.9581 4.245 15.8156 4.3875Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
