/**
 * @module StartLifeGroupBanner
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { APP_CONFIG } from '../../utils/constants';
import { STRINGS } from '../../utils/strings';
import './StartLifeGroupBanner.scss';

/**
 * Represents a simple banner to display Start a LifeGroup text and call to action.
 *
 * @param {object} props - The component props object.
 * @param {string} props.slug - The selected campus location slug value. (Example: 'int', 'edm'.)
 *
 * @returns {React.ReactElement} The StartLifeGroupBanner component.
 */
function StartLifeGroupBanner({ slug }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics } = useAlgoliaSearch();

  const lifegroupLinkUrl = `https://www.life.church/webview-app/multistep-forms/serving/?utm_source=web&utm_medium=lifegroup-search-app&opportunity=${APP_CONFIG.servingOpportunityGuid}`;
  const churchOnlineLinkUrl =
    'https://lp.life.church/onlinelifegroup/?utm_medium=referral&utm_source=life_church&utm_campaign=co_life_group&utm_content=co_start_life_group';
  const url = slug === 'int' ? churchOnlineLinkUrl : lifegroupLinkUrl;

  return (
    <section
      className="announcement backgroundColor-gray5 color-gray"
      data-testid="lg-church-online-banner"
    >
      <div className="wrapper">
        <p>{STRINGS.banners.startLifeGroup.title}</p>
        <a
          className="button button-primary button-small m-b-0 m-l-half"
          href={implementUtmParams(url, window?.location)}
          onClick={(event) => {
            const dataForAnalytics = getDataForAnalytics();
            triggerSegmentTrack({
              dataForAnalytics,
              event: ANALYTICS.events.buttonAction,
              properties: {
                action: ANALYTICS.actions.clicked,
                component: ANALYTICS.components.banner,
                component_url: event?.currentTarget?.getAttribute('href'),
                label: event?.currentTarget?.textContent,
              },
              user,
              userProfileData,
            });
          }}
          rel="noopener noreferrer"
          target="_blank"
        >
          {STRINGS.banners.startLifeGroup.cta.label}
        </a>
      </div>
    </section>
  );
}

export default StartLifeGroupBanner;
