/**
 * @module CategoryCard
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './CategoryBasedSearch.scss';

/**
 * Represents a card with image, title, and description to display category data.
 *
 * @param {object} props - The component props object.
 * @param {object} props.data - Category data object containing image, title, and description values.
 * @param {Function} props.onClick - Handler function for category card click.
 *
 * @returns {React.ReactElement} The CategoryCard component.
 */
function CategoryCard({ data, onClick, ...passThroughProps }) {
  /**
   * Handler function for card click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event, data);
    }
  }

  return (
    <div
      className="category-search-card text-left"
      onClick={handleClick}
      {...passThroughProps}
    >
      <div
        className="category-image-container"
        style={{ backgroundImage: `url(${data?.image}` }}
      ></div>
      <div className="category-text-container">
        <h3 className="title">{data?.title}</h3>
        <p className="description">{data?.description}</p>
      </div>
    </div>
  );
}

export { CategoryCard };
