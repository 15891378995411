/**
 * @module LgCardPlaceholder
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Chip from '../Chip';
import { TAG_COLORS } from '../../utils/constants';
import './LgCard.scss';

/**
 * Represents a placeholder LgCard component with blocks of "loading" content state.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isLegacy - Optional boolean flag denoting whether to use the wireframe and structure of a LgLegacyCard.
 *
 * @returns {React.ReactElement} The LgCardPlaceholder component.
 */
function LgCardPlaceholder({ isLegacy }) {
  if (isLegacy) {
    return (
      <div
        className="lg-legacy-card-wrapper placeholder"
        data-testid="lg-legacy-card-placeholder-component"
      >
        <div className="lg-legacy-card border-radius-md">
          <div className="lg-legacy-card-side-bar">
            <div className="lg-legacy-card-meta">
              <div className="lg-legacy-day-of-week">&nbsp;</div>
            </div>
          </div>
          <div className="lg-legacy-card-content p-t-1">
            <div className="lg-legacy-card-name">&nbsp;</div>
            <div className="lg-legacy-card-campus"></div>
            <div className="lg-legacy-card-description"></div>
            <div className="description-fade" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="lg-card-container placeholder"
      data-testid="lg-card-placeholder-component"
    >
      <div
        className="lg-card border-radius-md"
        data-testid="lg-card-category-container"
      >
        <div className="card-hero"></div>
        <div className="card-content">
          <div className="lg-frequency">&nbsp;</div>
          <div className="lg-name">&nbsp;</div>
          <div className="lg-chips">
            <div className="lg-season-location">
              <Chip color={TAG_COLORS.placeholder} label="&nbsp" />
              <div className="lg-location">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LgCardPlaceholder;
