/**
 * @module CampusCheckboxContainer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import CampusCheckbox from '../CampusCheckbox';
import MetroGroup from '../MetroGroup';
import './CampusCheckboxContainer.scss';

/**
 * Represents a container for U.S. state-based campus checkbox elements.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.campuses - Array of campus locations for the U.S. state group.
 * @param {string} props.state - The state value for the group of campuses.
 *
 * @returns {React.ReactElement} The CampusCheckboxContainer component.
 */
function CampusCheckboxContainer({ campuses, state }) {
  return (
    <div
      className="checkbox location-checkbox"
      data-testid="lg-campus-checkbox-container"
      key={state}
    >
      <label className="lg-location-checkbox state-text" htmlFor={state}>
        {state}
      </label>
      {Object.entries(campuses).map((locations) => {
        if (locations[0] === 'none') {
          return locations[1].map((campus) => (
            <CampusCheckbox
              key={campus.slug}
              name={campus.name}
              slug={campus.slug}
            />
          ));
        }
        return (
          <MetroGroup
            key={locations[0]}
            locations={locations}
            name={locations[0]}
          />
        );
      })}
    </div>
  );
}

export default CampusCheckboxContainer;
