/**
 * @module LocationsContainer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import CampusCheckboxContainer from '../CampusCheckboxContainer';
import LifeChurchOnlineCheckbox from '../LifeChurchOnlineCheckbox';
import FilteredCampusesContainer from '../FilteredCampusesContainer';
import SearchBarInput from '../SearchBarInput';
import ResetButton from '../SearchFilters/components/ResetButton';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import './LocationsContainer.scss';

function LocationsContainer() {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { breakpoints, width } = useWindowSize();
  const { campusList, filteredList, getDataForAnalytics, resetCampuses } =
    useAlgoliaSearch();

  const [toggleDropdown, setToggleDropdown] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [campuses, setCampuses] = React.useState([]);
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const [isIntChecked, setIsIntChecked] = React.useState(true);
  const ref = React.useRef(null);

  const metros = [];
  campusList?.forEach((campus) => {
    const metro = campus.metro.slice(0, -6);
    if (metro !== '' && !metros?.includes(metro)) {
      metros.push(metro);
    }
  });

  const searchCampusList = campusList?.filter((campus) => {
    return campus.slug !== 'all' && campus.slug !== 'int';
  });
  const locationsLength = filteredList?.length;
  const allowedCharacters = /[^a-z_ ]/gi;

  const numLocationsValue = locationsLength > 0 ? `(${locationsLength})` : '';

  /**
   * Handler function for the search event of the SearchBarInput component.
   *
   * @param {Event} event - The search Event object.
   * @param {object} data - Optional object of additional data from manual trigger.
   */
  function handleSearch(event, data) {
    const text = event.target.value.replace(allowedCharacters, '');
    setSearchText(text);

    if (text === '') {
      setFilteredLocations([]);
    } else {
      const firstRegex = `^(${text})`;
      const containsRegex = `${text}`;

      const firstMetros = metros.filter((metro) =>
        metro.match(new RegExp(firstRegex, 'i')),
      );
      const newMetroList = metros.filter((ele) => {
        return !firstMetros?.includes(ele);
      });

      /* istanbul ignore next */
      const firstResults = searchCampusList
        .filter((campus) => campus.name.match(new RegExp(firstRegex, 'i')))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      const newCampusList = searchCampusList.filter((ele) => {
        return !firstResults?.includes(ele);
      });

      const containsMetros = newMetroList.filter((metro) =>
        metro.match(new RegExp(containsRegex, 'i')),
      );

      /* istanbul ignore next */
      const containsResults = newCampusList
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((campus) => campus.name.match(new RegExp(containsRegex, 'i')));
      setFilteredLocations([
        ...firstMetros,
        ...firstResults,
        ...containsMetros,
        ...containsResults,
      ]);
    }

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.searchSubmitted,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.components.locationsContainerSearchInput,
        component_url: null,
        eventType: ANALYTICS.eventTypes.view,
        form_fields: {
          ...dataForAnalytics?.form_fields,
          campuses_search: text,
          campuses: data?.campuses || dataForAnalytics?.form_fields.campuses,
        },
        form_name: ANALYTICS.forms.search,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Handler function for dropdown click.
   */
  function handleDropdownClick() {
    const dataForAnalytics = getDataForAnalytics();
    const locationsContainer = document.getElementById(
      'id_locations_filters_container',
    );
    if (
      locationsContainer &&
      locationsContainer.classList.contains('lg-show-dropdown')
    ) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.locationsContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    } else {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchStarted,
        properties: {
          action: ANALYTICS.actions.expanded,
          component: ANALYTICS.components.locationsContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
          },
          form_name: ANALYTICS.forms.search,
        },
        user,
        userProfileData,
      });
    }
    setToggleDropdown(!toggleDropdown);
  }

  /**
   * Handler function for reset button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleResetClick(event) {
    event.preventDefault();
    resetCampuses({
      callback: /* istanbul ignore next */ (newCampuses) => {
        setSearchText('');
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.components.locationsContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.track,
            label: event?.currentTarget?.textContent,
          },
          user,
          userProfileData,
        });
        handleSearch({ target: { value: '' } }, { campuses: newCampuses });
      },
    });
  }

  /**
   * Convenience effect to sort and set campuses based on filtered locations.
   */
  React.useEffect(() => {
    function sortCampuses() {
      const map = {};
      const locationList =
        filteredLocations?.length === 0 ? campusList : filteredLocations;

      const sortedList = locationList
        .filter((a) => a.state)
        .sort((a, b) => (a.metro > b.metro ? 1 : -1));

      sortedList.forEach((campus) => {
        if (map[campus.state]) {
          if (!map[campus.state][campus.metro]) {
            map[campus.state][campus.metro] = [campus];
          } else {
            map[campus.state][campus.metro].push(campus);
          }
        } else {
          map[campus.state] = { [campus.metro]: [campus] };
        }
      });
      setCampuses(map);
    }
    if (campusList) {
      sortCampuses();
    }
  }, [campusList, filteredLocations]);

  /**
   * Convenience effect to add outside click listener for mouse down events,
   * which is handled to set the toggle dropdown to hide based on the ref target
   * matching the event target and a truthy toggleDropdown value.
   */
  React.useEffect(() => {
    function handleClickOutside(event) {
      /* istanbul ignore next*/
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        toggleDropdown
      ) {
        setToggleDropdown(!toggleDropdown);

        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.components.locationsContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.track,
            label: ANALYTICS.actions.collapsed,
          },
          user,
          userProfileData,
        });
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleDropdown]);

  return (
    <div
      className="lg-checkbox-group-container locations-container m-l-0"
      data-testid="lg-locations-container"
      ref={ref}
    >
      {width <= breakpoints.medium ? (
        <p className="m-b-half text-bold">
          {STRINGS.locationSelector.lifeChurchLocations}
        </p>
      ) : null}
      <div
        className={`lg-checkbox-group locations ${
          toggleDropdown || locationsLength > 0
            ? `lg-${
                /* istanbul ignore next */ width <= breakpoints.medium
                  ? 'blue'
                  : 'black'
              }`
            : 'lg-grey'
        }`}
        onClick={handleDropdownClick}
      >
        <div className="menu-text">
          {`${STRINGS.locationSelector.locations} ${numLocationsValue}`.trim()}
        </div>
        <span className={`lg-${toggleDropdown ? 'up' : 'down'}-arrow-zone`}>
          <span className={`lg-${toggleDropdown ? 'up' : 'down'}-arrow`} />
        </span>
      </div>
      <div
        className={`lg-checkbox-container ${
          toggleDropdown ? 'lg-show-dropdown' : 'lg-hide-dropdown'
        }`}
        id="id_locations_filters_container"
      >
        {width > breakpoints.medium ? (
          <div className="lg-desktop-filter-header-wrap">
            <h4 className="lg-checkbox-group-text desktop-topics-text">
              {STRINGS.locationSelector.lifeChurchLocations}
            </h4>
            <ResetButton onClick={handleResetClick} />
          </div>
        ) : null}
        <SearchBarInput handleSearch={handleSearch} searchText={searchText} />
        {filteredLocations.length === 0 ? (
          <>
            {searchText !== '' ? (
              <div className="m-a-1">
                <p className="no-result font-weight-semibold">
                  {STRINGS.locationSelector.zeroResultsFound.count}
                </p>
                <p className="no-result-description">
                  {STRINGS.locationSelector.zeroResultsFound.description}
                </p>
                <p className="no-result-description">
                  {
                    STRINGS.locationSelector.zeroResultsFound
                      .descriptionTryAgain
                  }
                </p>
              </div>
            ) : (
              <div className="campuses-container">
                <LifeChurchOnlineCheckbox
                  isChecked={isIntChecked}
                  key="lco"
                  setIsChecked={setIsIntChecked}
                />
                {Object.keys(campuses).length
                  ? Object.keys(campuses)
                      .sort() // NOSONAR
                      .map((state) => (
                        <CampusCheckboxContainer
                          campuses={campuses[state]}
                          key={state}
                          state={state}
                        />
                      ))
                  : null}
              </div>
            )}
          </>
        ) : (
          <div className="campuses-container">
            <FilteredCampusesContainer locations={filteredLocations} />
          </div>
        )}
      </div>
    </div>
  );
}

export default LocationsContainer;
