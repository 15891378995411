/**
 * @module CountryContainer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import SearchBarInput from '../SearchBarInput';
import CountrySelector from '../CountrySelector';
import ResetButton from '../SearchFilters/components/ResetButton';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { STRINGS } from '../../utils/strings';
import '../LocationsContainer/LocationsContainer.scss';

function CountryContainer({ filterFeature, options, value }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { breakpoints, width } = useWindowSize();
  const {
    churchOnlineLocation,
    filteredList,
    getDataForAnalytics,
    resetChurchOnlineLocation,
    storeCampus,
    storeChurchOnlineLocation,
  } = useAlgoliaSearch();

  const [toggleDropdown, setToggleDropdown] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const ref = React.useRef(null);

  const allowedCharacters = /[^a-z_ ]/gi;

  /**
   * Handler function for the search event.
   *
   * @param {Event} event - The Event object associated with the search.
   */
  function handleSearch(event) {
    let text;
    /* istanbul ignore next */
    if (typeof event === 'string') {
      text = event;
    } else {
      text = event.target.value.replace(allowedCharacters, '');
    }
    setSearchText(text);
    /* istanbul ignore next */
    if (text === '') {
      setFilteredLocations([]);
    } else {
      const firstRegex = `^(${text})`;
      const containsRegex = `${text}`;

      const countries = options.filter((country) => {
        return country.slug !== 'all';
      });

      const firstCountries = countries.filter((country) =>
        country.name.match(new RegExp(firstRegex, 'i')),
      );
      const newCountryList = countries.filter((ele) => {
        return !firstCountries?.includes(ele);
      });

      const containsCountries = newCountryList.filter((country) =>
        country.name.match(new RegExp(containsRegex, 'i')),
      );

      setFilteredLocations([...firstCountries, ...containsCountries]);
    }

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.searchSubmitted,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.components.locationSelector,
        component_url: null,
        eventType: ANALYTICS.eventTypes.view,
        form_fields: {
          ...dataForAnalytics?.form_fields,
          country_search: text,
        },
        form_name: ANALYTICS.forms.search,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Convenience function to find and return the full country name for the specified short slug code.
   *
   * @param {Array} opts - Array of options.
   * @param {string} slug - The slug value of the country.
   *
   * @returns {string} The full country name for the specified slug.
   */
  /* istanbul ignore next */
  function fullCountry(opts, slug) {
    return opts.find((o) => o.slug === slug);
  }

  /**
   * Handler function to set the country value.
   *
   * Note: Handler covered in selector, and thus ignored here.
   *
   * @param {string} slug - The slug code of the country to set.
   */
  /* istanbul ignore next */
  function setCountry(slug) {
    handleSearch('');
    if (!filteredList?.includes('int')) {
      storeCampus('int');
    }
    storeChurchOnlineLocation({ location: fullCountry(options, slug) });
  }

  /**
   * Handler function for the dropdown click event.
   */
  function handleDropdownClick() {
    const dataForAnalytics = getDataForAnalytics();
    const locationsContainer = document.getElementById(
      'id_countries_filter_container',
    );
    if (locationsContainer?.classList.contains('lg-show-dropdown')) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.countriesContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.track,
          label: ANALYTICS.actions.collapsed,
        },
        user,
        userProfileData,
      });
    } else {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchStarted,
        properties: {
          action: ANALYTICS.actions.expanded,
          component: ANALYTICS.components.countriesContainer,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
          },
          form_name: ANALYTICS.forms.search,
        },
        user,
        userProfileData,
      });
    }
    setToggleDropdown(!toggleDropdown);
  }

  /**
   * Handler function for reset button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleResetClick(event) {
    event.preventDefault();
    resetChurchOnlineLocation({
      callback: /* istanbul ignore next */ (newCampuses) => {
        setSearchText('');
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.components.countriesContainer,
            component_url: null,
            eventType: ANALYTICS.eventTypes.track,
            label: event?.currentTarget?.textContent,
          },
          user,
          userProfileData,
        });
        handleSearch({ target: { value: '' } }, { campuses: newCampuses });
      },
    });
  }

  /**
   * Convenience effect to add mousedown listener to the document and handle the event.
   */
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        toggleDropdown
      ) {
        setToggleDropdown(!toggleDropdown);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleDropdown]);

  const reorderedOptions = options
    ?.reduce(
      (acc, option) => {
        if (option.slug === 'all') {
          acc[0].unshift(option);
        } else {
          acc[1].push(option);
        }
        return acc;
      },
      [[], []],
    )
    .flat();

  return (
    <div
      className="lg-checkbox-group-container countries-container"
      data-testid="lg-country-container"
      ref={ref}
    >
      {
        /* istanbul ignore next */ width <= breakpoints.medium ? (
          <p className="m-b-half text-bold">Country</p>
        ) : null
      }
      <div
        className={`lg-checkbox-group countries
         ${
           toggleDropdown || churchOnlineLocation?.slug !== 'all'
             ? `lg-${
                 /* istanbul ignore next */ width <= breakpoints.medium
                   ? 'blue'
                   : 'black'
               }`
             : 'lg-grey'
         }
        `}
        onClick={handleDropdownClick}
      >
        <div className="menu-text">
          {`${
            churchOnlineLocation.name !== 'All Locations'
              ? churchOnlineLocation.name
              : 'All Countries'
          }`}
        </div>
        <span className={`lg-${toggleDropdown ? 'up' : 'down'}-arrow-zone`}>
          <span className={`lg-${toggleDropdown ? 'up' : 'down'}-arrow`} />
        </span>
      </div>
      <div
        className={`lg-checkbox-container ${
          toggleDropdown ? 'lg-show-dropdown' : 'lg-hide-dropdown'
        }`}
        id="id_countries_filter_container"
      >
        {
          /* istanbul ignore next */ width > breakpoints.medium ? (
            <div className="lg-desktop-filter-header-wrap">
              <h4 className="lg-checkbox-group-text desktop-topics-text">
                Country
              </h4>
              <ResetButton onClick={handleResetClick} />
            </div>
          ) : null
        }
        <SearchBarInput handleSearch={handleSearch} searchText={searchText} />
        {filteredLocations.length === 0 ? (
          <>
            {searchText !== '' ? (
              <div className="m-a-1">
                <p className="no-result font-weight-semibold">
                  {STRINGS.locationSelector.zeroResultsFound.count}
                </p>
                <p className="no-result-description">
                  {STRINGS.locationSelector.zeroResultsFound.description}
                </p>
                <p className="no-result-description">
                  {
                    STRINGS.locationSelector.zeroResultsFound
                      .descriptionTryAgain
                  }
                </p>
              </div>
            ) : (
              reorderedOptions.map((location) => (
                <CountrySelector
                  key={location.name}
                  name={location.name}
                  setCountry={setCountry}
                  setToggleDropdown={setToggleDropdown}
                  slug={location.slug}
                  value={value}
                />
              ))
            )}
          </>
        ) : (
          filteredLocations
            .sort()
            .map((location) => (
              <CountrySelector
                key={location.name}
                name={location.name}
                setCountry={setCountry}
                setToggleDropdown={setToggleDropdown}
                slug={location.slug}
                value={value}
              />
            ))
        )}
      </div>
    </div>
  );
}

export default CountryContainer;
