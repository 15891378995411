/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MessageDiscussionIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Message Discussion icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Message Discussion Icon element.
 */
export default function MessageDiscussionIcon({ color = '#000000' }) {
  return (
    <div
      className="icon"
      data-color={color}
      data-testid="message-discussion-icon"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6016 4.5H14.8516V10.5C14.8516 10.9125 14.5141 11.25 14.1016 11.25H5.10156V12C5.10156 12.825 5.77656 13.5 6.60156 13.5H14.1016L17.1016 16.5V6C17.1016 5.175 16.4266 4.5 15.6016 4.5ZM13.3516 8.25V3C13.3516 2.175 12.6766 1.5 11.8516 1.5H3.60156C2.77656 1.5 2.10156 2.175 2.10156 3V12.75L5.10156 9.75H11.8516C12.6766 9.75 13.3516 9.075 13.3516 8.25Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
