/**
 * @module ProfileContext
 */
import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { getUser } from '../api/profile';
import { STATUS_TYPES } from '../utils/constants';
import { logError } from '../utils/errorLogger';

/**
 * @typedef {object} ProfileContext
 * @property {object} userProfileData - Object of user profile data.
 */

export const ProfileContext = React.createContext({
  userProfileData: null,
});
ProfileContext.displayName = 'ProfileContext';

/**
 * React Context Provider for Life.Church Profile service.
 *
 * @param {object} props - The context component props object.
 * @param {React.ReactNode} props.children - The React children around which the context provider is wrapped.
 *
 * @returns {React.ReactElement} The Profile Context Provider.
 */
export function ProfileProvider({ children, ...props }) {
  const { user } = useAuth();
  const [status, setStatus] = React.useState(STATUS_TYPES.idle);

  /**
   * Data object to store user profile data.
   */
  const [userProfileData, setUserProfileData] = React.useState(null);

  // Convenience state for initial data retrieval check.
  const [isInitialDataFetched, setIsInitialDataFetched] = React.useState(false);

  /**
   * Convenience function to retrieve and return authentication access token.
   */
  const getAccessToken = React.useCallback(() => {
    const storedAccessToken =
      Cookies.get('access_token') ??
      window.localStorage.getItem('access_token');
    return storedAccessToken;
  }, []);

  /**
   * Convenience function to call the Life.Church Profile API to retrieve user data.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   * @param {Function} [params.forceFetch] - Optional boolean flag to ensure data is fetched. If false, and if profile data has been retrieved, the stored data is returned.
   */
  const fetchProfileData = React.useCallback(
    async ({ callback, forceFetch } = {}) => {
      if (!userProfileData || forceFetch) {
        const accessToken = getAccessToken();
        setStatus(STATUS_TYPES.pending);

        try {
          const userProfileResponse = await getUser({
            accessToken,
          });
          /**
           * Note: Profiles API unfortunately returns string-based boolean that
           * may or may not be title-case capitalized. As such, adding in the
           * extra checks for toString() and toLowerCase() to ensure accuracy.
           */
          setUserProfileData(userProfileResponse || null);
          setStatus(STATUS_TYPES.idle);
          if (callback) {
            callback({
              userProfileData: userProfileResponse,
            });
          }
        } catch (error) {
          setStatus(STATUS_TYPES.idle);
          logError(error);

          if (callback) {
            callback({ error });
          }
        }
      } else if (callback && typeof callback === 'function') {
        callback({ userProfileData });
      }
    },
    [getAccessToken, userProfileData],
  );

  /**
   * Single-run convenience effect to fetch data and set up.
   */
  React.useEffect(() => {
    if (!isInitialDataFetched && user) {
      fetchProfileData({
        callback: () => {
          setIsInitialDataFetched(true);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialDataFetched, user]);

  const value = React.useMemo(
    () => ({
      status,
      userProfileData,
    }),
    [status, userProfileData],
  );

  return (
    <ProfileContext.Provider value={value} {...props}>
      {children}
    </ProfileContext.Provider>
  );
}

ProfileProvider.propTypes = {
  /**
   * The React children.
   */
  children: PropTypes.node,
};
