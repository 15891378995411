/* eslint-disable no-shadow */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import CampusCheckbox from '../CampusCheckbox';
import MetroCheckbox from '../MetroCheckbox';
import './MetroGroup.scss';

/**
 * Represents a checkbox element group for a metro area group.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.locations - Array of locations.
 * @param {string} props.name - The name attribute to apply to the HTML form checkbox element.
 *
 * @returns {React.ReactElement} The MetroGroup component.
 */
function MetroGroup({ locations, name }) {
  const { filteredList, removeCampus, storeCampus } = useAlgoliaSearch();

  const [isMetroChecked, setIsMetroChecked] = React.useState(true);
  const [isPartial, setIsPartial] = React.useState(false);

  const metroSlugs = locations[1].map((campus) => campus.slug);
  const containsAll = metroSlugs.every((slug) => {
    return filteredList?.includes(slug);
  });
  const containsSome = metroSlugs.some((slug) => {
    return filteredList?.includes(slug);
  });

  /**
   * Handler function for a metro group element click event.
   *
   * Notes: The conditional logic in this handler looks for whether or not all
   * checkboxes are checked.
   * - If not, and if only some locations are checked, uncheck everything in the
   * group and remove campuses accordingly. Otherwise, check everything in the
   * group if partially or unchecked.
   * - If so, remove the 'all' campus and set/add the campuses in the metro
   * group, as well as setting boolean flags for all, partial, and metro.
   */
  function handleMetroClick() {
    if (isMetroChecked) {
      setIsMetroChecked(false);
      setIsPartial(false);
      let campusesToRemove = [];
      metroSlugs.forEach((slug) => {
        campusesToRemove.push(slug);
      });
      /* istanbul ignore next */
      if (campusesToRemove.length) {
        removeCampus({ campus: campusesToRemove });
      }
    } else {
      setIsMetroChecked(true);
      setIsPartial(false);
      let campusesToSet = [];
      metroSlugs.forEach((slug) => {
        if (!filteredList?.includes(slug)) {
          campusesToSet.push(slug);
        }
      });
      /* istanbul ignore next */
      if (campusesToSet.length) {
        storeCampus({ campus: campusesToSet });
      }
    }
  }

  /**
   * Convenience effect to set metro and partial check values.
   */
  React.useEffect(() => {
    if (containsAll) {
      setIsMetroChecked(true);
    } else if (containsSome) {
      setIsMetroChecked(false);
      setIsPartial(true);
    } else {
      setIsMetroChecked(false);
      setIsPartial(false);
    }
  }, [containsAll, containsSome]);

  /**
   * Convenience effect to set partial check value.
   */
  React.useEffect(() => {
    if (!filteredList.length) {
      setIsPartial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList]);

  return (
    <div className="metro-group" data-testid="lg-metro-group">
      <MetroCheckbox
        handleClick={handleMetroClick}
        isMetroChecked={isMetroChecked}
        isPartial={isPartial}
        key={name}
        name={locations[0]}
      />
      {locations[1]
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        .map((campus) => (
          <CampusCheckbox
            isMetroChecked={isMetroChecked}
            key={campus.slug}
            name={campus.name}
            setIsMetroChecked={setIsMetroChecked}
            setIsPartial={setIsPartial}
            slug={campus.slug}
          />
        ))}
    </div>
  );
}

export default MetroGroup;
