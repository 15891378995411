/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LifeChurchOnlineWatchPartyIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Life.Church Online Watch Party icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Life.Church Online Watch Party Icon element.
 */
export default function LifeChurchOnlineWatchPartyIcon({ color = '#000000' }) {
  return (
    <div
      className="icon"
      data-color={color}
      data-testid="life-church-online-watch-party-icon"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.58522 1.36348L3.09872 5.47835C2.64151 5.82583 2.36719 6.36534 2.36719 6.94142V15.1712C2.36719 16.177 3.19016 17 4.19602 17H15.169C16.1749 17 16.9978 16.177 16.9978 15.1712V6.94142C16.9978 6.36534 16.7235 5.82583 16.2663 5.47835L10.7798 1.36348C10.1306 0.87884 9.23445 0.87884 8.58522 1.36348ZM5.03125 10.3307C5.03125 7.75473 7.12192 5.66406 9.69792 5.66406C12.2739 5.66406 14.3646 7.75473 14.3646 10.3307C14.3646 12.9067 12.2739 14.9974 9.69792 14.9974C7.12192 14.9974 5.03125 12.9067 5.03125 10.3307ZM9.24992 8.69273C8.94192 8.49206 8.53125 8.71606 8.53125 9.08473V11.5767C8.53125 11.9454 8.94192 12.1694 9.24992 11.9687L11.1866 10.7227C11.4712 10.5407 11.4712 10.1207 11.1866 9.93873L9.24992 8.69273Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
