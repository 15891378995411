/**
 * @module GroupDetailHeader
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Chip from '../Chip';
import DefaultAvatar from '../../assets/avatar-default.svg';
import { getIcon } from '../ui/icon-helper';
import { TAG_COLORS } from '../../utils/constants';
import { normalize } from '../../utils/helpers';
import './DetailPage.scss';

/**
 * Represents the header portion of the LifeGroup Detail page containing hero image, name, and topic/gender chip tags.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 *
 * @returns {React.ReactElement} The GroupDetailHeader component.
 */
function GroupDetailHeader({ groupData }) {
  /**
   * Convenience function to generate the style object for the cover image.
   *
   * Note: Ignore added because the else if conditional should never actually
   * need the optional chainer, but it's left in place for bulletproofing logic.
   * Technically this function is only ever called from within other logic that
   * checks for the same data to exist.
   */
  function getCoverImageStyle() {
    const styleObject = {};
    /* istanbul ignore next */
    if (groupData.publicCoverImageUrl) {
      styleObject.backgroundImage = `url(${groupData?.publicCoverImageUrl})`;
    } else if (groupData.groupLeaders?.[0]?.publicAvatarUrl) {
      styleObject.backgroundSize = '100%';
    }
    return styleObject;
  }

  return (
    <div
      className="lg-detail-header container"
      data-testid="lg-detail-group-detail-header"
    >
      {groupData ? (
        <>
          {groupData.publicCoverImageUrl ||
          groupData.groupLeaders?.[0]?.publicAvatarUrl ? (
            <div className="lg-cover-image" style={getCoverImageStyle()}>
              {
                /* istanbul ignore next */ !groupData.publicCoverImageUrl ? (
                  <>
                    <div
                      className="lg-leader-avatar-bg"
                      style={{
                        backgroundImage: groupData?.groupLeaders?.[0]
                          ?.publicAvatarUrl
                          ? `url(${groupData.groupLeaders[0].publicAvatarUrl})`
                          : '',
                      }}
                    ></div>
                    <div
                      className="lg-leader-avatar-color-overlay"
                      style={{
                        backgroundColor: TAG_COLORS[
                          normalize(groupData?.facets?.genders?.[0])
                        ]
                          ? TAG_COLORS[
                              normalize(groupData?.facets?.genders?.[0])
                            ].overlay
                          : TAG_COLORS.default.overlay,
                      }}
                    ></div>
                    <div className="lg-leader-avatar-grid">
                      {groupData.groupLeaders.map((leaderData, index) => {
                        /* Max show of 2 leaders, per Product Design decision. */
                        if (index < 2) {
                          return (
                            <img
                              alt={`${leaderData?.firstName}-${uuidv4()}`}
                              className="lg-group-detail-header-leader-avatar"
                              key={`${leaderData?.firstName}-${uuidv4()}`}
                              src={leaderData?.publicAvatarUrl || DefaultAvatar}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </>
                ) : null
              }
            </div>
          ) : null}
          {groupData.name ? (
            <h1
              className={`${
                !groupData.publicCoverImageUrl &&
                !groupData.groupLeaders?.[0]?.publicAvatarUrl
                  ? 'm-t-2'
                  : ''
              }`}
            >
              {groupData.name}
            </h1>
          ) : null}
          {groupData.facets?.topic || groupData.facets?.genders ? (
            <div className="lg-chips">
              <div className="lg-topics">
                {groupData.facets?.topic?.map((topicData) => (
                  <Chip
                    color={TAG_COLORS.topics}
                    key={normalize(
                      typeof topicData === 'string'
                        ? topicData
                        : topicData.name,
                    )}
                    icon={getIcon(
                      normalize(
                        typeof topicData === 'string'
                          ? topicData
                          : topicData.name,
                      ),
                      TAG_COLORS.topics.content,
                    )}
                    label={
                      typeof topicData === 'string' ? topicData : topicData.name
                    }
                  />
                ))}
                {groupData.facets?.genders?.map((gender) => (
                  <Chip
                    color={TAG_COLORS[normalize(gender)]}
                    key={gender}
                    label={gender}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default GroupDetailHeader;
