/**
 * @module CategoryStep
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { CategoryCard } from './CategoryCard';
import { LocationSelector } from './LocationSelector';
import { CATEGORIES_DATA } from './variables';
import './CategoryBasedSearch.scss';

/**
 * Represents a view of a specific step in the category-based search flow.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.campusList - Array of campus data objects.
 * @param {Array} props.filteredList - Array of filtered campus data objects.
 * @param {'inPerson'|'online'} props.groupTypeLocation - The group type location ('inPerson' or 'online').
 * @param {Function} props.onClick - Handler function for the step's item click.
 * @param {number} props.stepNumber - The step number of the component to display.
 *
 * @returns {React.ReactElement} The CategoryStep component.
 */
function CategoryStep({
  campusList,
  filteredList,
  groupTypeLocation,
  onClick,
  stepNumber,
}) {
  /**
   * Handler function for card click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleClick(event) {
    const categoryNumber = event.currentTarget.getAttribute(
      'data-category-number',
    );
    const categoryGroupTypeLocation = event.currentTarget.getAttribute(
      'data-group-type-location',
    );
    let data = null;
    if (stepNumber === 0) {
      data = CATEGORIES_DATA[categoryGroupTypeLocation];
    } else if (stepNumber === 1) {
      data = CATEGORIES_DATA[groupTypeLocation].categories[categoryNumber];
    }
    if (onClick && typeof onClick === 'function') {
      onClick({ data, event });
    }
  }

  if (stepNumber === 1) {
    return (
      <>
        <h2>
          I'm looking for an{' '}
          <span className="group-type">
            {CATEGORIES_DATA[groupTypeLocation].inlineTerm}
          </span>{' '}
          LifeGroup for...
        </h2>
        <div className="category-cards-container">
          <CategoryCard
            data={CATEGORIES_DATA[groupTypeLocation].categories[0]}
            data-category-number="0"
            onClick={handleClick}
          />
          <CategoryCard
            data={CATEGORIES_DATA[groupTypeLocation].categories[1]}
            data-category-number="1"
            onClick={handleClick}
          />
          <CategoryCard
            data={CATEGORIES_DATA[groupTypeLocation].categories[2]}
            data-category-number="2"
            onClick={handleClick}
          />
          <CategoryCard
            data={CATEGORIES_DATA[groupTypeLocation].categories[3]}
            data-category-number="3"
            onClick={handleClick}
          />
        </div>
      </>
    );
  }

  if (stepNumber === 2) {
    return (
      <>
        <h2>Select your Life.Church location to view LifeGroups nearby.</h2>
        <p>Select any Life.Church locations near you.</p>
        <p>Don't live near a Life.Church? Try Life.Church Online.</p>
        <LocationSelector
          campusList={campusList}
          filteredList={filteredList}
          id="lg-category-based-search-campus-code"
        />
      </>
    );
  }

  /**
   * Default to returning first-step markup.
   */
  return (
    <>
      <h2>I'm looking for a LifeGroup that meets...</h2>
      <div className="category-cards-container">
        <CategoryCard
          data={CATEGORIES_DATA.Online.card}
          data-group-type-location="Online"
          onClick={handleClick}
        />
        <CategoryCard
          data={CATEGORIES_DATA['In-Person'].card}
          data-group-type-location="In-Person"
          onClick={handleClick}
        />
      </div>
    </>
  );
}

export { CategoryStep };
