/**
 * @module ContactForm
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import BaseModal from '@lifechurch/web-tools-io/dist/components/global/Modals/BaseModal';
import ModalHeader from '@lifechurch/web-tools-io/dist/components/global/Modals/ModalHeader';
import { camelToSnake } from '@lifechurch/web-tools-io/dist/utils/helpers/strings';
import { transformKeys } from '@lifechurch/web-tools-io/dist/utils/helpers/transformKeys';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
import parsePhoneNumber from 'libphonenumber-js';
import ConfirmationAnimation from '../../assets/circular-check-confirmation-animation.gif';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { SNAKE_CASE_EXCEPTIONS } from '../../utils/constants';
import { logError } from '../../utils/errorLogger';
import { STRINGS } from '../../utils/strings';
import Button from '../ButtonItem/ButtonItem';
import BackButton from './BackButton';
import './DetailPage.scss';

const CONTACT_METHOD_OPTIONS = [
  {
    label: 'Text',
    value: 'Text',
  },
  {
    label: 'Call',
    value: 'Phone',
  },
  {
    label: 'Email',
    value: 'Email',
  },
];

/**
 * Represents a section of a LifeGroup detail page including the description information about the group.
 *
 * Note: This file has a majority of functionality taken from the Multi-step Serving Form from LC Site V3.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 *
 * @returns {React.ReactElement} - The AboutGroup component.
 */
function ContactForm({ groupData, isOpen, onCloseClick, ...passThroughProps }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics } = useAlgoliaSearch();
  const [lgAnalyticsData, setLgAnalyticsData] = React.useState({});
  const [step, setStep] = React.useState(
    /* istanbul ignore next */ passThroughProps?.step ?? 0,
  );
  const [formError, setFormError] = React.useState();
  const [formErrors, setFormErrors] = React.useState({});
  const [formData, setFormData] = React.useState({
    contact_method: CONTACT_METHOD_OPTIONS[0].value,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [
    isListenersAddedAndProfileChecked,
    setIsListenersAddedAndProfileChecked,
  ] = React.useState(false);
  const [isPhoneNumberChecked, setIsPhoneNumberChecked] = React.useState(false);
  const [phoneInputDefaultCountry, setPhoneInputDefaultCountry] =
    React.useState('US');

  /**
   * Convenience function to check validity of form input values.
   *
   * Note: This is largely the same with validation that is used in the
   * Multi-step Serving Form in LC Site V3. Also, since this form has been kept
   * to a single component rather than step-by-step individual components, it
   * also includes conditional checks for the step number to validate respective
   * parts of the form accordingly.
   *
   * @param {object} values - Object of input field values against which to validate.
   *
   * @returns {boolean} Boolean flag denoting whether or not the supplied values are all valid.
   */
  function isValid(values) {
    const emailRegEx = /^[\w.+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegEx = {
      intl: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/, // NOSONAR
      original: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    };
    const errors = {};

    if (step === 0) {
      if (!values.first_name) {
        errors.first_name =
          STRINGS.groupDetail.contactForm.errors.completeRequiredField;
      }
      if (!values.last_name) {
        errors.last_name =
          STRINGS.groupDetail.contactForm.errors.completeRequiredField;
      }
    } else {
      if (
        !values.email ||
        !emailRegEx.test(values.email) ||
        !values.email.includes('@')
      ) {
        errors.email = STRINGS.groupDetail.contactForm.errors.invalidEmail;
      }
      /**
       * Note: Ignore directive appropriate since regEx test should catch anything
       * less than or equal to 9 digits, and second conditional is failsafe and
       * otherwise would show as uncovered. Test coverages for too long, short,
       * and blank phone already exists, so this is 100% covered.
       */
      /* istanbul ignore next */
      if (!values.phone_number || !phoneRegEx.intl.test(values.phone_number)) {
        errors.phone_number =
          STRINGS.groupDetail.contactForm.errors.phoneNumber.generic;
      } else if (values.phone_number.length <= 9) {
        errors.phone_number =
          STRINGS.groupDetail.contactForm.errors.phoneNumber.tooShort;
      } else if (values.phone_number.length >= 20) {
        errors.phone_number =
          STRINGS.groupDetail.contactForm.errors.phoneNumber.tooLong;
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  /**
   * Handler function for next button click.
   *
   * @param {Event} event - The Event object associated with the submit event.
   */
  async function handleSubmit(event) {
    event.preventDefault();
    if (!isValid(formData)) {
      return null;
    }

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupDetailPageContactFormModal,
        component_url: null,
        label: event.currentTarget.textContent,
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });

    const rockFormData = {
      PersonAliasId: user?.['https://www.life.church/rock_person_alias_id'],
      FirstName: formData.first_name,
      LastName: formData.last_name,
      Email: formData.email,
      MobileNumber: formData.phone_number,
      PreferredContactMethod: formData.contact_method,
    };

    setIsSubmitting(true);
    const pathAsArray = window.location.pathname.split('/');

    /**
     * Get the location of 'lifegroup' in the pathname and make a new path that
     * includes up to it but not the GUID thereafter. Use that as the base
     * pathname to which to POST, which is set up in server.js to use.
     */
    const lgSlot = pathAsArray.indexOf('lifegroup');
    const newPath = pathAsArray.slice(0, lgSlot + 1).join('/');
    const postUrl = `${window.location.origin}${newPath}/group-interest/`;

    try {
      const formPostResponse = await axios.post(postUrl, {
        groupGuid: groupData.objectID,
        userData: rockFormData,
      });
      // Eventually, API will return errors. For now, generic error message.
      if (!formPostResponse || formPostResponse?.errors) {
        setIsSubmitting(false);
        const errorMessage = `<p>${STRINGS.groupDetail.contactForm.errors.submitErrorGeneric}</p>`;
        setFormError({ message: errorMessage });
      } else {
        setIsSubmitting(false);
        setStep(2);
      }
    } catch (error) /* istanbul ignore next */ {
      setIsSubmitting(false);
      logError(error, { bugsnag: false });
      const errorMessage = `<p>${STRINGS.groupDetail.contactForm.errors.submitErrorGeneric}</p>`;
      setFormError({ message: errorMessage });
    }
  }

  /**
   * Handler function for the close icon click event,
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleCloseClick(event) {
    event.preventDefault();
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupDetailPageContactFormModal,
        component_url: null,
        label: 'Close',
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });
    if (onCloseClick && typeof onCloseClick === 'function') {
      onCloseClick(event);
    }
  }

  /**
   * Handler function for input change event.
   *
   * @param {Event} event - The change Event object.
   */
  function handleChange(event) {
    const { name, value } = event.target;
    const inputFieldWrap = event.target;

    /**
     * Note: Important to only re-validate when the field contains an error
     * already, not when it doesn't and would "get an error". This is presently
     * intentional to avoid layout thrash and annoying error/not-an-error/error
     * back-and-forth.
     */
    if (inputFieldWrap.classList.contains('input-error')) {
      isValid({
        ...formData,
        [name]: value,
      });
    }

    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }

  /**
   * Handler function for phone input change event.
   *
   * Note: This is intentionally a separate handler than other input fields.
   * There is only a value passed in from the component is in E.164 format.
   * For example, a "United States" number entered as (213) 373-4253 will be
   * passed in with value of "+12133734253".
   *
   * @param {string} value - The parsed phone number.
   *
   * Source: https://www.npmjs.com/package/react-phone-number-input.
   */
  function handlePhoneInputChange(value) {
    setFormData((prevData) => {
      return {
        ...prevData,
        phone_number: value,
      };
    });
  }

  /**
   * Handler function for back button click.
   */
  function handleBack() {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupDetailPageContactFormModal,
        component_url: null,
        label: STRINGS.global.back,
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });
    setStep(step - 1);
  }

  /**
   * Handler function for next button click.
   */
  function handleNext() {
    if (isValid(formData)) {
      const dataForAnalytics = getDataForAnalytics();
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.components.lifeGroupDetailPageContactFormModal,
          component_url: null,
          label: STRINGS.global.next,
          lifegroup: lgAnalyticsData,
        },
        user,
        userProfileData,
      });
      setStep(step + 1);
    }
  }

  /**
   * Convenience function to add keyboard listeners.
   */
  function addKeyboardListeners() {
    const inputs = document.querySelectorAll('input');
    const formButton = document.querySelector('button#id_btn_next');
    [...inputs].forEach((input, inputIndex) => {
      input.addEventListener('keypress', (event) => {
        if (event.key.toLowerCase() === 'enter' || event.keyCode === 13) {
          formButton.click();
        }
      });
      if (inputIndex === 0) {
        input.focus();
      }
    });
  }

  /**
   * Single-run convenience effect to attempt to set initial form data with
   * profile data, but not including phone number, which is set in other
   * useEffect() specific for phone number logic.
   */
  React.useEffect(() => {
    function updateDefaultData() {
      try {
        setFormData((prevData) => {
          return {
            ...prevData,
            first_name: userProfileData?.first_name,
            last_name: userProfileData?.last_name,
            email: userProfileData?.email,
          };
        });
      } catch (error) {
        /* istanbul ignore next */
        logError(error, { bugsnag: false });
      }
    }

    if (!isListenersAddedAndProfileChecked) {
      updateDefaultData();
      setIsListenersAddedAndProfileChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListenersAddedAndProfileChecked, userProfileData]);

  /**
   * Convenience function to set phone number data from user profile.
   */
  React.useEffect(() => {
    if (!isPhoneNumberChecked) {
      if (userProfileData?.family?.address?.country) {
        setPhoneInputDefaultCountry(userProfileData.family.address.country);
      }
      let intlPhoneNumber = '';
      if (Array.isArray(userProfileData?.phone_numbers)) {
        const userPhoneNumber = userProfileData.phone_numbers[0]?.number;
        intlPhoneNumber = parsePhoneNumber(
          userPhoneNumber,
          userProfileData?.family?.address?.country || 'US',
        );
      }
      setIsPhoneNumberChecked(true);
      setFormData((prevData) => {
        return {
          ...prevData,
          phone_number: Array.isArray(userProfileData?.phone_numbers)
            ? intlPhoneNumber.formatInternational()?.replace(/ /g, '')
            : '',
        };
      });
    }
  }, [isPhoneNumberChecked, userProfileData]);

  /**
   * Convenience effect to set analytics data via transformKeys to convert all
   * camelCase property keys to snake_case for analytics needs.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (groupData) {
      setLgAnalyticsData(
        transformKeys(groupData, camelToSnake, SNAKE_CASE_EXCEPTIONS),
      );
    }
  }, [groupData]);

  /**
   * Convenience effect to add keyboard listeners to the input fields and
   * form button, as well as auto-focus on the first input, when the step value
   * changes. Note that the setTimeout is in place to allow the modal animation
   * to complete (for the first step) and initial rendering to take place.
   */
  React.useEffect(() => {
    setTimeout(() => {
      addKeyboardListeners();
    }, 300);
  }, [step]);

  return (
    <div
      className="lg-group-contact-form-wrapper"
      data-testid="lg-group-detail-contact-form"
    >
      {isListenersAddedAndProfileChecked ? (
        <BaseModal
          content={
            <div
              className="lg-group-contact-form-content"
              data-testid="lg-group-contact-form-modal"
            >
              {step < 2 ? (
                <div className="lg-group-contact-form-header">
                  <h2>{STRINGS.groupDetail.contactForm.title}</h2>
                  {groupData?.name ? <h3>{groupData.name}</h3> : null}
                </div>
              ) : null}
              {formError ? (
                <div
                  className="error api-errors"
                  dangerouslySetInnerHTML={{
                    __html: `${formError.message}`,
                  }}
                />
              ) : null}
              {step === 0 ? (
                <div className="form-control-wrap">
                  <div className="form-control">
                    <label htmlFor="first_name">
                      {STRINGS.groupDetail.contactForm.labels.firstName}*
                    </label>
                    <input
                      className={`input-field ${
                        formErrors.first_name ? 'input-error' : ''
                      }`}
                      defaultValue={formData.first_name}
                      id="first_name"
                      name="first_name"
                      onChange={handleChange}
                      type="text"
                    />
                    {formErrors.first_name ? (
                      <p className="error">{formErrors.first_name}</p>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label htmlFor="last_name">
                      {STRINGS.groupDetail.contactForm.labels.lastName}*
                    </label>
                    <input
                      className={`input-field ${
                        formErrors.last_name ? 'input-error' : ''
                      }`}
                      defaultValue={formData.last_name}
                      id="last_name"
                      name="last_name"
                      onChange={handleChange}
                      type="text"
                    />
                    {formErrors.last_name ? (
                      <p className="error">{formErrors.last_name}</p>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {step === 1 ? (
                <div className="form-control-wrap">
                  <div className="form-control">
                    <label htmlFor="email">
                      {STRINGS.groupDetail.contactForm.labels.email}*
                    </label>
                    <input
                      className={`input-field ${
                        formErrors.email ? 'input-error' : ''
                      }`}
                      defaultValue={formData.email}
                      id="email"
                      name="email"
                      onChange={handleChange}
                      type="text"
                    />
                    {formErrors.email ? (
                      <p className="error">{formErrors.email}</p>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label htmlFor="phone_number">
                      {STRINGS.groupDetail.contactForm.labels.phone}*
                    </label>
                    <PhoneInput
                      className={`input-field ${
                        formErrors.phone_number ? 'input-error' : ''
                      }`}
                      defaultCountry={phoneInputDefaultCountry}
                      id="phone_number"
                      initialValueFormat="national"
                      onChange={handlePhoneInputChange}
                      value={formData.phone_number}
                    />
                    {formErrors.phone_number ? (
                      <p className="error">{formErrors.phone_number}</p>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label>
                      {
                        STRINGS.groupDetail.contactForm.labels
                          .preferredContactMethod
                      }
                    </label>
                    <div className="radio-wrap">
                      {CONTACT_METHOD_OPTIONS.map((option) => {
                        return (
                          <label className="radio" key={option.value}>
                            <input
                              className="radio"
                              data-value={option.value}
                              defaultChecked={
                                formData.contact_method === option.value
                              }
                              key={option.value}
                              name="contact_method"
                              type="radio"
                            />{' '}
                            {option.label}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {step === 2 ? (
                <div className="lg-group-contact-form-confirmation-wrapper text-center">
                  <div className="confirmation-animation-wrapper">
                    <img
                      alt="Confirmation check mark animation"
                      src={ConfirmationAnimation}
                    />
                  </div>
                  <h2>{STRINGS.groupDetail.contactForm.confirmation.title}</h2>
                  <p className="bold">
                    {
                      STRINGS.groupDetail.contactForm.confirmation
                        .messageCongrats
                    }
                  </p>
                  <p>
                    {
                      STRINGS.groupDetail.contactForm.confirmation
                        .messageNextStep
                    }
                  </p>
                </div>
              ) : null}
            </div>
          }
          contentClassName="p-t-none"
          footer={
            <div className="button-container">
              <div className="group flex align-center">
                {step === 0 ? (
                  <Button
                    buttonSize="large"
                    className="button-primary button-large full-width"
                    id="id_btn_next"
                    onClick={handleNext}
                    style="btn-primary"
                    text={STRINGS.global.next}
                  />
                ) : null}
                {step === 1 ? (
                  <Button
                    buttonSize="large"
                    className="button-primary button-large full-width"
                    id="id_btn_next"
                    onClick={handleSubmit}
                    style="btn-primary"
                    text={
                      isSubmitting ? (
                        <div className="circular loader"></div>
                      ) : (
                        `${STRINGS.global.submit}`
                      )
                    }
                  />
                ) : null}
                {step === 2 ? (
                  <Button
                    buttonSize="large"
                    className="button-primary button-large full-width"
                    onClick={handleCloseClick}
                    style="btn-primary"
                    text={STRINGS.global.done}
                  />
                ) : null}
              </div>
            </div>
          }
          header={
            <ModalHeader
              endButton={
                step === 1 ? (
                  <BackButton className="back-button" onClick={handleBack} />
                ) : null
              }
              onCloseClick={handleCloseClick}
              title="&nbsp;"
            />
          }
          isOpen={isOpen}
          onClose={handleCloseClick}
        />
      ) : null}
    </div>
  );
}

export default ContactForm;
