/**
 * @module useLCProfile
 */
import React from 'react';
import { ProfileContext } from '../../context/ProfileContext';

/**
 * React hook for accessing `ProfileContext`. Must be used inside of a `ProfileProvider` context.
 *
 * @throws {Error} - Throws an error if used outside of a `ProfileProvider` context.
 *
 * @returns {React.Context} - The ProfileContext values.
 *
 * @example
 * import useProfile from '../../hooks/useProfile';
 *
 * function SampleComponent() {
 *   const { userProfileData } = useLCProfile();
 *
 *   return (
 *     <SomeComponent userProfileData={userProfileData} />
 *   )
 * }
 */
function useLCProfile() {
  const context = React.useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useLCProfile must be used within a ProfileProvider');
  }
  return context;
}

export default useLCProfile;
