/**
 * @module Helpers
 */
import Cookies from 'js-cookie';
import {
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_MAP,
  EXPERIMENTS,
  LOCATIONS_METRO_DATA,
  METRO_GROUPS,
} from './constants';

/**
 * Convenience function to normalize the specified string, setting to lower case, removing single quotes, and replacing spaces with hyphens.
 *
 * @param {string} str - The string value to normalize.
 *
 * @returns {string} The normalized string with ' ' replaced with '-', and single quotes removed.
 */
export function normalize(str) {
  return str ? str.toLowerCase().replaceAll(' ', '-').replaceAll("'", '') : '';
}

/**
 * Convenience function to scroll smoothly to the top of the window.
 */
export function scrollToTop() {
  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top: 0,
  });
}

/**
 * Convenience function to generate the age range label for a LifeGroup based on the min and max ages in the data object.
 *
 * @param {object} params - The function params object.
 * @param {number} [params.max] - The max age value.
 * @param {number} [params.min] - The min age value.
 *
 * @returns {string} The age range label. (Example: '18 to 35 Years', '18 Years and Up').
 */
export function generateAgeLabel(
  { defaultMin = 18, includeYearsLabel = true, max, min } = {
    defaultMin: 18,
    includeYearsLabel: true,
    max: null,
    min: 18,
  },
) {
  const suffix = includeYearsLabel ? ' Years' : '';

  // Both max and min.
  if (!!max && !!min) {
    return `${min} to ${max}${suffix}`;
  }
  // No max or min.
  if (!max && !min) {
    return `${defaultMin}${suffix} and Up`;
  }
  // No max.
  if (!max && !!min) {
    return `${min}${suffix} and Up`;
  }
  // Default (also if no min but a max case).
  return `${defaultMin} to ${max}${suffix}`;
}

/**
 * Convenience function to generate and return the label for a group's Frequency. (Example: Monthly on Mon at 8:00pm.)
 *
 * @param {object} params - The function params object.
 * @param {LifeGroupAlgoliaData} params.data - The data object of the Algolia-supplied LifeGroup.
 * @param {boolean} [params.useFullDayOfWeek] - Optional boolean flag denoting whether or not to use full day of week or shortened. (Default: false.)
 *
 * @returns {string} Formatted and human-readable string of the group's meeting frequency.
 */
export function generateFrequencyLabel({ data, useFullDayOfWeek }) {
  if (!data) {
    return '';
  }
  let rtnValue = '';
  const frequency = data.facets?.meetingFrequency;
  const dow = data.facets?.meetingDayOfWeekFull;
  const time = data.meetingTime;
  const timeZone = data.timeZone ? ` ${data.timeZone}` : '';

  if (frequency) {
    rtnValue = `${frequency}`;
  }
  if (dow && dow.toLowerCase() !== 'varies') {
    const dowShort = DAYS_OF_WEEK_MAP[dow.toLowerCase()];
    if (rtnValue !== '') {
      rtnValue = `${rtnValue} on ${useFullDayOfWeek ? dow : dowShort}`;
    } else {
      rtnValue = `${useFullDayOfWeek ? dow : dowShort}`;
    }
  }
  if (time) {
    const adjustedTime = time.charAt(0) === '0' ? time.substring(1) : time;
    if (rtnValue !== '') {
      rtnValue = `${rtnValue} at ${adjustedTime
        .toLowerCase()
        .replaceAll(' ', '')}${timeZone}`;
    } else {
      rtnValue = `${adjustedTime.toLowerCase().replaceAll(' ', '')}${timeZone}`;
    }
  }
  return rtnValue;
}

/**
 * Convenience function to generate the location label for the group based on the specified location data.
 *
 * @param {object} params - The function params object.
 * @param {AlgoliaLifeGroupMeetingLocation} params.locationData - The LifeGroup meeting location data object.
 *
 * @returns {string} The generated location label (Example: 'Oklahoma City, OK, US')
 */
export function generateLocationLabel({ locationData }) {
  if (!locationData || Object.values(locationData).length <= 0) {
    return '';
  }
  let returnVal = '';
  if (locationData.city) {
    returnVal = locationData.city;
  }
  if (locationData.state) {
    returnVal =
      returnVal === ''
        ? locationData.state
        : `${returnVal}, ${locationData.state}`;
  }
  if (locationData.country) {
    returnVal =
      returnVal === ''
        ? locationData.country
        : `${returnVal}, ${locationData.country}`;
  }
  return returnVal;
}

/**
 * Convenience function to generate the location type label for the group based on the specified location type.
 *
 * @param {object} params - The function params object.
 * @param {AlgoliaLifeGroupMeetingLocation} params.locationData - The LifeGroup meeting location data object.
 *
 * @returns {string} The generated location label (Example: 'Meets at a Home').
 */
export function generateLocationTypeLabel({ locationData }) {
  if (!locationData?.locationType) {
    return '';
  }
  const article = ['a', 'e', 'i', 'o', 'u'].includes(
    locationData.locationType.charAt(0).toLowerCase(),
  )
    ? 'an'
    : 'a';
  return `Meets at ${article} ${locationData.locationType}`;
}

/**
 * Convenience function to retrieve localStorage item, if found. Returns `null` if not found.
 *
 * @param {string} key - The key of the localStorage item.
 * @param {SerializationOptions} serializationOptions - Object of options.
 *
 * @returns {*} - The value of the local storage item.
 */
export function getLocalStorageItem(key, { deserialize = JSON.parse } = {}) {
  const valueInLocalStorage = window.localStorage.getItem(key);
  if (valueInLocalStorage) {
    try {
      return deserialize(valueInLocalStorage);
    } catch (error) {
      return null;
    }
  }
  return null;
}

/**
 * Convenience function to retrieve the stored experiment cookie from the browser storage.
 *
 * @returns {string} Cookie value of the stored experiment (Example: 'category-based-search').
 */
/* istanbul ignore next */
export function getExperimentalCookieValue() {
  const experimentCookie = Cookies.get(EXPERIMENTS.cookieName);
  const filteredExperiment = Object.values(EXPERIMENTS.values).find(
    (e) => e.cookieValue === experimentCookie,
  );
  if (experimentCookie && filteredExperiment) {
    return filteredExperiment.cookieValue;
  }
  return null;
}

/**
 * Convenience function to retrieve three-character day of the week label.
 *
 * @param {string} value - The two-character day of week value used for lookup.
 *
 * @returns {*} - The three-character day of the week label, or the passed-in value if no mapping match found.
 */
export function getDayOfWeekLabel(value) {
  const day = DAYS_OF_WEEK.find((d) => d.value === value);
  return day !== undefined ? day.name : value;
}

/**
 * Convenience function to return metro group from campus code.
 *
 * @param {string} campusCode - The location campus code (e.g. 'edm').
 *
 * @returns {string|null} The corresponding metro group name, or null if none found.
 */
export function getMetroGroupForLocation(campusCode) {
  if (LOCATIONS_METRO_DATA[campusCode]) {
    return METRO_GROUPS[LOCATIONS_METRO_DATA[campusCode]].name;
  }
  return null;
}

/**
 * Convenience function to order the supplied days of the week data object with the order specified.
 *
 * @param {object} daysObject - Data object with day names as key values (Example: From facets.meetingDayOfWeekFull in Algolia - { Friday: 3, Monday: 4, ...}).
 * @param {Array} daysOrder - Array of days in the order in which they should be returned from the supplied daysObject (Default: Properly ordered starting with 'Sunday' and ending with 'Varies').
 *
 * @returns {Array} The ordered array of days.
 */
export function orderDaysOfWeek(
  daysObject,
  daysOrder = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Varies',
  ],
) {
  const daysArray = [];
  daysOrder.forEach((day) => {
    if (daysObject[day]) {
      daysArray.push(day);
    }
  });
  return daysArray;
}

/**
 * Convenience function to set age limits and return the result (Example: 18 - 42).
 *
 * @param {number|string} minAge
 * @param {number|string} maxAge
 *
 * @returns {string} The age range from the specified min and max ages.
 */
export function setAgeLimits(minAge, maxAge) {
  let maxStr;
  let minStr = minAge?.toString();
  if (!minAge || minAge < 18) {
    minStr = '18';
  }
  if (maxAge >= 99 || !maxAge) {
    maxStr = ' & up';
  } else {
    maxStr = ` - ${maxAge}`;
  }
  return `${minStr}${maxStr}`;
}

/**
 * Convenience function to return a mid-length day of the week value.
 *
 * @param {string} value - Short day of the week value (Example: 'Su').
 *
 * @returns {string} The longer version of the day name of the corresponding day, or the value if not found in the array of days (Example: 'Sun').
 */
export const getDayLabel = (value) => {
  const day = DAYS_OF_WEEK.find((o) => o.value === value);
  return day !== undefined ? day.name : value;
};

/**
 * Convenience function to return class name for checkbox group.
 *
 * @param {boolean} value - Boolean value denoting whether or not to show checkbox group. When falsey, hide class value returned.
 *
 * @returns {string} The class name value.
 */
export function toggleCheckboxGroup(value) {
  return value ? 'lg-show-dropdown' : 'lg-hide-dropdown';
}

/**
 * Convenience function to return class name for checkbox group.
 *
 * @param {boolean} value - Boolean value denoting which color class to use. When truthy, 'lg-black' is returned, and when falsey, 'lg-grey' is returned.
 *
 * @returns {string} The class name value.
 */
export function toggleCheckboxGroupColor(value) {
  return value ? 'lg-black' : 'lg-grey';
}

/**
 * Convenience function to return class name for checkbox group arrow.
 *
 * @param {boolean} value - Boolean value denoting checkbox group arrow direction. When truthy, 'up' is returned, and when falsey, 'down' is returned.
 *
 * @returns {string} The class name value.
 */
export function toggleCheckboxGroupArrow(value) {
  return value ? 'up' : 'down';
}
