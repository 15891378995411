/**
 * @module ResetButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Button from '../../ButtonItem/ButtonItem';
import { STRINGS } from '../../../utils/strings';

/**
 * Represents a simple reset button for filter controls.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onClick - Handler function for the component click event.
 *
 * @returns {React.ReactElement} The ResetButton component.
 */
function ResetButton({ onClick = () => {} }) {
  return (
    <div className="lg-filter-reset-btn-container">
      <Button
        buttonSize="small"
        className="filter-reset-btn button-tertiary button-small"
        onClick={onClick}
        style="btn-tertiary"
        text={STRINGS.global.reset}
      />
    </div>
  );
}

export default ResetButton;
