/**
 * @module MetroCheckbox
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './MetroCheckbox.scss';

/**
 * Represents a checkbox element for a metro area.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.handleClick - Handler function for the checkbox component click event.
 * @param {boolean} [props.isMetroChecked] - Boolean flag denoting whether or not the metro area is selected.
 * @param {boolean} [props.isPartial] - Boolean flag denoting whether or not a metro area is partially selected.
 * @param {string} props.name - The name attribute to apply to the HTML form checkbox element.
 *
 * @returns {React.ReactElement} The MetroCheckbox component.
 */
function MetroCheckbox({ handleClick, isMetroChecked, isPartial, name }) {
  let classNameValue = '';

  if (isMetroChecked) {
    classNameValue = 'campus-active';
  } else if (isPartial) {
    classNameValue = 'partial-check';
  }

  return (
    <div
      className="metro-checkbox"
      data-testid="lg-metro-checkbox"
      onClick={handleClick}
    >
      <label className={`lg-location-checkbox`} htmlFor={name}>
        {name}
      </label>
      <span className={`checkbox ${classNameValue}`} />
    </div>
  );
}

export default MetroCheckbox;
