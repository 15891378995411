/**
 * @module Strings
 * @description Contains string value constant values for strings used across the project. This helps avoid hard-coding strings in each component file.
 */
export const STRINGS = {
  banners: {
    startLifeGroup: {
      cta: {
        label: 'Start a LifeGroup',
      },
      title: 'Take your first step to become a LifeGroup leader today.',
    },
  },
  error: {
    body: 'Something went wrong on loading the LifeGroup Search utility. Please try later today or contact Support.',
    ctas: [
      {
        href: 'https://www.life.church/contact/',
        label: 'Contact Support',
        target: '_self',
        variant: 'primary',
      },
      {
        href: `https://www.life.church/`,
        label: 'Back to Life.Church Site',
        target: '_self',
        variant: 'secondary',
      },
    ],
    title: 'Something Went Wrong',
  },
  global: {
    back: 'Back',
    close: 'Close',
    contactUs: 'Contact Us',
    done: 'Done',
    global: 'Global',
    groupLeader: 'Group Leader',
    leader: 'Leader',
    less: 'Less',
    lifeChurchOnline: 'Life.Church Online',
    more: 'More',
    moreInfo: 'More Info',
    next: 'Next',
    reset: 'Reset',
    scrollToTop: 'Scroll to Top',
    skip: 'Skip',
    submit: 'Submit',
    view: 'View',
  },
  groupDetail: {
    backToSearch: 'Back to Search',
    connectionHelpTitle: 'Want help getting connected?',
    contactForm: {
      confirmation: {
        messageCongrats:
          'Congratulations on taking this step toward finding community.',
        messageNextStep:
          'The LifeGroup leader will contact you personally to help you get connected.',
        title: 'Your interest form was submitted!',
      },
      errors: {
        completeRequiredField: 'Please complete this required field.',
        invalidEmail: 'Please enter a valid email address.',
        phoneNumber: {
          generic: 'Please enter a valid phone number.',
          tooLong: 'Phone Number must be less than 20 digits.',
          tooShort: 'Phone Number must be 10 digits or more.',
        },
        submitErrorGeneric:
          'Oops! There was a problem submitting your form. Please try again.',
      },
      labels: {
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone',
        preferredContactMethod: 'Preferred Contact Method',
      },
      title: 'Contact Us',
    },
    interestContact: {
      title: 'Interested in this LifeGroup?',
    },
    map: {
      locationDisclaimer:
        'Location is approximate. The group leader will share more location details.',
      meetsInPerson: 'Meets In-Person',
      meetsOnline: 'Meets Online',
    },
    meetsUntil: 'Meets until',
    messageLifeGroupPastor: 'Message Your LifeGroups Pastor',
    moreLikeThis: {
      title: 'More Like This',
    },
    notFoundAlert:
      "Sorry, we couldn't find this LifeGroup or Local Mission Partner",
    pageTitle: 'About this LifeGroup',
    shortTermLifeGroup: 'Short-Term LifeGroup',
  },
  localPartners: {
    connectWithPartner:
      'Are you interested in getting connected to a Local Mission Partner? Check out the local organizations our church partners with, and reach out to them to learn more about serving with that partner.',
  },
  locationSelector: {
    lifeChurchLocations: 'Life.Church Locations',
    locations: 'Locations',
    zeroResultsFound: {
      count: '0 Results Found',
      description: "Didn't find what you are looking for?",
      descriptionTryAgain:
        'Try another keyword or see all the campuses near you.',
    },
  },
  maintenance: {
    body: "We're building the church and improving this page for you. We'll be back up as soon as possible, so please check back in a few minutes.",
    cta: {
      href: `https://www.life.church/contact/`,
      label: 'Get Help',
      target: '_blank',
    },
    title: "It's not you, it's us!",
  },
  menuModal: {
    links: {
      lco: {
        description:
          'Check out a Life.Church message—live with our Church Online community.',
        title: 'Attend Online',
      },
    },
  },
  outage: {
    body: "We are currently experiencing some issues with the LifeGroup Search experience. We'll be back up as soon as possible, so please check back in a few minutes.",
    cta: {
      href: `https://www.life.church/contact/`,
      label: 'Get Help',
      target: '_blank',
    },
    title: "It's not you, it's us!",
  },
  searchFilters: {
    labels: {
      age: 'Age',
      children: 'Children',
      country: 'Country',
      genders: 'Genders',
      keywords: 'Keywords',
      location: 'Location',
      locations: 'Locations',
      meetingDays: 'Meeting Days',
      meetingFrequency: 'Meeting Frequency',
      meetingType: 'Meeting Type',
      moreFilters: 'More Filters',
      people: 'People',
      schedule: 'Schedule',
      seasonOfLife: 'Season of Life',
      topic: 'Topic',
      yourAge: 'Your Age',
    },
    placeholders: {
      keywords: 'Search Keywords',
    },
    titles: {
      find: {
        base: 'Find a',
        lifeGroup: 'LifeGroup',
        localPartner: 'Local Mission Partner',
      },
    },
  },
  startLifeGroup: {
    broadenSearch: 'Try broadening your search, or consider',
    cta: 'starting a LifeGroup.',
    title: "Didn't find what you were looking for?",
  },
  switchMessage: {
    cta: 'Learn more about our small groups at Switch!',
    title: 'Are you in high school or middle school?',
  },
};
