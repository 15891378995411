/**
 * @module MoreFilters
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAlgoliaSearch from '../../../hooks/useAlgoliaSearch';
import Chip from '../../Chip';
import CustomCheckBox from '../../CheckBox/CustomCheckBox';
import CheckBox from '../../CheckBox';
import Keywords from './Keywords';
import { getIcon } from '../../ui/icon-helper';
import {
  GROUP_TYPES,
  GROUP_TYPES_MAP,
  TAG_COLORS,
} from '../../../utils/constants';
import {
  normalize,
  orderDaysOfWeek,
  toggleCheckboxGroup,
  toggleCheckboxGroupArrow,
  toggleCheckboxGroupColor,
} from '../../../utils/helpers';
import { STRINGS } from '../../../utils/strings';
import ResetButton from './ResetButton';

/**
 * Represents the more filters container with sections and filter controls for searching.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The MoreFilters component.
 */
function MoreFilters(props) {
  const {
    algoliaFacets,
    days,
    groupTypes,
    keywords,
    meetingFrequency,
    meetingType,
  } = useAlgoliaSearch();
  const {
    classToggle,
    onBlur,
    onClick,
    onDayChecked,
    onGroupTypeChange,
    onKeywordChanged,
    onMeetingFrequencyChanged,
    onMeetingTypeChanged,
    onReset,
  } = props;

  let moreFilterLength = 0;
  moreFilterLength += meetingType?.length ?? 0;
  moreFilterLength += days?.length ?? 0;
  moreFilterLength += meetingFrequency?.length ?? 0;
  if (keywords?.length) {
    moreFilterLength += 1;
  }

  const moreFiltersLengthValue =
    moreFilterLength > 0 ? `(${moreFilterLength})` : '';
  const lgCheckboxGroupMoreFiltersClassName =
    moreFilterLength > 0 ? 'lg-black' : toggleCheckboxGroupColor(classToggle);

  /**
   * Convenience function to iterate over people facets and add interactive Chip components for each.
   *
   * Note: Ignore added to default return since each switch statement needs to
   * have a default, but in our instance, all chips have corresponding facets
   * that are covered and will never result in the default return.
   *
   * @returns {React.ReactElement} Series of Chip components for all people facet keys.
   */
  const renderCheckboxes = (facet) => {
    if (!algoliaFacets?.[`facets.${facet}`]) {
      return null;
    }

    let facetArray = Object.keys(algoliaFacets[`facets.${facet}`]);

    // If Meeting Day of Week Full facet, render with result of ordered days.
    if (facet === 'meetingDayOfWeekFull') {
      facetArray = orderDaysOfWeek(algoliaFacets[`facets.${facet}`]);
    }

    const isChecked = (facetType, value) => {
      switch (facetType) {
        case 'meetingType':
          return meetingType?.includes(value);
        case 'meetingDayOfWeekFull':
          return days?.includes(value);
        case 'meetingFrequency':
          return meetingFrequency?.includes(value);
        /* istanbul ignore next */
        default:
          return false;
      }
    };

    /**
     * Handler function for Chip click.
     *
     * Note: Ignore added to default return since each switch statement needs to
     * have a default, but in our instance, all chips have corresponding facets
     * that are covered and will never result in the default return.
     *
     * @param {object} params - The function params object.
     * @param {Event} params.event - The Event object associated with the click.
     * @param {string} params.label - The Chip label.
     */
    function handleChipClick({ event, label }) {
      switch (facet) {
        case 'meetingType':
          return onMeetingTypeChanged({
            ...event,
            target: {
              ...event.target,
              value: label,
            },
          });
        case 'meetingDayOfWeekFull':
          return onDayChecked(label);
        case 'meetingFrequency':
          return onMeetingFrequencyChanged({
            ...event,
            target: {
              ...event.target,
              value: label,
            },
          });
        /* istanbul ignore next */
        default:
          return;
      }
    }

    /**
     * If Children facet, render checkboxes. Otherwise, Chips.
     */
    if (facet === 'meetingFrequency') {
      return facetArray.map((facetKey) => (
        <CustomCheckBox
          className="meeting-frequency"
          filterFeature="meetingFrequency"
          key={normalize(facetKey)}
          id={normalize(facetKey)}
          isChecked={isChecked(facet, facetKey)}
          labelClassName="lg-checkbox-meeting-frequency"
          name={facetKey}
          onChange={(event) => {
            event.stopPropagation();
            handleChipClick({ event, label: facetKey });
          }}
          value={facetKey}
        />
      ));
    }

    return facetArray.map((facetKey) => (
      <Chip
        color={
          isChecked(facet, facetKey) ? TAG_COLORS.topics : TAG_COLORS.default
        }
        icon={getIcon(
          normalize(facetKey),
          isChecked(facet, facetKey)
            ? TAG_COLORS.topics.content
            : TAG_COLORS.default.content,
        )}
        isBordered={true}
        isInteractive={true}
        key={normalize(facetKey)}
        label={facetKey}
        onClick={(event) => {
          event.stopPropagation();
          handleChipClick({ event, label: facetKey });
        }}
      />
    ));
  };

  /**
   * Convenience method to iterate over group types and return checkbox elements for each.
   *
   * @returns {React.ReactElement} Array of group type checkbox elements.
   */
  const renderGroupTypeCheckboxes = () => {
    const isChecked = (value) => {
      return groupTypes
        ? normalize(groupTypes)
            .toLowerCase()
            .includes(normalize(value).toLowerCase())
        : false;
    };

    return GROUP_TYPES.map((groupType) => (
      <CheckBox
        id={`group-type-checkbox-${normalize(groupType.value.toLowerCase())}`}
        isChecked={isChecked(groupType.value)}
        key={groupType.value}
        labelClassName="lg-group-type-checkbox"
        name={groupType.name}
        onChange={(event) => {
          onGroupTypeChange(event);
        }}
        value={groupType.value}
      />
    ));
  };

  /**
   * Handler function for container click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleContainerClick(event) {
    if (event.target.closest('#id-more-filters-container')) {
      onClick(event);
    }
  }

  /**
   * Handler function for reset button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleResetClick(event) {
    if (onReset && typeof onReset === 'function') {
      onReset(event);
    }
  }

  /**
   * Handler function for outside click awareness.
   *
   * @param {Event} event - The Event object associated with the blur.
   */
  const handleContainerBlur = React.useCallback((event) => {
    if (
      !event.target.closest('#id-more-filters-checkboxes') &&
      !event.target.closest('#id-more-filters-container') &&
      onBlur &&
      typeof onBlur === 'function'
    ) {
      onBlur(event);
    }
  }, []);

  /**
   * Single-run convenience effect to add handler for outside click awareness.
   */
  React.useEffect(() => {
    document.addEventListener('mouseup', handleContainerBlur);
    return () => {
      document.removeEventListener('mouseup', handleContainerBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="lg-checkbox-group-container"
      data-testid="lg-more-filters-checkboxes"
      id="id-more-filters-checkboxes"
      onClick={handleContainerClick}
      tabIndex={-1}
    >
      <div
        className={`lg-checkbox-group more-filters ${lgCheckboxGroupMoreFiltersClassName}`}
        id="id-more-filters-container"
      >
        <div
          className="menu-text"
          data-testid="lg-more-filters-container-menu-text"
        >
          {`${STRINGS.searchFilters.labels.moreFilters} ${moreFiltersLengthValue}`.trim()}
        </div>
        <span
          className={`lg-${toggleCheckboxGroupArrow(classToggle)}-arrow-zone`}
        >
          <span
            className={`lg-${toggleCheckboxGroupArrow(classToggle)}-arrow`}
          />
        </span>
      </div>
      <div
        className={`lg-checkbox-container more-filters ${toggleCheckboxGroup(
          classToggle,
        )}`}
        id="id_more_filters_container"
      >
        <div className="lg-desktop-filter-header-wrap">
          <h4 className="lg-checkbox-group-text desktop-topics-text">
            {STRINGS.searchFilters.labels.moreFilters}
          </h4>
          <ResetButton onClick={handleResetClick} />
        </div>
        <div className="lg-filter-section">
          <div className="group-type-container" tabIndex={-1}>
            <div className="group-type-render">
              {renderGroupTypeCheckboxes()}
            </div>
          </div>
        </div>
        {!GROUP_TYPES_MAP.localPartner.includes(groupTypes) ? (
          <>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.meetingType}</h3>
              <div className="chip-container">
                {renderCheckboxes('meetingType')}
              </div>
            </div>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.meetingDays}</h3>
              <div className="chip-container">
                {renderCheckboxes('meetingDayOfWeekFull')}
              </div>
            </div>
            <div className="lg-filter-section">
              <h3>{STRINGS.searchFilters.labels.meetingFrequency}</h3>
              <div className="checkbox-container">
                {renderCheckboxes('meetingFrequency')}
              </div>
            </div>
          </>
        ) : null}
        <div className="lg-filter-section">
          <h3>{STRINGS.searchFilters.labels.keywords}</h3>
          <Keywords isMobile={false} onKeywordChanged={onKeywordChanged} />
          {GROUP_TYPES_MAP.localPartner.includes(groupTypes) ? (
            <div className="group-type-disclaimer m-t-2">
              <p>{STRINGS.localPartners.connectWithPartner}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MoreFilters;
