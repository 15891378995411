/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module App
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import qs from 'qs';
import { useParams } from 'react-router-dom';
import useAlgoliaSearch from './hooks/useAlgoliaSearch';
import DetailPage from './components/DetailPage';
import SearchPage from './components/SearchPage';
import MaintenanceOutage from './views/MaintenanceOutage';
import Authentication from './views/Authentication';
import { ANALYTICS } from './utils/analytics';
import {
  APP_CONFIG,
  AUTH_ROUTES,
  ENVIRONMENT,
  ERROR_MODES,
  OVERRIDE_ALGOLIA_QUERY_KEY,
} from './utils/constants';
import './styles/main.scss';

/**
 * Convenience component to return a "specialty" component based on route.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isAuthPath] - Optional boolean flag denoting whether or not the route is an Auth path.
 *
 * @returns {React.ReactElement} The SpecialtyRouteComponent.
 */
function SpecialtyRouteComponent({ isAuthPath }) {
  if (isAuthPath) {
    return <Authentication />;
  }
  return null;
}

/**
 * The app's PageLoader component.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.view] - Optional component view to display. (Default: 'searchPage'.)
 *
 * @returns {React.ReactElement} - The PageLoader component.
 */
function PageLoader({ view }) {
  const { configure, isConfigured } = useAuth();
  const { storeOverrideAlgolia } = useAlgoliaSearch();
  const pathParams = useParams();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isAuthPath, setIsAuthPath] = React.useState(null);
  const [groupId, setGroupId] = React.useState(pathParams?.groupId);

  /**
   * Convenience effect to configure Auth if not already done, and set the
   * initialized value accordingly.
   */
  function configureAuth() {
    if (!isConfigured) {
      const authConfig = {
        audience: process.env.AUTHAUDIENCE,
        clientID: process.env.AUTHCLIENTID,
        domain: process.env.AUTHDOMAIN,
        redirectUri: `${window.location.origin}${process.env.AUTH_CALLBACK_URL}`,
      };
      configure({
        callback: () => {
          setIsAuthPath(AUTH_ROUTES.includes(window.location.pathname));
          setIsInitialized(true);
        },
        config: authConfig,
      });
    } else {
      setIsInitialized(true);
    }
  }

  /**
   * Convenience effect to look for and set the group ID value from path params.
   */
  React.useEffect(() => {
    if (pathParams?.groupId) {
      setGroupId(pathParams.groupId);
    }
  }, [pathParams]);

  /**
   * Single-run convenience effect to parse query string params and set override
   * flag in AlgoliaSearchContext and on the window. Additionally, trigger the
   * configuration logic for Auth.
   */
  React.useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (
      ((queryParams &&
        ['1', 'true'].includes(queryParams[OVERRIDE_ALGOLIA_QUERY_KEY])) ||
        window.lcLtOverride) &&
      !ENVIRONMENT.production
    ) {
      storeOverrideAlgolia({
        callback: () => {
          configureAuth();
        },
        value: true,
      });
    } else {
      configureAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (window.isAlgoliaOutage) {
    return <MaintenanceOutage mode={ERROR_MODES.outage} />;
  }

  return (
    <>
      {isInitialized && !isAuthPath ? (
        <>
          {view === APP_CONFIG.appViews.lgDetailPage ? (
            <DetailPage groupId={groupId} />
          ) : (
            <SearchPage />
          )}
        </>
      ) : (
        <SpecialtyRouteComponent isAuthPath={isAuthPath} />
      )}
    </>
  );
}

/**
 * The App view component.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.view] - Optional component view to display. (Default: 'searchPage'.)
 *
 * @returns {React.ReactElement} - The App view component.
 */
const App = ({ view }) => {
  /**
   * Fetch user from local storage to pass along to Segment for analytics.
   */
  let user;
  try {
    user = window?.localStorage?.getItem('user_profile')
      ? JSON.parse(window?.localStorage?.getItem('user_profile'))
      : null;
  } catch (error) {
    Log.error(error);
  }

  /**
   * Handler function for button or link item element click.
   *
   * Note: Since this function is also referenced and invoked by the dynamically
   * added `onclick` attributes for component content, it needs to be exposed
   * to the window via `window.handleAnchorElementClick`.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} options - Data object of optional options for analytics tracking.
   */
  window.handleAnchorElementClick = (event, options = {}) => {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: options?.component || null,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  };

  /**
   * Handler function to add setInterval for checking existence of mapLoader
   * on the window object, and then trigger calling it. This helps the script
   * tag and its onLoad to not possibly falsely assume window.mapLoader() is
   * present, but rather, calls this new window.onMapboxLoaded() function that
   * does its thing to wait for mapLoader() to be there.
   */
  window.onMapboxLoaded = () => {
    const setMapboxLoaded = setInterval(() => {
      if (
        window &&
        window.mapLoader &&
        typeof window.mapLoader === 'function'
      ) {
        window.mapLoader();
        clearInterval(setMapboxLoaded);
      }
    }, 100);
  };

  return (
    <div className="App">
      <PageLoader view={view} />
    </div>
  );
}; // NOSONAR

export default App;
