/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module FinancialCoachingIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Financial Coaching icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Financial Coaching Icon element.
 */
export default function FinancialCoachingIcon({ color = '#000000' }) {
  return (
    <div
      className="icon"
      data-color={color}
      data-testid="financial-coaching-icon"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.70312 1.5C5.56313 1.5 2.20312 4.86 2.20312 9C2.20312 13.14 5.56313 16.5 9.70312 16.5C13.8431 16.5 17.2031 13.14 17.2031 9C17.2031 4.86 13.8431 1.5 9.70312 1.5ZM10.3631 13.32V13.59C10.3631 13.95 10.0706 14.25 9.70312 14.25C9.34312 14.25 9.04313 13.9575 9.04313 13.59V13.275C8.57062 13.1625 7.59563 12.8175 7.02563 11.7C6.85313 11.37 7.01813 10.9575 7.36312 10.815L7.41562 10.7925C7.72312 10.665 8.06812 10.7925 8.22563 11.085C8.46562 11.5425 8.93812 12.1125 9.81562 12.1125C10.5131 12.1125 11.3006 11.7525 11.3006 10.905C11.3006 10.185 10.7756 9.81 9.59063 9.3825C8.76563 9.09 7.07812 8.61 7.07812 6.9C7.07812 6.825 7.08563 5.1 9.04313 4.68V4.41C9.04312 4.0425 9.34312 3.75 9.70312 3.75C10.0631 3.75 10.3631 4.0425 10.3631 4.41V4.6875C11.1656 4.83 11.6756 5.2575 11.9831 5.6625C12.2381 5.9925 12.1031 6.4725 11.7131 6.6375C11.4431 6.75 11.1281 6.66 10.9481 6.4275C10.7381 6.1425 10.3631 5.85 9.74813 5.85C9.22313 5.85 8.39062 6.1275 8.39062 6.8925C8.39062 7.605 9.03563 7.875 10.3706 8.3175C12.1706 8.94 12.6281 9.855 12.6281 10.905C12.6281 12.8775 10.7531 13.2525 10.3631 13.32Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
