/**
 * @module SearchBarInput
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './SearchBarInput.scss';

/**
 * Represents a simple HTML input form field element with change events that dispatch via props for the parent component.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.handleSearch - Handler function for the input onChange event.
 * @param {string} [props.placeholder] - Optional placeholder attribute value to use for the component's input field and its placeholder attribute.
 * @param {string} props.searchText - The string value of the search text set for the component's input field.
 *
 * @returns {React.ReactElement} The SearchBarInput component.
 */
function SearchBarInput({ handleSearch, placeholder = 'Search', searchText }) {
  return (
    <div className="search-bar" data-testid="lg-search-filter">
      <input
        onChange={(event) => handleSearch(event)}
        onKeyDown={(event) => {
          return /[a-z]/i.test(event.key);
        }}
        placeholder={placeholder}
        type="text"
        value={searchText}
      />
    </div>
  );
}

export default SearchBarInput;
