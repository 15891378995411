/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MarriageIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Marriage icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Marriage Icon element.
 */
export default function MarriageIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="marriage-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.20312 15C1.99063 15 1.8125 14.9281 1.66875 14.7844C1.525 14.6406 1.45312 14.4625 1.45312 14.25V12C1.45312 11.575 1.6 11.2188 1.89375 10.9313C2.1875 10.6438 2.54062 10.5 2.95312 10.5H5.40938C5.65938 10.5 5.89688 10.5625 6.12188 10.6875C6.34688 10.8125 6.52813 10.9812 6.66563 11.1937C7.02813 11.6813 7.475 12.0625 8.00625 12.3375C8.5375 12.6125 9.10313 12.75 9.70312 12.75C10.3156 12.75 10.8875 12.6125 11.4188 12.3375C11.95 12.0625 12.3906 11.6813 12.7406 11.1937C12.9031 10.9812 13.0938 10.8125 13.3125 10.6875C13.5312 10.5625 13.7594 10.5 13.9969 10.5H16.4531C16.8781 10.5 17.2344 10.6438 17.5219 10.9313C17.8094 11.2188 17.9531 11.575 17.9531 12V14.25C17.9531 14.4625 17.8813 14.6406 17.7375 14.7844C17.5938 14.9281 17.4156 15 17.2031 15H13.4531C13.2406 15 13.0625 14.9281 12.9188 14.7844C12.775 14.6406 12.7031 14.4625 12.7031 14.25V13.2938C12.2656 13.6063 11.7938 13.8438 11.2875 14.0063C10.7812 14.1688 10.2531 14.25 9.70312 14.25C9.16563 14.25 8.64062 14.1656 8.12813 13.9969C7.61563 13.8281 7.14062 13.5875 6.70312 13.275V14.25C6.70312 14.4625 6.63125 14.6406 6.4875 14.7844C6.34375 14.9281 6.16563 15 5.95312 15H2.20312ZM3.70312 9.75C3.07812 9.75 2.54688 9.53125 2.10938 9.09375C1.67187 8.65625 1.45312 8.125 1.45312 7.5C1.45312 6.8625 1.67187 6.32813 2.10938 5.89688C2.54688 5.46563 3.07812 5.25 3.70312 5.25C4.34063 5.25 4.875 5.46563 5.30625 5.89688C5.7375 6.32813 5.95312 6.8625 5.95312 7.5C5.95312 8.125 5.7375 8.65625 5.30625 9.09375C4.875 9.53125 4.34063 9.75 3.70312 9.75ZM15.7031 9.75C15.0781 9.75 14.5469 9.53125 14.1094 9.09375C13.6719 8.65625 13.4531 8.125 13.4531 7.5C13.4531 6.8625 13.6719 6.32813 14.1094 5.89688C14.5469 5.46563 15.0781 5.25 15.7031 5.25C16.3406 5.25 16.875 5.46563 17.3063 5.89688C17.7375 6.32813 17.9531 6.8625 17.9531 7.5C17.9531 8.125 17.7375 8.65625 17.3063 9.09375C16.875 9.53125 16.3406 9.75 15.7031 9.75Z"
          fill={color}
        />
        <path
          d="M10.1079 6.85373C9.87997 7.04923 9.52911 7.04923 9.3012 6.85089L9.26821 6.82256C7.69382 5.47671 6.66521 4.59554 6.7042 3.4962C6.72219 3.01453 6.98309 2.55269 7.40593 2.28069C8.19762 1.77068 9.17525 2.00869 9.70304 2.59236C10.2308 2.00869 11.2085 1.76785 12.0002 2.28069C12.423 2.55269 12.6839 3.01453 12.7019 3.4962C12.7439 4.59554 11.7123 5.47671 10.1379 6.82823L10.1079 6.85373Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
