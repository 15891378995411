/**
 * @module MobileFilters
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import BaseModal from '@lifechurch/web-tools-io/dist/components/global/Modals/BaseModal';
import ModalHeader from '@lifechurch/web-tools-io/dist/components/global/Modals/ModalHeader';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import { useCollapse } from 'react-collapsed';
import Button from '../ButtonItem/ButtonItem';
import LocationsContainer from '../LocationsContainer';
import CheckBox from '../CheckBox';
import Keywords from './components/Keywords';
import Location from './components/Location';
import People from './components/People';
import Schedule from './components/Schedule';
import Topics from './components/Topics';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import {
  ALGOLIA_CONFIG,
  GROUP_TYPES,
  GROUP_TYPES_MAP,
} from '../../utils/constants';
import { normalize } from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import filterButton from '../../images/sliders-h-white.svg';

/**
 * Represents a sub-component of a collapsible section, based on the react-collapsed package.
 *
 * @param {object} props - The sub-component props object.
 * @param {*} props.children - The markup of the collapsible component contents.
 * @param {Function} props.onToggle - Handler function for collapse toggle event.
 * @param {string} props.title - The title of the collapsible section.
 *
 * @returns {React.ReactElement} The CollapsibleSection sub-component.
 */
function CollapsibleSection({ children, onToggle, title }) {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <div className="lg-filter-section mobile-filter-section">
      <div
        className={`collapse-section-title ${
          isExpanded ? 'expanded' : 'closed'
        }`.trim()}
        onClick={
          /* istanbul ignore next */ () => {
            setIsExpanded(!isExpanded);
            onToggle(!isExpanded);
          }
        }
        {...getToggleProps({
          onClick: () => {
            setIsExpanded((prevExpanded) => !prevExpanded);
            onToggle(!isExpanded);
          },
        })}
      >
        {title}
      </div>
      <section {...getCollapseProps()}>
        <div className="collapse-section-content">{children}</div>
      </section>
    </div>
  );
}

/**
 * Represents the mobile filters container and modal with sections and filter controls for searching.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The MobileFilters component.
 */
function MobileFilters(props) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const {
    age,
    childrenValue: children,
    churchOnlineLocation,
    days,
    filteredList,
    genders,
    getDataForAnalytics,
    groupTypes,
    keywords,
    meetingFrequency,
    resetCampuses,
    resetFilters,
    searchResults,
    seasonOfLife,
    storeAge,
    storeChildren,
    storeDay,
    storeGender,
    storeGroupType,
    storeKeywords,
    storeMeetingFrequency,
    storeMeetingType,
    storeSeasonOfLife,
    storeTopic,
    topics,
    triggerKeywordUpdate,
  } = useAlgoliaSearch();
  const resultsLength = searchResults?.nbHits || 0;
  const [showModal, setShowModal] = React.useState(
    props?.testOverrides?.showModal,
  );

  /**
   * Handler function for age change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onAgeChanged(event) {
    const { value } = event.target;
    storeAge({
      callback: /* istanbul ignore next */ (newAge) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              age_search: newAge,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for children change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onChildrenChanged(event) {
    const { value } = event.target;
    storeChildren({
      callback: /* istanbul ignore next */ (newChildren) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              adults_only: newChildren.includes(
                ALGOLIA_CONFIG.facets['facets.children'].options.adultsOnly,
              ),
              childcare_available: newChildren.includes(
                ALGOLIA_CONFIG.facets['facets.children'].options
                  .childcareAvailable,
              ),
              children_welcome: newChildren.includes(
                ALGOLIA_CONFIG.facets['facets.children'].options
                  .childrenWelcome,
              ),
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for day checked event.
   *
   * @param {string} label - The label value of the facet changed.
   */
  function onDayChecked(label) {
    storeDay({
      callback: /* istanbul ignore next */ (newDays) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              meeting_day_of_week_full: newDays,
            },
            form_name: ANALYTICS.forms.search,
            label,
          },
          user,
          userProfileData,
        });
      },
      value: label,
    });
  }

  /**
   * Handler function for gender change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onGenderChanged(event) {
    const { value } = event.target;
    storeGender({
      callback: /* istanbul ignore next */ (newGenders) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              genders: newGenders,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for group type checked event.
   *
   * @param {Event} event - The function Event object.
   */
  function onGroupTypeChecked(event) {
    storeGroupType({
      callback: /* istanbul ignore next */ (newGroupTypes) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              group_type: newGroupTypes,
            },
            form_name: ANALYTICS.forms.search,
            label: newGroupTypes,
          },
          user,
          userProfileData,
        });
      },
      value: event.target.value,
    });
    resetFilters();
  }

  /**
   * Handler function for keyword change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onKeywordChanged(event) {
    const { value } = event.target;
    storeKeywords({
      callback: /* istanbul ignore next */ (newKeywords) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              keyword_search: newKeywords,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
    if (value.length >= 3 || value.length === 0) {
      triggerKeywordUpdate({ value: value.length });
    }
  }

  /**
   * Handler function for meeting frequency changed event.
   *
   * @param {Event} event - The function Event object.
   */
  function onMeetingFrequencyChanged(event) {
    storeMeetingFrequency({
      callback: /* istanbul ignore next */ (newMeetingFrequency) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              meeting_frequency: newMeetingFrequency,
            },
            form_name: ANALYTICS.forms.search,
            label: event.target.value,
          },
          user,
          userProfileData,
        });
      },
      value: event.target.value,
    });
  }

  /**
   * Handler function for meeting type changed event.
   *
   * @param {Event} event - The function Event object.
   */
  function onMeetingTypeChanged(event) {
    storeMeetingType({
      callback: /* istanbul ignore next */ (newMeetingType) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              group_type: newMeetingType,
            },
            form_name: ANALYTICS.forms.search,
            label: event.target.value,
          },
          user,
          userProfileData,
        });
      },
      value: event.target.value,
    });
  }

  /**
   * Handler function for season of life change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onSeasonOfLifeChanged(event) {
    const { value } = event.target;
    storeSeasonOfLife({
      callback: /* istanbul ignore next */ (newSeasonOfLife) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              season_of_life: newSeasonOfLife,
            },
            form_name: ANALYTICS.forms.search,
            label: value,
          },
          user,
          userProfileData,
        });
      },
      value,
    });
  }

  /**
   * Handler function for Topic filter change event.
   *
   * @param {object} event - The data object with event and data target information.
   */
  function onTopicChanged(data) {
    storeTopic({
      callback: /* istanbul ignore next */ (newTopics) => {
        const dataForAnalytics = getDataForAnalytics();
        triggerSegmentTrack({
          dataForAnalytics,
          event: ANALYTICS.events.searchSubmitted,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.components.searchOptionsModal,
            component_url: null,
            eventType: ANALYTICS.eventTypes.view,
            form_fields: {
              ...dataForAnalytics?.form_fields,
              topics: newTopics,
            },
            form_name: ANALYTICS.forms.search,
            label: data?.value,
          },
          user,
          userProfileData,
        });
      },
      value: data?.value,
    });
  }

  /**
   * Convenience method to iterate over group types and return checkbox elements for each.
   *
   * Note: groupTypes should always be set, so no need to assert its presence.
   *
   * @returns {React.ReactElement} Array of group type checkbox elements.
   */
  const renderGroupTypeCheckboxes = () => {
    const isChecked = (value) => {
      return /* istanbul ignore next */ groupTypes
        ? normalize(groupTypes)
            .toLowerCase()
            .includes(normalize(value).toLowerCase())
        : false;
    };

    return GROUP_TYPES.map((groupType) => (
      <CheckBox
        id={`group-type-checkbox-${normalize(groupType.value.toLowerCase())}`}
        isChecked={isChecked(groupType.value)}
        key={groupType.value}
        labelClassName="lg-group-type-checkbox"
        name={groupType.name}
        onChange={(event) => {
          onGroupTypeChecked(event);
        }}
        value={groupType.value}
      />
    ));
  };

  /**
   * Handler function for the show modal click event.
   *
   * Note: Ignore directive added since every handler invocation should have an
   * associated event, but left in as a failsafe.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function showModalHandler(event) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
    }
    let label = event?.currentTarget?.textContent;
    if (!label) {
      /* istanbul ignore next */
      label = showModal ? ANALYTICS.actions.hidden : ANALYTICS.actions.show;
    }

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.searchOptionsModal,
        component_url: null,
        label,
      },
      user,
      userProfileData,
    });

    // Trigger for search started event as well if being shown.
    if (!showModal) {
      triggerSegmentTrack({
        dataForAnalytics,
        event: ANALYTICS.events.searchStarted,
        properties: {
          action: ANALYTICS.actions.expanded,
          component: ANALYTICS.components.searchOptionsModal,
          component_url: null,
          eventType: ANALYTICS.eventTypes.view,
          form_fields: {
            ...dataForAnalytics?.form_fields,
          },
          form_name: ANALYTICS.forms.search,
          label: 'Reset',
        },
        user,
        userProfileData,
      });
    }

    setShowModal(!showModal);
  }

  /**
   * Convenience function to reset filters.
   */
  function resetSearchFilters() {
    resetFilters({
      callback: /* istanbul ignore next */ () => {
        resetCampuses({
          callback: (newCampuses) => {
            const dataForAnalytics = getDataForAnalytics();
            triggerSegmentTrack({
              dataForAnalytics,
              event: ANALYTICS.events.buttonAction,
              properties: {
                action: ANALYTICS.actions.clicked,
                component: ANALYTICS.components.searchOptionsModal,
                component_url: null,
                label: STRINGS.global.reset,
              },
              user,
              userProfileData,
            });

            triggerKeywordUpdate({ value: 0 });

            // Trigger for search submit event as well.
            triggerSegmentTrack({
              dataForAnalytics,
              event: ANALYTICS.events.searchSubmitted,
              properties: {
                action: ANALYTICS.actions.updated,
                component: ANALYTICS.components.searchOptionsModal,
                component_url: null,
                eventType: ANALYTICS.eventTypes.view,
                form_fields: {
                  ...dataForAnalytics?.form_fields,
                  campuses: newCampuses,
                  keywords: '',
                },
                form_name: ANALYTICS.forms.search,
                label: STRINGS.global.reset,
              },
              user,
              userProfileData,
            });
          },
        });
      },
    });
  }

  /**
   * Handler function for tracking collapsible section toggle events.
   *
   * @param {object} params - The function params object.
   * @param {string} params.action - String value denoting the collapse/expand status of the collapsible, used as the action property for Segment analytics.
   * @param {string} params.label - Title value of the collapsible section, used as the label property for Segment analytics.
   */
  function trackCollapsibleSectionToggle({ action, label }) {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action,
        component: ANALYTICS.components.searchOptionsModal,
        component_url: null,
        label,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Convenience function to count and return the number of applied filters.
   *
   * @returns {number} The filter count number value.
   */
  function filterCount() {
    let count = 0;
    if (filteredList) {
      count += filteredList.length;
    }
    if (topics?.length > 0) {
      count += topics.length;
    }
    count += genders?.length || 0;
    count += seasonOfLife?.length || 0;
    count += children?.length || 0;
    count += days?.length || 0;
    count += meetingFrequency?.length || 0;
    if (age) {
      count += 1;
    }
    if (keywords?.length) {
      count += 1;
    }
    if (churchOnlineLocation?.slug !== 'all') {
      count += 1;
    }
    return count;
  }

  return (
    <div
      className="lg-search-options-mobile-wrapper"
      id="id-lg-search-options-mobile-wrapper"
    >
      <div
        className="lg-search-options-mobile-placeholder"
        id="id-lg-search-options-mobile-placeholder"
      ></div>
      <div
        className="lg-search-options lg-search-options-mobile"
        data-testid="lg-search-options-mobile"
        id="id-lg-search-options-mobile"
      >
        <div className="lg-search-container">
          <div className="lg-filter-header-container">
            <div className="lg-header-container">
              <h2 className="lg-header">
                {STRINGS.searchFilters.titles.find.base}{' '}
                {!GROUP_TYPES_MAP.localPartner.includes(groupTypes)
                  ? STRINGS.searchFilters.titles.find.lifeGroup
                  : STRINGS.searchFilters.titles.find.localPartner}
              </h2>

              <span
                className={`lg-filter-btn ${
                  filterCount() > 0 ? 'active' : ''
                }`.trim()}
                data-testid="lg-filter-btn-mobile"
                onClick={showModalHandler}
              >
                <div className="lg-filter-btn-border">
                  <img
                    alt="Filter button"
                    className="lg-filter-img"
                    src={filterButton}
                  />
                </div>
              </span>
            </div>

            <p className="lg-results-label">{`${resultsLength} Result${
              parseInt(resultsLength, 10) !== 1 ? 's' : ''
            } Found`}</p>
          </div>
        </div>
      </div>

      <BaseModal
        content={
          <div
            className="mobile-filters-modal-content"
            data-testid="mobile-filters-modal"
          >
            <div className="group-type-container" tabIndex={-1}>
              <div className="group-type-render">
                {renderGroupTypeCheckboxes()}
              </div>
            </div>
            {GROUP_TYPES_MAP.localPartner.includes(groupTypes) ? (
              <div className="local-partner-filter-wrapper">
                <div className="lg-filter-section">
                  <LocationsContainer />
                </div>
                <div className="lg-filter-section">
                  <h3>{STRINGS.searchFilters.labels.keywords}</h3>
                  <input
                    onChange={onKeywordChanged}
                    placeholder="Search Keywords"
                    type="text"
                    value={keywords}
                  />
                </div>
                <div className="group-type-disclaimer">
                  <p>{STRINGS.localPartners.connectWithPartner}</p>
                </div>
              </div>
            ) : null}
            {!GROUP_TYPES_MAP.localPartner.includes(groupTypes) ? (
              <div className="lg-filter-sections">
                <CollapsibleSection
                  onToggle={(toggleStatus) => {
                    trackCollapsibleSectionToggle({
                      action: toggleStatus
                        ? ANALYTICS.actions.expanded
                        : ANALYTICS.actions.collapsed,
                      label: STRINGS.searchFilters.labels.location,
                    });
                  }}
                  title={STRINGS.searchFilters.labels.location}
                >
                  <Location onMeetingTypeChanged={onMeetingTypeChanged} />
                </CollapsibleSection>
                <CollapsibleSection
                  onToggle={(toggleStatus) => {
                    trackCollapsibleSectionToggle({
                      action: toggleStatus
                        ? ANALYTICS.actions.expanded
                        : ANALYTICS.actions.collapsed,
                      label: STRINGS.searchFilters.labels.topic,
                    });
                  }}
                  title={STRINGS.searchFilters.labels.topic}
                >
                  <Topics isMobile={true} onChange={onTopicChanged} />
                </CollapsibleSection>
                <CollapsibleSection
                  onToggle={(toggleStatus) => {
                    trackCollapsibleSectionToggle({
                      action: toggleStatus
                        ? ANALYTICS.actions.expanded
                        : ANALYTICS.actions.collapsed,
                      label: STRINGS.searchFilters.labels.people,
                    });
                  }}
                  title={STRINGS.searchFilters.labels.people}
                >
                  <People
                    isMobile={true}
                    onAgeChanged={onAgeChanged}
                    onChildrenChanged={onChildrenChanged}
                    onGenderChanged={onGenderChanged}
                    onSeasonOfLifeChanged={onSeasonOfLifeChanged}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  onToggle={(toggleStatus) => {
                    trackCollapsibleSectionToggle({
                      action: toggleStatus
                        ? ANALYTICS.actions.expanded
                        : ANALYTICS.actions.collapsed,
                      label: STRINGS.searchFilters.labels.schedule,
                    });
                  }}
                  title={STRINGS.searchFilters.labels.schedule}
                >
                  <Schedule
                    isMobile={true}
                    onDayChecked={onDayChecked}
                    onMeetingFrequencyChanged={onMeetingFrequencyChanged}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  onToggle={(toggleStatus) => {
                    trackCollapsibleSectionToggle({
                      action: toggleStatus
                        ? ANALYTICS.actions.expanded
                        : ANALYTICS.actions.collapsed,
                      label: STRINGS.searchFilters.labels.keywords,
                    });
                  }}
                  title={STRINGS.searchFilters.labels.keywords}
                >
                  <Keywords
                    isMobile={true}
                    onKeywordChanged={onKeywordChanged}
                  />
                </CollapsibleSection>
              </div>
            ) : null}
          </div>
        }
        contentClassName="p-t-none"
        footer={
          <div className="button-container">
            <div className="group">
              <Button
                buttonSize="medium"
                className="button-secondary button-medium flex-basis fb-content"
                data-testid="mobile-filters-reset-btn"
                onClick={resetSearchFilters}
                style="btn-secondary"
                text={STRINGS.global.reset}
              />
              <Button
                buttonSize="medium"
                className="button-primary button-medium flex-basis fb-max-content"
                onClick={showModalHandler}
                style="btn-primary"
                text={
                  /* istanbul ignore next */ `${
                    STRINGS.global.done
                  } (${resultsLength} result${resultsLength !== 1 ? 's' : ''})`
                }
              />
            </div>
          </div>
        }
        header={<ModalHeader onCloseClick={showModalHandler} title="Filters" />}
        isOpen={showModal}
        onClose={showModalHandler}
      />
    </div>
  );
}

export default MobileFilters;
