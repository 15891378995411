/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module BookClubIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Book Club icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Book Club Icon element.
 */
export default function BookClubIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="book-club-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8156 1.01471L10.8156 4.01471C10.7406 4.08971 10.7031 4.17971 10.7031 4.28471V10.4122C10.7031 10.7347 11.0856 10.9072 11.3256 10.6897L14.3256 7.98971C14.4081 7.92221 14.4531 7.81721 14.4531 7.71221V1.28471C14.4531 0.947214 14.0481 0.782214 13.8156 1.01471ZM17.0556 3.90221C16.7031 3.72221 16.3356 3.57221 15.9531 3.44471V12.4672C15.0981 12.1597 14.2206 12.0022 13.3281 12.0022C11.9031 12.0022 10.4931 12.4072 9.20312 13.1872V4.11221C7.98812 3.41471 6.58563 3.00221 5.07812 3.00221C3.73563 3.00221 2.46812 3.33221 1.35062 3.90221C1.10312 4.02221 0.953125 4.28471 0.953125 4.56221V13.6222C0.953125 14.1922 1.56063 14.5447 2.06313 14.2747C2.97062 13.8022 3.99062 13.5022 5.07812 13.5022C6.63062 13.5022 8.06313 14.1172 9.20312 15.0022C10.3431 14.1172 11.7756 13.5022 13.3281 13.5022C14.4156 13.5022 15.4356 13.8022 16.3431 14.2822C16.8456 14.5522 17.4531 14.1997 17.4531 13.6297V4.56221C17.4531 4.28471 17.3031 4.02221 17.0556 3.90221Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
