/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module DiscipleshipIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Discipleship icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Discipleship Icon element.
 */
export default function DiscipleshipIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="discipleship-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0418 10.9426C13.4423 11.3069 12.7539 11.4986 12.0524 11.4963C11.4647 11.4917 10.8837 11.3715 10.3424 11.1426C9.92521 11.7315 9.70194 12.4358 9.70364 13.1576V15.0007C9.70379 15.0692 9.68984 15.1371 9.66267 15.2C9.6355 15.2629 9.59568 15.3196 9.54568 15.3665C9.49568 15.4134 9.43658 15.4495 9.37204 15.4726C9.3075 15.4957 9.2389 15.5052 9.17051 15.5007C9.04199 15.4895 8.92246 15.4301 8.83587 15.3345C8.74928 15.2389 8.70205 15.1141 8.70364 14.9851V14.2076L6.28989 11.7938C5.93107 11.9277 5.5516 11.9977 5.16864 12.0007C4.64142 12.0021 4.12407 11.8578 3.67364 11.5838C2.31176 10.7563 1.57864 8.85194 1.72051 6.48756C1.72765 6.36524 1.77947 6.2498 1.86611 6.16316C1.95275 6.07652 2.06819 6.02471 2.19051 6.01756C4.55489 5.87819 6.45926 6.60881 7.28426 7.97069C7.6084 8.50449 7.7499 9.12932 7.68739 9.75069C7.6835 9.79883 7.66575 9.84482 7.63629 9.88309C7.60682 9.92136 7.5669 9.95027 7.52135 9.96634C7.4758 9.9824 7.42657 9.98493 7.37962 9.97361C7.33266 9.9623 7.28999 9.93762 7.25676 9.90256L6.05676 8.64631C5.96223 8.5565 5.83636 8.50718 5.70597 8.50884C5.57559 8.51051 5.45102 8.56305 5.35882 8.65525C5.26662 8.74745 5.21409 8.87202 5.21242 9.0024C5.21075 9.13278 5.26008 9.25866 5.34989 9.35319L8.71739 12.8063C8.72114 12.7576 8.72551 12.7088 8.73051 12.6607C8.83985 11.7337 9.24892 10.8677 9.89551 10.1944L13.0574 6.85319C13.1512 6.75945 13.2039 6.63228 13.204 6.49966C13.2041 6.36704 13.1514 6.23982 13.0577 6.146C12.964 6.05218 12.8368 5.99944 12.7042 5.99938C12.5715 5.99932 12.4443 6.05195 12.3505 6.14569L9.28801 9.38444C9.25736 9.41691 9.21856 9.44058 9.17567 9.45298C9.13277 9.46538 9.08733 9.46607 9.04408 9.45497C9.00083 9.44387 8.96134 9.42139 8.92972 9.38986C8.89809 9.35833 8.87549 9.31891 8.86426 9.27569C8.56801 8.18319 8.69864 7.09569 9.26426 6.16194C10.3805 4.31944 12.978 3.33319 16.213 3.52319C16.3353 3.53033 16.4508 3.58214 16.5374 3.66879C16.6241 3.75543 16.6759 3.87087 16.683 3.99319C16.8705 7.22881 15.8843 9.82631 14.0418 10.9426Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
