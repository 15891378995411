/**
 * @module OverrideData
 */
export const ALGOLIA_OVERRIDES = {
  algoliaFacets: {
    'facets.type': {
      LifeGroups: 100,
    },
    'facets.topic': {
      Parenting: 13,
      Recovery: 13,
      Volunteering: 13,
      'Financial Coaching': 12,
      Hobby: 12,
      Mentoring: 11,
      'Message Discussion': 11,
      Prayer: 11,
      Discipleship: 10,
      Marriage: 10,
      'Mental Health': 10,
      'Bible Study': 9,
      Sisters: 9,
      'Book Club': 8,
      'Life.Church Online Watch Party': 8,
      Friendship: 5,
      'Leadership Coaching': 5,
    },
    'facets.genders': {
      Anyone: 72,
      Men: 66,
      Women: 64,
      Couples: 60,
    },
    'facets.campuses': {
      'Oklahoma City': 51,
      Stillwater: 47,
      'Northwest Oklahoma City': 45,
      Edmond: 42,
      Tulsa: 42,
      'Broadway & Britton': 41,
      'Broken Arrow': 39,
      'Life.Church Online': 4,
    },
    'facets.children': {
      'Childcare Available': 41,
      'Adults Only': 34,
      'Children Welcome': 25,
    },
    'facets.topic.name': {
      'Bible Study': 33,
      Hobby: 32,
      'Financial Coaching': 31,
      Prayer: 30,
      Friendship: 29,
      Marriage: 29,
      'Mental Health': 29,
      Mentoring: 29,
      Sisters: 29,
      'Life.Church Online Watch Party': 27,
      Recovery: 27,
      'Leadership Coaching': 26,
      'Message Discussion': 25,
      Parenting: 24,
      Volunteering: 24,
      'Book Club': 23,
      Discipleship: 21,
    },
    'facets.ageRangeTop': {
      50: 5,
      51: 3,
      52: 5,
      54: 2,
      55: 1,
      56: 2,
      57: 1,
      58: 4,
      59: 2,
      60: 2,
      61: 1,
      62: 2,
      64: 3,
      65: 2,
      66: 5,
      67: 2,
      69: 3,
      70: 2,
      71: 2,
      73: 1,
      74: 3,
      75: 2,
      76: 2,
      77: 2,
      78: 4,
      79: 1,
      80: 4,
      82: 2,
      83: 1,
      84: 1,
      85: 2,
      86: 3,
      87: 1,
      88: 2,
      89: 1,
      90: 3,
      91: 2,
      92: 3,
      93: 3,
      95: 4,
      96: 1,
      97: 1,
      98: 1,
      99: 1,
    },
    'facets.meetingType': {
      Online: 57,
      'In-Person': 43,
    },
    'facets.topic.image': {
      './some/path/to_image.jpg': 75,
    },
    'facets.seasonOfLife': {
      Single: 48,
      'New Believer': 46,
      Professional: 46,
      Married: 45,
      'Single Parent': 45,
      Grandparent: 43,
      Student: 41,
      Parent: 40,
      'Blended Family': 39,
      'Empty Nester': 39,
      Divorced: 38,
      'Stay at Home': 37,
      'Young Adult': 36,
      Widowed: 35,
    },
    'facets.ageRangeBottom': {
      18: 6,
      19: 2,
      20: 4,
      21: 1,
      22: 3,
      23: 1,
      24: 2,
      25: 4,
      26: 4,
      27: 5,
      28: 7,
      29: 3,
      30: 6,
      31: 1,
      32: 6,
      33: 1,
      34: 5,
      36: 4,
      37: 5,
      39: 2,
      40: 3,
      42: 5,
      43: 1,
      44: 3,
      46: 3,
      47: 4,
      48: 5,
      49: 4,
    },
    'groupLeaders.firstName': {
      Wilma: 2,
      Agustina: 1,
      Alysson: 1,
      Amanda: 1,
      America: 1,
      Anne: 1,
      Anthony: 1,
      Asia: 1,
      Aubree: 1,
      Audie: 1,
      Audreanne: 1,
      Brionna: 1,
      Ceasar: 1,
      Celestino: 1,
      Chloe: 1,
      Clair: 1,
      Clifford: 1,
      Clifton: 1,
      Daija: 1,
      Dejuan: 1,
      Delia: 1,
      Deondre: 1,
      Derrick: 1,
      Domingo: 1,
      Duane: 1,
      Eino: 1,
      Elinore: 1,
      Eloise: 1,
      Elva: 1,
      Erna: 1,
      Ernesto: 1,
      Esmeralda: 1,
      Eulalia: 1,
      Felicia: 1,
      Frieda: 1,
      Gabriel: 1,
      Georgianna: 1,
      Gerry: 1,
      Gladys: 1,
      Grant: 1,
      Helga: 1,
      Helmer: 1,
      Herta: 1,
      Imani: 1,
      Isabell: 1,
      Jacques: 1,
      Jadon: 1,
      Jayde: 1,
      Jerrell: 1,
      John: 1,
      Kailey: 1,
      Kailyn: 1,
      Kaleigh: 1,
      Keven: 1,
      Korbin: 1,
      Lamont: 1,
      Lillie: 1,
      Lizzie: 1,
      Lorenz: 1,
      Lucius: 1,
      Luigi: 1,
      Luis: 1,
      Lysanne: 1,
      Maci: 1,
      Maegan: 1,
      Magdalena: 1,
      Magnus: 1,
      Margarete: 1,
      Maxie: 1,
      Micah: 1,
      Minnie: 1,
      Mireya: 1,
      Natasha: 1,
      Newton: 1,
      Nova: 1,
      Percy: 1,
      Ricky: 1,
      Roberto: 1,
      Roderick: 1,
      Rodrick: 1,
      Rosamond: 1,
      Rozella: 1,
      Sheridan: 1,
      Sidney: 1,
      Sincere: 1,
      Stanton: 1,
      Syble: 1,
      Sydni: 1,
      Teresa: 1,
      Therese: 1,
      Thomas: 1,
      Tomasa: 1,
      Tremayne: 1,
      Valerie: 1,
      Vidal: 1,
      Wilburn: 1,
      Wilmer: 1,
      Yasmin: 1,
      Zackery: 1,
    },
    'facets.isShortTermGroup': {
      true: 55,
      false: 45,
    },
    'facets.meetingFrequency': {
      'Bi-Weekly': 34,
      Monthly: 33,
      Weekly: 33,
    },
    'meetingLocation.country': {
      US: 70,
      PS: 2,
      BB: 1,
      BW: 1,
      BZ: 1,
      CA: 1,
      CY: 1,
      FJ: 1,
      GI: 1,
      GQ: 1,
      GY: 1,
      HN: 1,
      IN: 1,
      JO: 1,
      KG: 1,
      LK: 1,
      LS: 1,
      MO: 1,
      NF: 1,
      NP: 1,
      PK: 1,
      RS: 1,
      SB: 1,
      SV: 1,
      SY: 1,
      TK: 1,
      TL: 1,
      UA: 1,
      UY: 1,
      VC: 1,
    },
    'facets.meetingDayOfWeekFull': {
      Thursday: 21,
      Sunday: 17,
      Friday: 14,
      Varies: 14,
      Monday: 12,
      Tuesday: 12,
      Wednesday: 10,
    },
  },
  resultsPagination: {
    isMore: false,
    offset: 0,
    page: 0,
    resultsLength: 20,
    total: 20,
  },
  searchResults: {
    hits: [
      {
        name: 'Decipio texo ait cursim strenuus.',
        description:
          'Quis sint amissio talis aro tollo tego. Tantum spectaculum denique sollicito dens. Tonsor somnus ante consectetur deprimo tricesimus calcar.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '01:49:44-06:00',
        meetingDayOfWeekShort: 'M',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T04:45:07.451Z',
        createdDateTime: 1663102110,
        modifiedDateTime: 1741651474,
        facets: {
          campuses: ['Life.Church Online'],
          type: 'LifeGroups',
          genders: ['Men', 'Women'],
          seasonOfLife: ['Stay at Home'],
          topic: [
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mental Health',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Sisters',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 75,
          ageRangeBottom: 48,
          meetingType: 'Online',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Varies',
          children: 'Adults Only',
          isShortTermGroup: false,
        },
        campus: {
          guid: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
          name: 'Life.Church Online',
          city: null,
          state: null,
          locationDescription: 'null, null',
          shortCode: 'INT',
          street1: '4600 E. 2nd St.',
          street2: null,
          zip: '73034',
        },
        meetingLocation: {
          locationType: 'Restaurant',
          locationTypeColor: '#ed0cdc',
          city: 'Tulsa',
          state: 'TX',
          zip: '71196',
          country: 'BB',
        },
        groupLeaders: [
          {
            firstName: 'Luigi',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.03,
          lng: -96.49,
        },
        objectID: 'fc4a539c-3897-4d01-9220-adb7048d72a2',
        _highlightResult: {
          name: {
            value: 'Decipio texo ait cursim strenuus.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Quis sint amissio talis aro tollo tego. Tantum spectaculum denique sollicito dens. Tonsor somnus ante consectetur deprimo tricesimus calcar.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '01:49:44-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'M',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T04:45:07.451Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1663102110',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741651474',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Life.Church Online',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mental Health',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Sisters',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '75',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '48',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Varies',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Adults Only',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Life.Church Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'null, null',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'INT',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '4600 E. 2nd St.',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '73034',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Restaurant',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#ed0cdc',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '71196',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'BB',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Luigi',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.03',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.49',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Desparatus libero amissio cibo vulgus.',
        description:
          'Cariosus tondeo quibusdam denuo veniam adimpleo a comes dicta baiulus. Asper vox trans cursim vigor suffragium vicissitudo conitor. Vestrum caelestis tracto vacuus cum strues tenus.',
        timeZone: 'CT',
        meetingTime: '01:03 PM',
        meetingTimeIso: '24:10:02-06:00',
        meetingDayOfWeekShort: 'Su',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-02-27T14:41:53.822Z',
        createdDateTime: 1763810943,
        modifiedDateTime: 1740683013,
        facets: {
          campuses: [
            'Broadway & Britton',
            'Tulsa',
            'Stillwater',
            'Oklahoma City',
          ],
          type: 'LifeGroups',
          genders: ['Women', 'Anyone'],
          seasonOfLife: [
            'Single',
            'Married',
            'New Believer',
            'Young Adult',
            'Single Parent',
            'Grandparent',
            'Empty Nester',
            'Widowed',
            'Stay at Home',
          ],
          topic: [
            'Marriage',
            'Volunteering',
            'Message Discussion',
            'Bible Study',
            'Recovery',
            'Friendship',
            'Mental Health',
            'Sisters',
            'Life.Church Online Watch Party',
            'Prayer',
          ],
          ageRangeTop: 65,
          ageRangeBottom: 29,
          meetingType: 'In-Person',
          meetingFrequency: 'Bi-Weekly',
          meetingDayOfWeekFull: 'Wednesday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: 'e17f27af-570c-4b88-9a75-7b205e1dbe94',
          name: 'Oklahoma City',
          city: 'Mansfield',
          state: 'OK',
          locationDescription: 'Mansfield, OK',
          shortCode: 'DLJ',
          street1: '69584 Reginald Park',
          street2: null,
          zip: '29548',
        },
        meetingLocation: {
          locationType: 'Church',
          locationTypeColor: '#8bcbcb',
          city: 'Tulsa',
          state: 'OK',
          zip: '73354',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Lorenz',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.16,
          lng: -97.56,
        },
        objectID: 'f9636502-a60a-45ca-8b7c-4eca29c20ce2',
        _highlightResult: {
          name: {
            value: 'Desparatus libero amissio cibo vulgus.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Cariosus tondeo quibusdam denuo veniam adimpleo a comes dicta baiulus. Asper vox trans cursim vigor suffragium vicissitudo conitor. Vestrum caelestis tracto vacuus cum strues tenus.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '01:03 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '24:10:02-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Su',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-02-27T14:41:53.822Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1763810943',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1740683013',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Broadway & Britton',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                value: 'Marriage',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Volunteering',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Message Discussion',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Bible Study',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Recovery',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Friendship',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Mental Health',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Sisters',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Life.Church Online Watch Party',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Prayer',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            ageRangeTop: {
              value: '65',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '29',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Bi-Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Wednesday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'e17f27af-570c-4b88-9a75-7b205e1dbe94',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Mansfield, OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'DLJ',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '69584 Reginald Park',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '29548',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Church',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#8bcbcb',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '73354',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Lorenz',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.16',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-97.56',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Pax desidero accusator aspicio conservo.',
        description:
          'Angulus terra atavus vulgivagus tamisium attonbitus ipsam patria carpo. Aurum capto cado demoror quae corroboro. Usitas crepusculum succurro conqueror antiquus audacia.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '10:18:58-06:00',
        meetingDayOfWeekShort: 'Sa',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T06:08:22.571Z',
        createdDateTime: 1750527181,
        modifiedDateTime: 1741650983,
        facets: {
          campuses: ['Tulsa', 'Broken Arrow', 'Edmond'],
          type: 'LifeGroups',
          genders: ['Couples', 'Women', 'Anyone'],
          seasonOfLife: [
            'Grandparent',
            'Single Parent',
            'Blended Family',
            'Widowed',
          ],
          topic: [
            {
              name: 'Message Discussion',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 54,
          ageRangeBottom: 19,
          meetingType: 'In-Person',
          meetingFrequency: 'Bi-Weekly',
          meetingDayOfWeekFull: 'Sunday',
          children: 'Children Welcome',
          isShortTermGroup: false,
        },
        campus: {
          guid: '9fc56ddc-e333-4851-af64-16a2bbcee795',
          name: 'Stillwater',
          city: 'Ft. Worth',
          state: 'TX',
          locationDescription: 'Ft. Worth, TX',
          shortCode: 'TIX',
          street1: '79644 W Oak Street',
          street2: 'Suite 600',
          zip: '42221',
        },
        meetingLocation: {
          locationType: 'Restaurant',
          locationTypeColor: '#daed40',
          city: 'Mansfield',
          state: 'TX',
          zip: '10403',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Wilmer',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.6,
          lng: -96.83,
        },
        objectID: 'f87a629b-db25-4119-98cb-a40eea897867',
        _highlightResult: {
          name: {
            value: 'Pax desidero accusator aspicio conservo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Angulus terra atavus vulgivagus tamisium attonbitus ipsam patria carpo. Aurum capto cado demoror quae corroboro. Usitas crepusculum succurro conqueror antiquus audacia.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '10:18:58-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Sa',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T06:08:22.571Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1750527181',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741650983',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Edmond',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Blended Family',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Message Discussion',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '54',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '19',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Bi-Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Sunday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Children Welcome',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '9fc56ddc-e333-4851-af64-16a2bbcee795',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Stillwater',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Ft. Worth, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'TIX',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '79644 W Oak Street',
              matchLevel: 'none',
              matchedWords: [],
            },
            street2: {
              value: 'Suite 600',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '42221',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Restaurant',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#daed40',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '10403',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Wilmer',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.6',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.83',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Tenus desolo ultio spoliatio quis.',
        description:
          'Sursum aro ulterius thorax deficio adflicto occaecati velum. Vinitor texo compello. Ager blanditiis deporto ustilo uxor victus coaegresco neque accedo tabula.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '19:41:11-06:00',
        meetingDayOfWeekShort: 'F',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T08:57:59.780Z',
        createdDateTime: 1637290051,
        modifiedDateTime: 1741695828,
        facets: {
          campuses: [
            'Broadway & Britton',
            'Stillwater',
            'Broken Arrow',
            'Northwest Oklahoma City',
            'Oklahoma City',
          ],
          type: 'LifeGroups',
          genders: ['Men'],
          seasonOfLife: [
            'Married',
            'Single',
            'Young Adult',
            'New Believer',
            'Stay at Home',
          ],
          topic: [
            {
              name: 'Message Discussion',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Discipleship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Book Club',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 84,
          ageRangeBottom: 26,
          meetingType: 'In-Person',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Tuesday',
          children: 'Adults Only',
          isShortTermGroup: true,
        },
        campus: {
          guid: '112f521c-3328-4311-ba04-a8e77fe414a3',
          name: 'Broadway & Britton',
          city: 'Ft. Worth',
          state: 'TX',
          locationDescription: 'Ft. Worth, TX',
          shortCode: 'AOR',
          street1: '777 Emily Mall',
          street2: null,
          zip: '35569',
        },
        meetingLocation: {
          locationType: 'Park',
          locationTypeColor: '#a6447d',
          city: 'Mansfield',
          state: 'OK',
          zip: '63280',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Domingo',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.04,
          lng: -96.99,
        },
        objectID: 'f81059f9-9c58-4975-9428-9334fd33853e',
        _highlightResult: {
          name: {
            value: 'Tenus desolo ultio spoliatio quis.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Sursum aro ulterius thorax deficio adflicto occaecati velum. Vinitor texo compello. Ager blanditiis deporto ustilo uxor victus coaegresco neque accedo tabula.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '19:41:11-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'F',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T08:57:59.780Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1637290051',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741695828',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Broadway & Britton',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Northwest Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Message Discussion',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Discipleship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Book Club',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '84',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '26',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Tuesday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Adults Only',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '112f521c-3328-4311-ba04-a8e77fe414a3',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Broadway & Britton',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Ft. Worth, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'AOR',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '777 Emily Mall',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '35569',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Park',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#a6447d',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '63280',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Domingo',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.04',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.99',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Admoneo sustineo crux tempora defetiscor.',
        description:
          'Nobis venia cubo uredo caput cometes venia socius cuppedia deripio. Curtus suspendo theologus vinculum cimentarius tolero valde. Cognatus angustus sortitus accedo cervus vicissitudo.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '11:35:00-06:00',
        meetingDayOfWeekShort: 'Th',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T16:10:29.250Z',
        createdDateTime: 1599209407,
        modifiedDateTime: 1741668667,
        facets: {
          campuses: ['Life.Church Online'],
          type: 'LifeGroups',
          genders: ['Men', 'Women'],
          seasonOfLife: [
            'Student',
            'Blended Family',
            'Empty Nester',
            'Professional',
            'Grandparent',
            'Divorced',
            'Young Adult',
            'Single',
            'Widowed',
            'Parent',
          ],
          topic: [
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Prayer',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Message Discussion',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Sisters',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 66,
          ageRangeBottom: 36,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Sunday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
          name: 'Life.Church Online',
          city: null,
          state: null,
          locationDescription: 'null, null',
          shortCode: 'INT',
          street1: '4600 E. 2nd St.',
          street2: null,
          zip: '73034',
        },
        meetingLocation: {
          locationType: 'Office',
          locationTypeColor: '#54c72e',
          city: 'Stillwater',
          state: 'TX',
          zip: '97331',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Mireya',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36,
          lng: -97.07,
        },
        objectID: 'f7d33aa6-8954-4ada-b7b8-3fc2d8613864',
        _highlightResult: {
          name: {
            value: 'Admoneo sustineo crux tempora defetiscor.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Nobis venia cubo uredo caput cometes venia socius cuppedia deripio. Curtus suspendo theologus vinculum cimentarius tolero valde. Cognatus angustus sortitus accedo cervus vicissitudo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '11:35:00-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Th',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T16:10:29.250Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1599209407',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741668667',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Life.Church Online',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Student',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Blended Family',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Prayer',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Message Discussion',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Sisters',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '66',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '36',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Sunday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Life.Church Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'null, null',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'INT',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '4600 E. 2nd St.',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '73034',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Office',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#54c72e',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Stillwater',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '97331',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Mireya',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-97.07',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Cavus viriliter viridis attero viriliter.',
        description:
          'Viduo ultio adsidue canis vigilo adipisci fugiat altus. Benigne claro commodi antiquus confido. Vulpes crinis odit adulatio alias depereo delinquo video eum.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '15:39:32-06:00',
        meetingDayOfWeekShort: 'W',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-10T17:04:25.385Z',
        createdDateTime: 1587461060,
        modifiedDateTime: 1741662047,
        facets: {
          campuses: ['Tulsa'],
          type: 'LifeGroups',
          genders: ['Men', 'Anyone', 'Couples'],
          seasonOfLife: [
            'Widowed',
            'Grandparent',
            'Parent',
            'Empty Nester',
            'Professional',
            'Blended Family',
          ],
          topic: [
            {
              name: 'Message Discussion',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Book Club',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Discipleship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mental Health',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Prayer',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 50,
          ageRangeBottom: 26,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Monday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: '6316e0bd-b6f9-438c-bbf3-3fffa2399498',
          name: 'Oklahoma City',
          city: 'Oklahoma City',
          state: 'TX',
          locationDescription: 'Oklahoma City, TX',
          shortCode: 'HEP',
          street1: '32838 Marietta Ranch',
          street2: null,
          zip: '38703',
        },
        meetingLocation: {
          locationType: 'Coffee Shop',
          locationTypeColor: '#468fd2',
          city: 'Tulsa',
          state: 'OK',
          zip: '56313',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Georgianna',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.41,
          lng: -96.54,
        },
        objectID: 'f4a8c556-738c-4c07-8cfe-eb7eec50b572',
        _highlightResult: {
          name: {
            value: 'Cavus viriliter viridis attero viriliter.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Viduo ultio adsidue canis vigilo adipisci fugiat altus. Benigne claro commodi antiquus confido. Vulpes crinis odit adulatio alias depereo delinquo video eum.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '15:39:32-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'W',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-10T17:04:25.385Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1587461060',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741662047',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Blended Family',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Message Discussion',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Book Club',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Discipleship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mental Health',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Prayer',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '50',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '26',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Monday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '6316e0bd-b6f9-438c-bbf3-3fffa2399498',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Oklahoma City, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'HEP',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '32838 Marietta Ranch',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '38703',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Coffee Shop',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#468fd2',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '56313',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Georgianna',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.41',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.54',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Vehemens minus cotidie tribuo tot.',
        description:
          'Deorsum abutor ulterius. Tenax ab quam colo. Iusto sollers amiculum caries virgo ubi.',
        timeZone: 'CT',
        meetingTime: '04:59 AM',
        meetingTimeIso: '14:07:15-06:00',
        meetingDayOfWeekShort: 'Th',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-02-27T16:32:04.786Z',
        createdDateTime: 1761357005,
        modifiedDateTime: 1740653971,
        facets: {
          campuses: ['Tulsa', 'Edmond', 'Broken Arrow'],
          type: 'LifeGroups',
          genders: ['Couples', 'Anyone', 'Men'],
          seasonOfLife: ['New Believer'],
          topic: [
            'Marriage',
            'Recovery',
            'Financial Coaching',
            'Life.Church Online Watch Party',
            'Prayer',
            'Volunteering',
            'Bible Study',
            'Parenting',
            'Hobby',
            'Sisters',
          ],
          ageRangeTop: 78,
          ageRangeBottom: 25,
          meetingType: 'In-Person',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Varies',
          children: 'Children Welcome',
          isShortTermGroup: false,
        },
        campus: {
          guid: 'ceedd9ca-7889-4887-bd91-79bb786e2d02',
          name: 'Tulsa',
          city: 'Stillwater',
          state: 'OK',
          locationDescription: 'Stillwater, OK',
          shortCode: 'WEF',
          street1: '4063 George Street',
          street2: null,
          zip: '69248',
        },
        meetingLocation: {
          locationType: 'Park',
          locationTypeColor: '#afb8f6',
          city: 'Oklahoma City',
          state: 'TX',
          zip: '76574',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Rozella',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.56,
          lng: -96.52,
        },
        objectID: 'f39dbd19-f6e9-47c0-a081-9574a29b000d',
        _highlightResult: {
          name: {
            value: 'Vehemens minus cotidie tribuo tot.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Deorsum abutor ulterius. Tenax ab quam colo. Iusto sollers amiculum caries virgo ubi.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '04:59 AM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '14:07:15-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Th',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-02-27T16:32:04.786Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1761357005',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1740653971',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Edmond',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                value: 'Marriage',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Recovery',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Financial Coaching',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Life.Church Online Watch Party',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Prayer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Volunteering',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Bible Study',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parenting',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Hobby',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Sisters',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            ageRangeTop: {
              value: '78',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '25',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Varies',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Children Welcome',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'ceedd9ca-7889-4887-bd91-79bb786e2d02',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Stillwater',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Stillwater, OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'WEF',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '4063 George Street',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '69248',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Park',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#afb8f6',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '76574',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Rozella',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.56',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.52',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Tollo sollers vicinus comis trucido.',
        description:
          'Desidero dolore bonus corrumpo. Carus tametsi deserunt. Spargo cicuta in eveniet viriliter.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '05:26:13-06:00',
        meetingDayOfWeekShort: 'F',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T06:52:54.853Z',
        createdDateTime: 1611203681,
        modifiedDateTime: 1741632020,
        facets: {
          campuses: [
            'Broken Arrow',
            'Stillwater',
            'Broadway & Britton',
            'Oklahoma City',
            'Edmond',
          ],
          type: 'LifeGroups',
          genders: ['Women', 'Couples', 'Anyone', 'Men'],
          seasonOfLife: ['Empty Nester', 'Grandparent'],
          topic: [
            {
              name: 'Friendship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Message Discussion',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Prayer',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Parenting',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Discipleship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 76,
          ageRangeBottom: 34,
          meetingType: 'Online',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Friday',
          children: 'Children Welcome',
          isShortTermGroup: true,
        },
        campus: {
          guid: 'c33f5b8d-d5f4-4931-955d-0578b713f226',
          name: 'Stillwater',
          city: 'Mansfield',
          state: 'TX',
          locationDescription: 'Mansfield, TX',
          shortCode: 'LGZ',
          street1: '3309 Gordon Road',
          street2: null,
          zip: '67843',
        },
        meetingLocation: {
          locationType: 'Church',
          locationTypeColor: '#1ad35a',
          city: 'Oklahoma City',
          state: 'OK',
          zip: '49813',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Luis',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.39,
          lng: -96.93,
        },
        objectID: 'f2c1ee1a-11a6-42a5-ad73-62ac46509c21',
        _highlightResult: {
          name: {
            value: 'Tollo sollers vicinus comis trucido.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Desidero dolore bonus corrumpo. Carus tametsi deserunt. Spargo cicuta in eveniet viriliter.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '05:26:13-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'F',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T06:52:54.853Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1611203681',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741632020',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broadway & Britton',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Edmond',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Friendship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Message Discussion',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Prayer',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Parenting',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Discipleship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '76',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '34',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Friday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Children Welcome',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'c33f5b8d-d5f4-4931-955d-0578b713f226',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Stillwater',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Mansfield, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'LGZ',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '3309 Gordon Road',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '67843',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Church',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#1ad35a',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '49813',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Luis',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.39',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.93',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Villa curiositas error aeneus utilis.',
        description:
          'Dedecor natus defaeco terror sunt deputo sit a. Curis reprehenderit atque claro iure conitor arbor. Tres audio cado quibusdam vallum.',
        timeZone: 'CT',
        meetingTime: '11:53 AM',
        meetingTimeIso: '10:18:54-06:00',
        meetingDayOfWeekShort: 'F',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-02-27T16:05:00.888Z',
        createdDateTime: 1763034063,
        modifiedDateTime: 1740678814,
        facets: {
          campuses: ['Life.Church Online'],
          type: 'LifeGroups',
          genders: ['Men', 'Couples', 'Women'],
          seasonOfLife: [
            'Divorced',
            'Stay at Home',
            'Grandparent',
            'Student',
            'Professional',
            'Married',
            'Young Adult',
            'Blended Family',
            'Widowed',
          ],
          topic: ['Hobby'],
          ageRangeTop: 66,
          ageRangeBottom: 37,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Thursday',
          children: 'Adults Only',
          isShortTermGroup: true,
        },
        campus: {
          guid: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
          name: 'Life.Church Online',
          city: null,
          state: null,
          locationDescription: 'null, null',
          shortCode: 'INT',
          street1: '4600 E. 2nd St.',
          street2: null,
          zip: '73034',
        },
        meetingLocation: {
          locationType: 'Park',
          locationTypeColor: '#3e0cfd',
          city: 'Mansfield',
          state: 'OK',
          zip: '59957',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Isabell',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.03,
          lng: -95.81,
        },
        objectID: 'f09f34e9-3ead-458b-b66b-2022b3eaa537',
        _highlightResult: {
          name: {
            value: 'Villa curiositas error aeneus utilis.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Dedecor natus defaeco terror sunt deputo sit a. Curis reprehenderit atque claro iure conitor arbor. Tres audio cado quibusdam vallum.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '11:53 AM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '10:18:54-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'F',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-02-27T16:05:00.888Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1763034063',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1740678814',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Life.Church Online',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Student',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Blended Family',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                value: 'Hobby',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            ageRangeTop: {
              value: '66',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '37',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Thursday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Adults Only',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Life.Church Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'null, null',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'INT',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '4600 E. 2nd St.',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '73034',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Park',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#3e0cfd',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '59957',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Isabell',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.03',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-95.81',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Undique vulticulus aufero depopulo vitiosus.',
        description:
          'Victoria pecus corona calcar. Cernuus cognatus armarium audentia uxor deprecator candidus curso stella cena. Aliquam depereo curia delectus totam nisi communis.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '07:59:13-06:00',
        meetingDayOfWeekShort: 'Tu',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T07:59:06.160Z',
        createdDateTime: 1627004256,
        modifiedDateTime: 1741688364,
        facets: {
          campuses: ['Life.Church Online'],
          type: 'LifeGroups',
          genders: ['Men'],
          seasonOfLife: [
            'Empty Nester',
            'Grandparent',
            'Stay at Home',
            'Single Parent',
            'Widowed',
            'Student',
            'Divorced',
          ],
          topic: [
            {
              name: 'Volunteering',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Parenting',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Book Club',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Message Discussion',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Discipleship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 96,
          ageRangeBottom: 30,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Thursday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
          name: 'Life.Church Online',
          city: null,
          state: null,
          locationDescription: 'null, null',
          shortCode: 'INT',
          street1: '4600 E. 2nd St.',
          street2: null,
          zip: '73034',
        },
        meetingLocation: {
          locationType: 'Home',
          locationTypeColor: '#40e8b2',
          city: 'Tulsa',
          state: 'OK',
          zip: '19926',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Clifford',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.95,
          lng: -96.55,
        },
        objectID: 'effe135f-4344-40f2-9600-ece16f16508a',
        _highlightResult: {
          name: {
            value: 'Undique vulticulus aufero depopulo vitiosus.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Victoria pecus corona calcar. Cernuus cognatus armarium audentia uxor deprecator candidus curso stella cena. Aliquam depereo curia delectus totam nisi communis.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '07:59:13-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Tu',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T07:59:06.160Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1627004256',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741688364',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Life.Church Online',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Student',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Volunteering',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Parenting',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Book Club',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Message Discussion',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Discipleship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '96',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '30',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Thursday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'f387cc05-3f9a-4eb2-aefe-07b4787baf99',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Life.Church Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'null, null',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'INT',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '4600 E. 2nd St.',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '73034',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Home',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#40e8b2',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '19926',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Clifford',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.95',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.55',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Accommodo undique denuncio vorago molestias.',
        description:
          'Tyrannus facere attonbitus. Ceno usus aestivus cui. Creta desino tonsor umquam.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '12:44:18-06:00',
        meetingDayOfWeekShort: 'Varies',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T04:38:57.872Z',
        createdDateTime: 1654464504,
        modifiedDateTime: 1741677646,
        facets: {
          campuses: [
            'Broken Arrow',
            'Stillwater',
            'Tulsa',
            'Northwest Oklahoma City',
            'Oklahoma City',
          ],
          type: 'LifeGroups',
          genders: ['Anyone'],
          seasonOfLife: [
            'Young Adult',
            'Stay at Home',
            'Empty Nester',
            'Grandparent',
            'Married',
            'Parent',
            'New Believer',
            'Professional',
          ],
          topic: [
            {
              name: 'Discipleship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Leadership Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Prayer',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 91,
          ageRangeBottom: 40,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Monday',
          children: 'Adults Only',
          isShortTermGroup: false,
        },
        campus: {
          guid: 'a42c08f6-5711-44a9-8227-9d1ed64527a2',
          name: 'Mansfield',
          city: 'Stillwater',
          state: 'TX',
          locationDescription: 'Stillwater, TX',
          shortCode: 'GLJ',
          street1: '465 Aryanna Bridge',
          street2: null,
          zip: '40199',
        },
        meetingLocation: {
          locationType: 'Coffee Shop',
          locationTypeColor: '#e1c6e9',
          city: 'Oklahoma City',
          state: 'OK',
          zip: '26087',
          country: 'NP',
        },
        groupLeaders: [
          {
            firstName: 'Daija',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.05,
          lng: -96.81,
        },
        objectID: 'ee4bf44a-49c2-4762-9a08-cd74c84735a0',
        _highlightResult: {
          name: {
            value: 'Accommodo undique denuncio vorago molestias.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Tyrannus facere attonbitus. Ceno usus aestivus cui. Creta desino tonsor umquam.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '12:44:18-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Varies',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T04:38:57.872Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1654464504',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741677646',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Northwest Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Discipleship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Leadership Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Prayer',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '91',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '40',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Monday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Adults Only',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'a42c08f6-5711-44a9-8227-9d1ed64527a2',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Stillwater',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Stillwater, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'GLJ',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '465 Aryanna Bridge',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '40199',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Coffee Shop',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#e1c6e9',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '26087',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'NP',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Daija',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.05',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.81',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Magni coma delinquo paens odit.',
        description:
          'Desino ocer ut titulus. Articulus conturbo stabilis crepusculum depulso creptio cura aeneus copia. Alveus sortitus tardus amplexus teres adstringo tristis tergo adsidue succedo.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '04:52:02-06:00',
        meetingDayOfWeekShort: 'Tu',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T05:22:27.268Z',
        createdDateTime: 1638385576,
        modifiedDateTime: 1741703122,
        facets: {
          campuses: ['Stillwater', 'Broken Arrow', 'Oklahoma City'],
          type: 'LifeGroups',
          genders: ['Men', 'Anyone'],
          seasonOfLife: [
            'New Believer',
            'Divorced',
            'Single Parent',
            'Married',
            'Empty Nester',
            'Young Adult',
            'Student',
            'Widowed',
            'Professional',
            'Single',
          ],
          topic: [
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Sisters',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Leadership Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Parenting',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 51,
          ageRangeBottom: 48,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Thursday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: '6f183297-e4d9-4fa8-aa81-9e4bba5305ab',
          name: 'Edmond',
          city: 'Oklahoma City',
          state: 'TX',
          locationDescription: 'Oklahoma City, TX',
          shortCode: 'GQC',
          street1: '14285 Madisyn Well',
          street2: null,
          zip: '23110',
        },
        meetingLocation: {
          locationType: 'Restaurant',
          locationTypeColor: '#4ff4fe',
          city: 'Ft. Worth',
          state: 'TX',
          zip: '62194',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Roderick',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.24,
          lng: -96.66,
        },
        objectID: 'ec1e4020-be03-4f08-acba-b33cf8016d5a',
        _highlightResult: {
          name: {
            value: 'Magni coma delinquo paens odit.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Desino ocer ut titulus. Articulus conturbo stabilis crepusculum depulso creptio cura aeneus copia. Alveus sortitus tardus amplexus teres adstringo tristis tergo adsidue succedo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '04:52:02-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Tu',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T05:22:27.268Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1638385576',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741703122',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Student',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Sisters',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Leadership Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Parenting',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '51',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '48',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Thursday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '6f183297-e4d9-4fa8-aa81-9e4bba5305ab',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Edmond',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Oklahoma City, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'GQC',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '14285 Madisyn Well',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '23110',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Restaurant',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#4ff4fe',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '62194',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Roderick',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.24',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.66',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Aspicio tabella cursus balbus teneo.',
        description:
          'Viriliter debeo libero maxime conculco delectus conitor vulgaris. Conventus trans sapiente adstringo aestivus aspernatur stipes cognatus ago aptus. Aetas cubicularis coepi defleo beatae carmen quam.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '14:33:01-06:00',
        meetingDayOfWeekShort: 'Su',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-10T19:13:02.071Z',
        createdDateTime: 1751536703,
        modifiedDateTime: 1741675045,
        facets: {
          campuses: [
            'Edmond',
            'Broken Arrow',
            'Stillwater',
            'Northwest Oklahoma City',
          ],
          type: 'LifeGroups',
          genders: ['Men', 'Anyone', 'Couples', 'Women'],
          seasonOfLife: ['New Believer', 'Grandparent', 'Parent', 'Single'],
          topic: [
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Friendship',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 80,
          ageRangeBottom: 28,
          meetingType: 'Online',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Sunday',
          children: 'Children Welcome',
          isShortTermGroup: false,
        },
        campus: {
          guid: '3b2effd2-52b6-447e-9972-abd72b623425',
          name: 'Ft. Worth',
          city: 'Tulsa',
          state: 'OK',
          locationDescription: 'Tulsa, OK',
          shortCode: 'BOI',
          street1: '52938 Shields Flats',
          street2: null,
          zip: '88825',
        },
        meetingLocation: {
          locationType: 'Park',
          locationTypeColor: '#ebebad',
          city: 'Tulsa',
          state: 'TX',
          zip: '82061',
          country: 'MO',
        },
        groupLeaders: [
          {
            firstName: 'America',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.08,
          lng: -97.47,
        },
        objectID: 'e9b2bd89-0bd2-43e9-835b-c2e0d63a35f6',
        _highlightResult: {
          name: {
            value: 'Aspicio tabella cursus balbus teneo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Viriliter debeo libero maxime conculco delectus conitor vulgaris. Conventus trans sapiente adstringo aestivus aspernatur stipes cognatus ago aptus. Aetas cubicularis coepi defleo beatae carmen quam.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '14:33:01-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Su',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-10T19:13:02.071Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1751536703',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741675045',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Edmond',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Northwest Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Friendship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '80',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '28',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Sunday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Children Welcome',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '3b2effd2-52b6-447e-9972-abd72b623425',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'BOI',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '52938 Shields Flats',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '88825',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Park',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#ebebad',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '82061',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'MO',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'America',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.08',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-97.47',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Sapiente tempora voveo cedo adflicto.',
        description:
          'Bonus decipio audacia ulterius decumbo cornu velit terebro. Cenaculum coaegresco caput aveho ventus cura tubineus comitatus. Sit adipiscor cupiditas ulciscor amaritudo thymbra succurro calcar.',
        timeZone: 'CT',
        meetingTime: '03:32 PM',
        meetingTimeIso: '13:36:14-06:00',
        meetingDayOfWeekShort: 'F',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-02-27T10:12:10.448Z',
        createdDateTime: 1607735298,
        modifiedDateTime: 1740605573,
        facets: {
          campuses: ['Oklahoma City', 'Tulsa', 'Stillwater'],
          type: 'LifeGroups',
          genders: ['Men'],
          seasonOfLife: [
            'Empty Nester',
            'Divorced',
            'Single Parent',
            'Young Adult',
            'Grandparent',
            'Student',
            'Professional',
          ],
          topic: [
            'Sisters',
            'Prayer',
            'Leadership Coaching',
            'Life.Church Online Watch Party',
            'Mental Health',
            'Bible Study',
            'Recovery',
            'Parenting',
            'Hobby',
          ],
          ageRangeTop: 87,
          ageRangeBottom: 32,
          meetingType: 'In-Person',
          meetingFrequency: 'Monthly',
          meetingDayOfWeekFull: 'Wednesday',
          children: 'Adults Only',
          isShortTermGroup: false,
        },
        campus: {
          guid: '88221116-2ae5-424b-9273-eb32de3bfdc8',
          name: 'Tulsa',
          city: 'Ft. Worth',
          state: 'TX',
          locationDescription: 'Ft. Worth, TX',
          shortCode: 'LXI',
          street1: '120 Bechtelar Lane',
          street2: null,
          zip: '46103',
        },
        meetingLocation: {
          locationType: 'Restaurant',
          locationTypeColor: '#be6a61',
          city: 'Tulsa',
          state: 'TX',
          zip: '88727',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Minnie',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.16,
          lng: -97.01,
        },
        objectID: 'e8ba23d9-b001-4ce3-82b5-cf0900a80f55',
        _highlightResult: {
          name: {
            value: 'Sapiente tempora voveo cedo adflicto.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Bonus decipio audacia ulterius decumbo cornu velit terebro. Cenaculum coaegresco caput aveho ventus cura tubineus comitatus. Sit adipiscor cupiditas ulciscor amaritudo thymbra succurro calcar.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '03:32 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '13:36:14-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'F',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-02-27T10:12:10.448Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1607735298',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1740605573',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Student',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                value: 'Sisters',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Prayer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Leadership Coaching',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Life.Church Online Watch Party',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Mental Health',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Bible Study',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Recovery',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parenting',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Hobby',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            ageRangeTop: {
              value: '87',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '32',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Monthly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Wednesday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Adults Only',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '88221116-2ae5-424b-9273-eb32de3bfdc8',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Ft. Worth, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'LXI',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '120 Bechtelar Lane',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '46103',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Restaurant',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#be6a61',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '88727',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Minnie',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.16',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-97.01',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Pauci tres custodia solus ullam.',
        description:
          'Callide acquiro cohaero textilis canonicus laboriosam cogito. Verbera vito commodo. Volup varietas dapifer admitto copiose viriliter calculus corrigo.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '16:14:58-06:00',
        meetingDayOfWeekShort: 'Su',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T11:54:03.680Z',
        createdDateTime: 1751683150,
        modifiedDateTime: 1741694437,
        facets: {
          campuses: [
            'Northwest Oklahoma City',
            'Tulsa',
            'Oklahoma City',
            'Broken Arrow',
          ],
          type: 'LifeGroups',
          genders: ['Men'],
          seasonOfLife: ['Single', 'Widowed', 'Divorced'],
          topic: [
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Discipleship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 78,
          ageRangeBottom: 18,
          meetingType: 'In-Person',
          meetingFrequency: 'Bi-Weekly',
          meetingDayOfWeekFull: 'Monday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: '5ca8c01e-1f60-41b3-8a44-da76315c7a7e',
          name: 'Ft. Worth',
          city: 'Tulsa',
          state: 'TX',
          locationDescription: 'Tulsa, TX',
          shortCode: 'ODO',
          street1: '126 Hope Landing',
          street2: null,
          zip: '49741',
        },
        meetingLocation: {
          locationType: 'Park',
          locationTypeColor: '#eddc90',
          city: 'Tulsa',
          state: 'TX',
          zip: '47064',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Eulalia',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 36.08,
          lng: -97.21,
        },
        objectID: 'e4d7f25b-7474-46e7-936f-4842829d0b5a',
        _highlightResult: {
          name: {
            value: 'Pauci tres custodia solus ullam.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Callide acquiro cohaero textilis canonicus laboriosam cogito. Verbera vito commodo. Volup varietas dapifer admitto copiose viriliter calculus corrigo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '16:14:58-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Su',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T11:54:03.680Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1751683150',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741694437',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Northwest Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Discipleship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '78',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '18',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Bi-Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Monday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '5ca8c01e-1f60-41b3-8a44-da76315c7a7e',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'ODO',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '126 Hope Landing',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '49741',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Park',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#eddc90',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '47064',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Eulalia',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '36.08',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-97.21',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Utrum spectaculum autem debilito suppono.',
        description:
          'Cognatus facere rerum curto aveho arbor sum acsi ambitus. Absque calamitas umbra custodia iste cuppedia ter terra. Vero verbera cado torrens arbor adeptio tandem antea carus.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '03:41:06-06:00',
        meetingDayOfWeekShort: 'Su',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T03:22:27.788Z',
        createdDateTime: 1635027452,
        modifiedDateTime: 1741656043,
        facets: {
          campuses: ['Stillwater'],
          type: 'LifeGroups',
          genders: ['Women', 'Couples'],
          seasonOfLife: [
            'Empty Nester',
            'Student',
            'Grandparent',
            'Single',
            'New Believer',
            'Stay at Home',
            'Widowed',
          ],
          topic: [
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Book Club',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Leadership Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Friendship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Parenting',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 78,
          ageRangeBottom: 25,
          meetingType: 'Online',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Friday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: '23f3e58f-5621-4c5c-8c36-58ac80ba045b',
          name: 'Ft. Worth',
          city: 'Tulsa',
          state: 'TX',
          locationDescription: 'Tulsa, TX',
          shortCode: 'LMI',
          street1: '14392 N Pearl Street',
          street2: null,
          zip: '71727',
        },
        meetingLocation: {
          locationType: 'Restaurant',
          locationTypeColor: '#b5b5f1',
          city: 'Oklahoma City',
          state: 'OK',
          zip: '13931',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Nova',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.79,
          lng: -97.38,
        },
        objectID: 'e345d653-925e-4e5d-ae92-9ac57c059416',
        _highlightResult: {
          name: {
            value: 'Utrum spectaculum autem debilito suppono.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Cognatus facere rerum curto aveho arbor sum acsi ambitus. Absque calamitas umbra custodia iste cuppedia ter terra. Vero verbera cado torrens arbor adeptio tandem antea carus.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '03:41:06-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'Su',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T03:22:27.788Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1635027452',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741656043',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Stillwater',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Student',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Book Club',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Leadership Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Friendship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Parenting',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '78',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '25',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'Online',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Friday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '23f3e58f-5621-4c5c-8c36-58ac80ba045b',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'LMI',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '14392 N Pearl Street',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '71727',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Restaurant',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#b5b5f1',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '13931',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Nova',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.79',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-97.38',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Absorbeo curso accusamus vilis spoliatio.',
        description:
          'Vado acsi curriculum odio thesis arguo. Vito molestiae collum. Tremo aegre theca comptus speculum.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '10:20:40-06:00',
        meetingDayOfWeekShort: 'F',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T12:01:29.386Z',
        createdDateTime: 1735158758,
        modifiedDateTime: 1741702539,
        facets: {
          campuses: ['Broadway & Britton'],
          type: 'LifeGroups',
          genders: ['Couples', 'Women', 'Men', 'Anyone'],
          seasonOfLife: ['New Believer', 'Married'],
          topic: [
            {
              name: 'Mental Health',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Leadership Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Volunteering',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Book Club',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Prayer',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 99,
          ageRangeBottom: 44,
          meetingType: 'In-Person',
          meetingFrequency: 'Bi-Weekly',
          meetingDayOfWeekFull: 'Friday',
          children: 'Adults Only',
          isShortTermGroup: true,
        },
        campus: {
          guid: '146a628a-4bef-4377-ac50-3131eb71537e',
          name: 'Tulsa',
          city: 'Tulsa',
          state: 'TX',
          locationDescription: 'Tulsa, TX',
          shortCode: 'JSJ',
          street1: '625 Elna Highway',
          street2: null,
          zip: '25422',
        },
        meetingLocation: {
          locationType: 'Office',
          locationTypeColor: '#78ad6e',
          city: 'Tulsa',
          state: 'TX',
          zip: '15488',
          country: 'PK',
        },
        groupLeaders: [
          {
            firstName: 'Newton',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.19,
          lng: -96.66,
        },
        objectID: 'dd7fa160-de29-41d1-bf22-d2b29e63789a',
        _highlightResult: {
          name: {
            value: 'Absorbeo curso accusamus vilis spoliatio.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Vado acsi curriculum odio thesis arguo. Vito molestiae collum. Tremo aegre theca comptus speculum.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '10:20:40-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'F',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T12:01:29.386Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1735158758',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741702539',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Broadway & Britton',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Mental Health',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Leadership Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Volunteering',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Book Club',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Prayer',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '99',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '44',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Bi-Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Friday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Adults Only',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '146a628a-4bef-4377-ac50-3131eb71537e',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'JSJ',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '625 Elna Highway',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '25422',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Office',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#78ad6e',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '15488',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'PK',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Newton',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.19',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.66',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Umbra cum acies demonstro corona.',
        description:
          'Sequi consequuntur decens cedo et quidem succurro solus. Demonstro aeneus quas amplitudo sufficio subnecto voluptatibus adficio veritatis. Suscipit tenax cognatus aperiam tredecim amitto.',
        timeZone: 'CT',
        meetingTime: '09:47 PM',
        meetingTimeIso: '05:37:03-06:00',
        meetingDayOfWeekShort: 'M',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Children are welcome at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T10:53:53.821Z',
        createdDateTime: 1736582686,
        modifiedDateTime: 1741666123,
        facets: {
          campuses: [
            'Oklahoma City',
            'Tulsa',
            'Broadway & Britton',
            'Broken Arrow',
          ],
          type: 'LifeGroups',
          genders: ['Anyone', 'Women'],
          seasonOfLife: [
            'Grandparent',
            'Single Parent',
            'New Believer',
            'Single',
            'Divorced',
            'Widowed',
            'Professional',
            'Married',
          ],
          topic: [
            {
              name: 'Mental Health',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Sisters',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Leadership Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Friendship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Prayer',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Parenting',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 64,
          ageRangeBottom: 47,
          meetingType: 'In-Person',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Thursday',
          children: 'Children Welcome',
          isShortTermGroup: false,
        },
        campus: {
          guid: '621d4bdd-7804-42a8-8211-dbc57b42bebf',
          name: 'Broadway & Britton',
          city: 'Tulsa',
          state: 'TX',
          locationDescription: 'Tulsa, TX',
          shortCode: 'YNS',
          street1: '31057 Columbia Avenue',
          street2: null,
          zip: '90066',
        },
        meetingLocation: {
          locationType: 'Church',
          locationTypeColor: '#b0d550',
          city: 'Ft. Worth',
          state: 'OK',
          zip: '98584',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Jadon',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.41,
          lng: -96.37,
        },
        objectID: 'dd0a0848-29cd-4e85-b080-62ac7b50071a',
        _highlightResult: {
          name: {
            value: 'Umbra cum acies demonstro corona.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Sequi consequuntur decens cedo et quidem succurro solus. Demonstro aeneus quas amplitudo sufficio subnecto voluptatibus adficio veritatis. Suscipit tenax cognatus aperiam tredecim amitto.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:47 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '05:37:03-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'M',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Children are welcome at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T10:53:53.821Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1736582686',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741666123',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Tulsa',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broadway & Britton',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Grandparent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Widowed',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Mental Health',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Sisters',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Leadership Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Friendship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Prayer',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Parenting',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '64',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '47',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Thursday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Children Welcome',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '621d4bdd-7804-42a8-8211-dbc57b42bebf',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Broadway & Britton',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'YNS',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '31057 Columbia Avenue',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '90066',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Church',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#b0d550',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Ft. Worth',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '98584',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Jadon',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.41',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.37',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Amaritudo collum unde terreo cimentarius.',
        description:
          'Vir utilis spargo nemo laudantium. Appositus virtus vetus cupio dolorem turbo cur repudiandae adicio. Est carpo concedo.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '06:26:28-06:00',
        meetingDayOfWeekShort: 'F',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Childcare will be provided',
        shortTermGroupEndDate: '2025-03-11T10:47:44.132Z',
        createdDateTime: 1625160885,
        modifiedDateTime: 1741704101,
        facets: {
          campuses: ['Northwest Oklahoma City', 'Edmond'],
          type: 'LifeGroups',
          genders: ['Women', 'Couples', 'Anyone', 'Men'],
          seasonOfLife: [
            'Single Parent',
            'Blended Family',
            'New Believer',
            'Married',
            'Young Adult',
            'Empty Nester',
            'Professional',
            'Divorced',
          ],
          topic: [
            {
              name: 'Recovery',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Book Club',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Marriage',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Hobby',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mental Health',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Sisters',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Friendship',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 71,
          ageRangeBottom: 30,
          meetingType: 'In-Person',
          meetingFrequency: 'Bi-Weekly',
          meetingDayOfWeekFull: 'Friday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: '7b699286-1c5e-471a-ab25-ce6077f8d653',
          name: 'Mansfield',
          city: 'Tulsa',
          state: 'OK',
          locationDescription: 'Tulsa, OK',
          shortCode: 'JAM',
          street1: '45012 Bramley Close',
          street2: null,
          zip: '12159',
        },
        meetingLocation: {
          locationType: 'Restaurant',
          locationTypeColor: '#b0abb7',
          city: 'Oklahoma City',
          state: 'OK',
          zip: '55235',
          country: 'US',
        },
        groupLeaders: [
          {
            firstName: 'Lizzie',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.48,
          lng: -96.55,
        },
        objectID: 'dbde26ed-50ae-484a-99e5-84ecaee6627b',
        _highlightResult: {
          name: {
            value: 'Amaritudo collum unde terreo cimentarius.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Vir utilis spargo nemo laudantium. Appositus virtus vetus cupio dolorem turbo cur repudiandae adicio. Est carpo concedo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '06:26:28-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'F',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Childcare will be provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T10:47:44.132Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1625160885',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741704101',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Northwest Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Edmond',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Blended Family',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Young Adult',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Empty Nester',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Recovery',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Book Club',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Marriage',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Hobby',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mental Health',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Sisters',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Friendship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '71',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '30',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Bi-Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Friday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: '7b699286-1c5e-471a-ab25-ce6077f8d653',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'JAM',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '45012 Bramley Close',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '12159',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Restaurant',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#b0abb7',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '55235',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'US',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Lizzie',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.48',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-96.55',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
      {
        name: 'Unus tergeo totus utrimque colligo.',
        description:
          'Stultus unus trucido. Solus celebrer defendo. Iusto caterva dapifer modi ventus terror.',
        timeZone: 'CT',
        meetingTime: '09:48 PM',
        meetingTimeIso: '20:46:28-06:00',
        meetingDayOfWeekShort: 'W',
        coverImage: '/lc-site/locations/campus_default.jpeg',
        publicCoverImageUrl:
          'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
        childrenWelcomeDescription: 'Sorry, no children at this life group',
        childrenCareDescription: 'Sorry, no childcare provided',
        shortTermGroupEndDate: '2025-03-11T05:04:13.152Z',
        createdDateTime: 1639214488,
        modifiedDateTime: 1741703583,
        facets: {
          campuses: ['Oklahoma City', 'Broken Arrow'],
          type: 'LifeGroups',
          genders: ['Couples', 'Anyone', 'Men', 'Women'],
          seasonOfLife: [
            'Single Parent',
            'New Believer',
            'Parent',
            'Married',
            'Blended Family',
            'Single',
            'Stay at Home',
            'Professional',
            'Divorced',
          ],
          topic: [
            {
              name: 'Friendship',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Financial Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Life.Church Online Watch Party',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mental Health',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Mentoring',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Volunteering',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Leadership Coaching',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Bible Study',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Parenting',
              image: './some/path/to_image.jpg',
            },
            {
              name: 'Sisters',
              image: './some/path/to_image.jpg',
            },
          ],
          ageRangeTop: 80,
          ageRangeBottom: 47,
          meetingType: 'In-Person',
          meetingFrequency: 'Weekly',
          meetingDayOfWeekFull: 'Friday',
          children: 'Childcare Available',
          isShortTermGroup: true,
        },
        campus: {
          guid: 'a92210cb-7834-4354-85bf-c92385b0a524',
          name: 'Mansfield',
          city: 'Tulsa',
          state: 'TX',
          locationDescription: 'Tulsa, TX',
          shortCode: 'DSR',
          street1: '94607 Gislason Lodge',
          street2: null,
          zip: '70261',
        },
        meetingLocation: {
          locationType: 'Coffee Shop',
          locationTypeColor: '#b82bd8',
          city: 'Oklahoma City',
          state: 'OK',
          zip: '20825',
          country: 'VC',
        },
        groupLeaders: [
          {
            firstName: 'Sidney',
            avatar: '/lc-site/locations/campus-pastor-default.jpg',
            publicAvatarUrl:
              'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
          },
        ],
        _geoloc: {
          lat: 35.93,
          lng: -95.82,
        },
        objectID: 'db072657-9014-4f0e-83f1-009afea7aaa1',
        _highlightResult: {
          name: {
            value: 'Unus tergeo totus utrimque colligo.',
            matchLevel: 'none',
            matchedWords: [],
          },
          description: {
            value:
              'Stultus unus trucido. Solus celebrer defendo. Iusto caterva dapifer modi ventus terror.',
            matchLevel: 'none',
            matchedWords: [],
          },
          timeZone: {
            value: 'CT',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTime: {
            value: '09:48 PM',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingTimeIso: {
            value: '20:46:28-06:00',
            matchLevel: 'none',
            matchedWords: [],
          },
          meetingDayOfWeekShort: {
            value: 'W',
            matchLevel: 'none',
            matchedWords: [],
          },
          coverImage: {
            value: '/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          publicCoverImageUrl: {
            value:
              'https://cms-images.life.church/lc-site/locations/campus_default.jpeg',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenWelcomeDescription: {
            value: 'Sorry, no children at this life group',
            matchLevel: 'none',
            matchedWords: [],
          },
          childrenCareDescription: {
            value: 'Sorry, no childcare provided',
            matchLevel: 'none',
            matchedWords: [],
          },
          shortTermGroupEndDate: {
            value: '2025-03-11T05:04:13.152Z',
            matchLevel: 'none',
            matchedWords: [],
          },
          createdDateTime: {
            value: '1639214488',
            matchLevel: 'none',
            matchedWords: [],
          },
          modifiedDateTime: {
            value: '1741703583',
            matchLevel: 'none',
            matchedWords: [],
          },
          facets: {
            campuses: [
              {
                value: 'Oklahoma City',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Broken Arrow',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            type: {
              value: 'LifeGroups',
              matchLevel: 'none',
              matchedWords: [],
            },
            genders: [
              {
                value: 'Couples',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Anyone',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Men',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Women',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            seasonOfLife: [
              {
                value: 'Single Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'New Believer',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Parent',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Married',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Blended Family',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Single',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Stay at Home',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Professional',
                matchLevel: 'none',
                matchedWords: [],
              },
              {
                value: 'Divorced',
                matchLevel: 'none',
                matchedWords: [],
              },
            ],
            topic: [
              {
                name: {
                  value: 'Friendship',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Financial Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Life.Church Online Watch Party',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mental Health',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Mentoring',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Volunteering',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Leadership Coaching',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Bible Study',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Parenting',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
              {
                name: {
                  value: 'Sisters',
                  matchLevel: 'none',
                  matchedWords: [],
                },
                image: {
                  value: './some/path/to_image.jpg',
                  matchLevel: 'none',
                  matchedWords: [],
                },
              },
            ],
            ageRangeTop: {
              value: '80',
              matchLevel: 'none',
              matchedWords: [],
            },
            ageRangeBottom: {
              value: '47',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingType: {
              value: 'In-Person',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingFrequency: {
              value: 'Weekly',
              matchLevel: 'none',
              matchedWords: [],
            },
            meetingDayOfWeekFull: {
              value: 'Friday',
              matchLevel: 'none',
              matchedWords: [],
            },
            children: {
              value: 'Childcare Available',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          campus: {
            guid: {
              value: 'a92210cb-7834-4354-85bf-c92385b0a524',
              matchLevel: 'none',
              matchedWords: [],
            },
            name: {
              value: 'Mansfield',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Tulsa',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationDescription: {
              value: 'Tulsa, TX',
              matchLevel: 'none',
              matchedWords: [],
            },
            shortCode: {
              value: 'DSR',
              matchLevel: 'none',
              matchedWords: [],
            },
            street1: {
              value: '94607 Gislason Lodge',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '70261',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          meetingLocation: {
            locationType: {
              value: 'Coffee Shop',
              matchLevel: 'none',
              matchedWords: [],
            },
            locationTypeColor: {
              value: '#b82bd8',
              matchLevel: 'none',
              matchedWords: [],
            },
            city: {
              value: 'Oklahoma City',
              matchLevel: 'none',
              matchedWords: [],
            },
            state: {
              value: 'OK',
              matchLevel: 'none',
              matchedWords: [],
            },
            zip: {
              value: '20825',
              matchLevel: 'none',
              matchedWords: [],
            },
            country: {
              value: 'VC',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
          groupLeaders: [
            {
              firstName: {
                value: 'Sidney',
                matchLevel: 'none',
                matchedWords: [],
              },
              avatar: {
                value: '/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
              publicAvatarUrl: {
                value:
                  'https://cms-images.life.church/lc-site/locations/campus-pastor-default.jpg',
                matchLevel: 'none',
                matchedWords: [],
              },
            },
          ],
          _geoloc: {
            lat: {
              value: '35.93',
              matchLevel: 'none',
              matchedWords: [],
            },
            lng: {
              value: '-95.82',
              matchLevel: 'none',
              matchedWords: [],
            },
          },
        },
      },
    ],
    nbHits: 20,
    offset: 0,
    length: 20,
    exhaustiveNbHits: true,
    exhaustiveTypo: true,
    exhaustive: {
      nbHits: true,
      typo: true,
    },
    query: '',
    params:
      'clickAnalytics=true&facetFilters=%5B%5B%22facets.type%3ALifeGroups%22%5D%5D&length=60&offset=0&page=0&sortFacetValuesBy=alpha',
    queryID: 'f5aa627cdbd8198f30d3bb3b7d55333a',
    renderingContent: {},
    processingTimeMS: 1,
    processingTimingsMS: {
      _request: {
        roundTrip: 22,
      },
      afterFetch: {
        format: {
          highlighting: 3,
          total: 4,
        },
      },
      total: 1,
    },
    serverTimeMS: 6,
  },
};

export const LG_API_OVERRIDES = {
  resultsPagination: {
    isMore: false,
    offset: 0,
    page: 0,
    resultsLength: 20,
    total: 20,
  },
  searchResults: {
    hits: [
      {
        id: '4805571BNB',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805571BNB',
        },
        attributes: {
          id: '4805571BNB',
          guid: '61E2109E-88CE-47FF-B22B-502F4BD045EC',
          name: 'Hope is Alive - OKC area',
          description:
            'Help change the lives of individuals and families affected by addiction through mentorship, spiritual guidance, and leadership development.\nThis organization partners with the Oklahoma City, BNB, and South OKC campuses.',
          'campus-code': 'BNB',
          'campus-location': 'Oklahoma City, OK',
          'campus-name': 'Broadway and Britton, OK',
          'lg-loc': 'Broadway and Britton, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '6775209MOR',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/6775209MOR',
        },
        attributes: {
          id: '6775209MOR',
          guid: 'E94E8CBE-1810-40EF-ACA3-4AAFAFC4A9E9',
          name: 'The Vine',
          description:
            'Oklahoma Baptist Homes for Children believes in the sanctity of the family. Children’s Hope was developed to help single moms, who may have limited or no support/resources, by providing an opportunity to develop a solid foundation for herself and the children in her custody. \n\nIf you or a group are interested in volunteering, there are several ways to get involved – from a one-time project to ongoing opportunities – throughout the year by helping with facility maintenance, group projects, campus events, style show, hospitality, after-school tutoring and even landscaping!',
          'campus-code': 'MOR',
          'campus-location': 'Moore, OK',
          'campus-name': 'Moore, OK',
          'lg-loc': 'Moore, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '7878047SBA',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/7878047SBA',
        },
        attributes: {
          id: '7878047SBA',
          guid: 'FF9E3890-0E8C-4B56-8475-A73A29894E85',
          name: 'Building All Children',
          description:
            "Building All Children's mission statement is to assess, resource, & empower children & their families to build each child's development and individual learning style. Volunteer opportunities include assisting at community playgroups with BUILDville, supporting local playgroups with Play & BUILDgroups, preparing for future playgroups with Prep Club, managing check-in reception, and aiding in workshops and trainings with Rise & BUILD programs.",
          'campus-code': 'SBA',
          'campus-location': 'Broken Arrow, OK',
          'campus-name': 'South Broken Arrow, OK',
          'lg-loc': 'South Broken Arrow, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '5792489NCS',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/5792489NCS',
        },
        attributes: {
          id: '5792489NCS',
          guid: '3064B764-82BF-4983-8267-6025D1E091DE',
          name: 'Beautiful Redemption',
          description:
            'Since 2016 Beautiful Redemption has connected faith communities, child welfare agencies and individuals to help almost 10,000 at-risk children. But, even more than that, we aim to see the local church become unified and intentional about rising up to be the solution to the foster care crisis in our State. This organization partners with our Rio Rancho, Colorado Springs, and Parker (South Denver) campuses.',
          'campus-code': 'NCS',
          'campus-location': 'Colorado Springs, CO',
          'campus-name': 'Colorado Springs, CO',
          'lg-loc': 'Colorado Springs, CO',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4804252WEL',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4804252WEL',
        },
        attributes: {
          id: '4804252WEL',
          guid: '755BAE01-2F2C-4659-80BD-F39256B1AF2A',
          name: 'Kids Cancer Foundation',
          description:
            "Kids Cancer Foundation's mission is to provide hope and support to local children and families battling childhood cancer. They accomplish this by providing compassionate, easily accessible supportive care services. Each month Kids Cancer Foundation hosts a themed event for their families. If you're interested in serving at these monthly events or learning about other opportunities to contribute, enter your contact information below.",
          'campus-code': 'WEL',
          'campus-location': 'Wellington, FL',
          'campus-name': 'Wellington, FL',
          'lg-loc': 'Wellington, FL',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805550YKN',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805550YKN',
        },
        attributes: {
          id: '4805550YKN',
          guid: 'DB119108-1491-4BAA-A59F-E96F9780B6F9',
          name: 'Branch15 - OKC Metro',
          description:
            'Walk alongside women on their paths to recovery and independent, sustainable living.\nThis organization partners with the Yukon, Edmond, Northwest OKC, OKC and Owasso campuses.',
          'campus-code': 'YKN',
          'campus-location': 'Yukon, OK',
          'campus-name': 'Yukon, OK',
          'lg-loc': 'Yukon, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805683BNB',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805683BNB',
        },
        attributes: {
          id: '4805683BNB',
          guid: '10F82E5B-33F0-4990-8497-090B595F1DDA',
          name: 'TEEM (The Education and Employment Ministry)',
          description:
            'Empower individuals to reach their God-given potential through mentoring relationships with those leaving incarceration.',
          'campus-code': 'BNB',
          'campus-location': 'Oklahoma City, OK',
          'campus-name': 'Broadway and Britton, OK',
          'lg-loc': 'Broadway and Britton, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '6568493NKC',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/6568493NKC',
        },
        attributes: {
          id: '6568493NKC',
          guid: 'F304EAC0-6621-4428-8779-DEDD25094C45',
          name: 'Safe Families Kansas City',
          description:
            'Safe Families seeks to keep children safe and families together. Safe Families is rooted in faith, fueled by radical hospitality, disruptive generosity, and intentional compassion, to expand the community safety net by providing parents in need a loving sanctuary for their children to go to in times of crisis. You can serve as a Resource Friend, Family Coach, or Host Family for a child in need.',
          'campus-code': 'NKC',
          'campus-location': 'Kansas City, MO',
          'campus-name': 'Northland, MO',
          'lg-loc': 'Northland, MO',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '6580195AMS',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/6580195AMS',
        },
        attributes: {
          id: '6580195AMS',
          guid: '2F72B1D9-F48F-4980-94CC-F5ABB0531080',
          name: 'Amarillo Angels',
          description:
            'The Amarillo Angels mission is to walk alongside children, youth, and families in the foster care community by offering consistent support through intentional giving, relationship building, and mentorship.',
          'campus-code': 'AMS',
          'campus-location': 'Amarillo, TX',
          'campus-name': 'Amarillo, TX',
          'lg-loc': 'Amarillo, TX',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805614OWS',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805614OWS',
        },
        attributes: {
          id: '4805614OWS',
          guid: '1547BCE9-A83B-435E-B6EB-4A86828530F9',
          name: 'Legacy Family Network',
          description:
            'Support children in foster and adoptive homes by connecting, equipping, and supporting the families that take care of them.',
          'campus-code': 'OWS',
          'campus-location': 'Owasso, OK',
          'campus-name': 'Owasso, OK',
          'lg-loc': 'Owasso, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '7078672SHW',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/7078672SHW',
        },
        attributes: {
          id: '7078672SHW',
          guid: '744B00A2-7A13-4386-B880-199527F89510',
          name: 'Central oklahoma Community Action Agency',
          description:
            'Central Oklahoma Community Action Agency helps low-income individuals and families avoid crisis situations in two ways; meeting emergency needs first, then helping families gain self-sufficiency through supportive case management and partnerships with other social service providers. They have many different ways ou can get involved and make a difference!',
          'campus-code': 'SHW',
          'campus-location': 'Shawnee, OK',
          'campus-name': 'Shawnee, OK',
          'lg-loc': 'Shawnee, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805550EDM',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805550EDM',
        },
        attributes: {
          id: '4805550EDM',
          guid: 'DB119108-1491-4BAA-A59F-E96F9780B6F9',
          name: 'Branch15 - OKC Metro',
          description:
            'Walk alongside women on their paths to recovery and independent, sustainable living.\nThis organization partners with the Yukon, Edmond, Northwest OKC, OKC and Owasso campuses.',
          'campus-code': 'EDM',
          'campus-location': 'Edmond, OK',
          'campus-name': 'Edmond, OK',
          'lg-loc': 'Edmond, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805571EDM',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805571EDM',
        },
        attributes: {
          id: '4805571EDM',
          guid: '61E2109E-88CE-47FF-B22B-502F4BD045EC',
          name: 'Hope is Alive - OKC area',
          description:
            'Help change the lives of individuals and families affected by addiction through mentorship, spiritual guidance, and leadership development.\nThis organization partners with the Oklahoma City, BNB, and South OKC campuses.',
          'campus-code': 'EDM',
          'campus-location': 'Edmond, OK',
          'campus-name': 'Edmond, OK',
          'lg-loc': 'Edmond, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805684EDM',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805684EDM',
        },
        attributes: {
          id: '4805684EDM',
          guid: '1121A913-E713-4A4E-A2EE-EE875AF19CD9',
          name: 'True North',
          description:
            "Lead and engage men to become better husbands, fathers, and men through mentoring relationships, men’s events, and weekend retreats.\n\nIn order for a man to get his heart back, he’s got to take a journey, one that involves risk, danger, and a point of no return. True North retreats are a four-day quest into the recovery of the masculine soul. True North Basecamp is more than a retreat—it is an expedition of the heart. You will never be the same!\n\nIn addition to True North retreats, we also host a men's discipleship gathering monthly that is designed to create an environment where men can worship, commune with God, and have community.",
          'campus-code': 'EDM',
          'campus-location': 'Edmond, OK',
          'campus-name': 'Edmond, OK',
          'lg-loc': 'Edmond, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805672YKN',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805672YKN',
        },
        attributes: {
          id: '4805672YKN',
          guid: 'EB573C96-648E-4B02-8ABB-636D14853C56',
          name: 'Autism Oklahoma',
          description:
            'Grow, learn, and serve the families affected by autism in Oklahoma. \nThis organization partners with the Oklahoma City, Yukon, Moore, and Life.Church Online teams.',
          'campus-code': 'YKN',
          'campus-location': 'Yukon, OK',
          'campus-name': 'Yukon, OK',
          'lg-loc': 'Yukon, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805636TUL',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805636TUL',
        },
        attributes: {
          id: '4805636TUL',
          guid: 'C292AC80-115F-471A-9537-FA9969F50D95',
          name: 'John 3:16',
          description:
            "Meet the physical, emotional, and spiritual needs of Tulsa's hungry, homeless, and at-risk by serving the community and building relationships.",
          'campus-code': 'TUL',
          'campus-location': 'Tulsa, OK',
          'campus-name': 'Midtown Tulsa, OK',
          'lg-loc': 'Midtown Tulsa, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4805596SPF',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4805596SPF',
        },
        attributes: {
          id: '4805596SPF',
          guid: 'B0097191-B95B-497C-9DA4-4A3F94946A98',
          name: 'Victory Mission + Ministry',
          description:
            'Provides emergency relief through outreach services and long-term empowerment through programming and social enterprise. Volunteers can be involved in outreach with mobile food pantries, the emergency shelter, and the street outreach team. There are opportunities to mentor those in the Restoration Program, or be involved with Jobs for Life or WorkReady BootCamps.',
          'campus-code': 'SPF',
          'campus-location': 'Springfield, MO',
          'campus-name': 'Springfield, MO',
          'lg-loc': 'Springfield, MO',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '5078135YKN',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/5078135YKN',
        },
        attributes: {
          id: '5078135YKN',
          guid: '6E4AE9A3-DA1B-47B4-93F8-47B09B1F0221',
          name: 'Savannah Station Therapeutic Riding Center, Inc.',
          description:
            'At Savannah Station Therapeutic Riding Program our mission is to offer\nHope and Healing to people with special needs through the amazing power of the Horse.',
          'campus-code': 'YKN',
          'campus-location': 'Yukon, OK',
          'campus-name': 'Yukon, OK',
          'lg-loc': 'Yukon, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '4804232BAO',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/4804232BAO',
        },
        attributes: {
          id: '4804232BAO',
          guid: '16D9B000-0114-42A5-BC25-0F83314EA5F0',
          name: 'Big Brothers Big Sisters of Oklahoma',
          description:
            'Help children and at-risk youth realize their potential and build their futures through positive mentoring relationships.',
          'campus-code': 'BAO',
          'campus-location': 'Broken Arrow, OK',
          'campus-name': 'Broken Arrow, OK',
          'lg-loc': 'Broken Arrow, OK',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
      {
        id: '7307226CLV',
        type: 'lifegroups',
        links: {
          self: 'https://staging.lifegroups.apis.life.church/v1/lifegroups/7307226CLV',
        },
        attributes: {
          id: '7307226CLV',
          guid: '277686E1-6873-4C32-800A-B3D33668D818',
          name: 'Urbandale Food Pantry',
          description:
            'The Urbandale Food Pantry is a collaborative, community effort to reach out to our neighbors and provide food assistance with dignity and compassion. For over a decade we’ve been serving our neighbors in need. Open Monday-Thursday 9:30-6:30pm and Saturday 8-12pm, with serving opportunities every day Monday-Saturday!',
          'campus-code': 'CLV',
          'campus-location': 'Clive, IA',
          'campus-name': 'Des Moines, IA',
          'lg-loc': 'Des Moines, IA',
          'community-types': ['Local Partner'],
          agemin: 18,
          agemax: 99,
          'meeting-frequency': 'Varies',
          'day-of-week': 'Varies',
          'meeting-time': null,
          'kids-welcome': false,
          'meet-online': false,
          'day-sort': 8,
          timesort: '2000-01-01T23:59:59.999Z',
          postalcode: null,
          state: null,
          country: 'US',
          'group-type': 'local-partner',
          'utc-time-zone': null,
        },
      },
    ],
    length: 20,
    nbHits: 20,
    offset: 0,
  },
};
