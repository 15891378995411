/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module HobbiesIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Hobbies icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Hobbies Icon element.
 */
export default function HobbiesIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="hobbies-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.22846 6.52352H5.95095C5.75347 4.92509 4.79071 3.56736 3.44531 2.8206C4.42659 1.81464 5.75346 1.14812 7.22846 1V6.52352ZM9.73847 6.52353C9.93596 4.9251 10.8987 3.56737 12.2441 2.82061C11.2567 1.81465 9.93596 1.14813 8.46096 1.00001V6.52353H9.73847ZM2.65354 3.80469C3.72122 4.31692 4.49883 5.31671 4.70249 6.52016H1.70312C1.80187 5.52654 2.1413 4.60081 2.65354 3.80469ZM13.9837 6.52016H10.9844C11.188 5.31671 11.9656 4.31692 13.0333 3.80469C13.5456 4.60081 13.885 5.52654 13.9837 6.52016ZM9.73847 7.7579H8.46096V9.22015C7.87441 9.56689 7.4506 10.1665 7.34882 10.8825L7.22846 11.7284V7.75781H5.95095C5.75347 9.35624 4.79071 10.714 3.44531 11.4607C4.384 12.417 5.62386 13.0666 7.01113 13.2556L6.71832 15.3134C6.59106 16.2042 7.27941 16.9967 8.176 16.9967C8.56934 16.9967 8.93954 16.8405 9.2172 16.5628L10.5245 15.2613H13.9951L15.2966 16.5628C15.5743 16.8405 15.9503 16.9967 16.3378 16.9967C17.2402 16.9967 17.9286 16.2042 17.8013 15.3134L17.1708 10.8825C17.0088 9.74297 16.0313 8.89844 14.8802 8.89844H13.7586C13.8683 8.53132 13.9448 8.14985 13.9837 7.75781H10.9844C11.0533 8.165 11.1879 8.54888 11.3772 8.89844H10.0213C9.88373 8.53688 9.78751 8.15481 9.73847 7.7579ZM4.70249 7.7579C4.49883 8.95518 3.71504 9.96113 2.65354 10.4734C2.1413 9.67724 1.80187 8.75151 1.70312 7.7579H4.70249ZM10.5245 12.3691H11.6814V11.7907H10.5245V10.6338H9.94604V11.7907H8.78915V12.3691H9.94604V13.526H10.5245V12.3691ZM13.4167 11.2122C13.4167 11.5304 13.677 11.7907 13.9951 11.7907C14.3133 11.7907 14.5736 11.5304 14.5736 11.2122C14.5736 10.8941 14.3133 10.6338 13.9951 10.6338C13.677 10.6338 13.4167 10.8941 13.4167 11.2122ZM14.5736 12.9475C14.5736 13.2657 14.8339 13.526 15.152 13.526C15.4702 13.526 15.7305 13.2657 15.7305 12.9475C15.7305 12.6294 15.4702 12.3691 15.152 12.3691C14.8339 12.3691 14.5736 12.6294 14.5736 12.9475Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
