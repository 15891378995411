/**
 * @module MaintenanceOutage
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentPage } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { ERROR_MODES } from '../../utils/constants';
import { getExperimentalCookieValue } from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import './MaintenanceOutage.scss';

/**
 * Represents a view shown for maintenance or outage mode for LifeGroup Search App V2.
 *
 * @param {object} props - The component props object.
 * @param {('error'|'maintenance'|'outage')} props.mode - The mode of the component (either 'error', 'maintenance', or 'outage', defaults to 'maintenance').
 *
 * @returns {React.ReactElement} The MaintenanceOutage component.
 */
function MaintenanceOutage({ mode }) {
  const [strings] = React.useState(
    STRINGS[Object.keys(ERROR_MODES).includes(mode) ? mode : 'maintenance'],
  );
  const [localMode] = React.useState(
    Object.keys(ERROR_MODES).includes(mode) ? mode : 'maintenance',
  );

  /**
   * Handler function for button click event.
   */
  /* istanbul ignore next */
  function handleButtonClick() {
    const { href, target } = strings.cta;
    triggerSegmentTrack({
      dataForAnalytics: null,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names[mode],
        component_url: href,
        label: ANALYTICS.labels.getHelp,
        screen: ANALYTICS.screens.names[localMode],
      },
      user: null,
      userProfileData: null,
    });

    if (href) {
      window.open(href, target || '_blank');
    }
  }

  /**
   * Handler function for error mode button click event.
   */
  /* istanbul ignore next */
  function handleErrorButtonClick(event) {
    const ctaNum = event.currentTarget.getAttribute('data-button-num');
    const { href, target } = strings.ctas[ctaNum];
    triggerSegmentTrack({
      dataForAnalytics: null,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names[mode],
        component_url: href,
        label: event.currentTarget.textContent,
        screen: ANALYTICS.screens.names[localMode],
      },
      user: null,
      userProfileData: null,
    });

    if (href) {
      window.open(href, target || '_blank');
    }
  }

  /**
   * Single-run convenience effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages[localMode],
      properties: {
        experimental_flag: getExperimentalCookieValue(),
        logged_in: false,
        path: window?.location?.pathname,
        preferred_campus: null,
        referrer: document?.referrer,
        title: document?.title,
        url: /* istanbul ignore next */ window?.location?.href,
        user_id: null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="maintenance-outage" data-testid="maintenance-outage">
      <h1>{strings.title}</h1>
      <p>{strings.body}</p>
      {localMode !== ERROR_MODES.error ? (
        <StyledButton
          onClick={handleButtonClick}
          variant={ButtonVariants.primary}
        >
          {strings.cta.label}
        </StyledButton>
      ) : (
        strings.ctas.map((cta, index) => {
          return (
            <StyledButton
              data-button-num={index}
              key={`${cta.label.replace(' ', '-').toLowerCase()}`}
              onClick={handleErrorButtonClick}
              variant={cta.variant}
            >
              {cta.label}
            </StyledButton>
          );
        })
      )}
    </div>
  );
}

export default MaintenanceOutage;
