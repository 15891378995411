/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module CalendarIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Calendar icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Calendar Icon element.
 */
export default function CalendarIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="calendar-icon">
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.84992 15.5169C1.33521 15.5169 0.898208 15.3372 0.538925 14.9779C0.179642 14.6186 0 14.1816 0 13.6669V3.36677C0 2.85206 0.179642 2.41506 0.538925 2.05578C0.898208 1.6965 1.33521 1.51685 1.84992 1.51685H2.28987V0.823441C2.28987 0.595753 2.37022 0.401559 2.53091 0.24086C2.69161 0.0802865 2.88581 0 3.11349 0C3.34131 0 3.5355 0.0802865 3.69608 0.24086C3.85677 0.401559 3.93712 0.595753 3.93712 0.823441V1.51685H10.0919V0.80914C10.0919 0.586219 10.1709 0.3956 10.3291 0.237285C10.4875 0.0790947 10.6781 0 10.901 0C11.1239 0 11.3145 0.0790947 11.4727 0.237285C11.631 0.3956 11.7101 0.586219 11.7101 0.80914V1.51685H12.1501C12.6648 1.51685 13.1018 1.6965 13.4611 2.05578C13.8204 2.41506 14 2.85206 14 3.36677V13.6669C14 14.1816 13.8204 14.6186 13.4611 14.9779C13.1018 15.3372 12.6648 15.5169 12.1501 15.5169H1.84992ZM1.84992 13.8986H12.1501C12.208 13.8986 12.2611 13.8744 12.3093 13.8261C12.3576 13.778 12.3817 13.7249 12.3817 13.6669V6.15172H1.61828V13.6669C1.61828 13.7249 1.64243 13.778 1.69073 13.8261C1.7389 13.8744 1.79196 13.8986 1.84992 13.8986ZM1.61828 4.53344H12.3817V3.36677C12.3817 3.30882 12.3576 3.25575 12.3093 3.20758C12.2611 3.15928 12.208 3.13513 12.1501 3.13513H1.84992C1.79196 3.13513 1.7389 3.15928 1.69073 3.20758C1.64243 3.25575 1.61828 3.30882 1.61828 3.36677V4.53344Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
