/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module SistersIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Sisters icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Sisters Icon element.
 */
export default function SistersIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="sisters-icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.83206 15.7356C9.17719 15.7356 8.59881 15.5141 8.09694 15.0712C7.59494 14.6285 7.31369 14.098 7.25319 13.4797C6.67244 13.7259 6.06619 13.7506 5.43444 13.554C4.80269 13.3574 4.31756 12.9797 3.97906 12.4211C3.63781 11.8644 3.53281 11.2516 3.66406 10.5829C3.79531 9.91412 4.13112 9.3865 4.6715 9C4.1465 8.62112 3.81644 8.10694 3.68131 7.45744C3.54619 6.80794 3.63775 6.20481 3.956 5.64806C4.27425 5.09131 4.75362 4.70025 5.39412 4.47487C6.0345 4.24937 6.6465 4.25969 7.23013 4.50581C7.29063 3.87794 7.57188 3.345 8.07388 2.907C8.57575 2.469 9.15406 2.25 9.80881 2.25C10.4637 2.25 11.0421 2.469 11.5439 2.907C12.0459 3.345 12.3272 3.87794 12.3877 4.50581C12.9867 4.25969 13.5963 4.24356 14.2166 4.45744C14.8367 4.67144 15.3136 5.06831 15.6474 5.64806C15.9656 6.22019 16.0611 6.82525 15.9337 7.46325C15.8062 8.10125 15.4722 8.6135 14.9319 9C15.4722 9.3865 15.81 9.91031 15.9451 10.5714C16.0803 11.2324 15.9887 11.849 15.6704 12.4211C15.3319 13.0105 14.855 13.3958 14.2396 13.5771C13.6243 13.7583 13.0146 13.7259 12.4108 13.4797C12.3503 14.098 12.069 14.6285 11.567 15.0712C11.0651 15.5141 10.4868 15.7356 9.83206 15.7356ZM9.80169 11.6321C10.5257 11.6321 11.1454 11.3744 11.6608 10.8591C12.1761 10.3437 12.4338 9.724 12.4338 9C12.4338 8.276 12.1761 7.65631 11.6608 7.14094C11.1454 6.62556 10.5257 6.36787 9.80169 6.36787C9.07769 6.36787 8.458 6.62556 7.94263 7.14094C7.42725 7.65631 7.16956 8.276 7.16956 9C7.16956 9.724 7.42725 10.3437 7.94263 10.8591C8.458 11.3744 9.07769 11.6321 9.80169 11.6321Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
