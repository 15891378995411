/**
 * @module CheckBox
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './CheckBox.scss';

/**
 * Represents a simple checkbox element with accompanying label.
 *
 * @param {object} props - The component props object.
 * @param {string} props.cypress - The value to use for the label's data-cy-grouptype attribute.
 * @param {string} props.id - The unique id to use as the value for the checkbox input element.
 * @param {boolean} props.isChecked - Boolean flag denoting whether or not the checkbox is checked.
 * @param {string} props.labelClassName - Class name value to use for the checkbox class label element.
 * @param {string} props.name - The name attribute value to use for the checkbox.
 * @param {Function} props.onChange - Handler function for the checkbox change event.
 * @param {string} props.value - The value of the checkbox input element.
 *
 * @returns {React.ReactElement} The CheckBox component.
 */
function CheckBox({
  cypress,
  id,
  isChecked,
  labelClassName,
  name,
  onChange,
  value,
}) {
  const classNameValue = labelClassName + (isChecked ? ' lg-active' : '');

  return (
    <div className="checkbox-div" data-testid="lg-checkbox">
      <input
        checked={isChecked}
        className="lg-hidden-checkbox"
        id={id}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <label
        className={`lg-checkbox ${classNameValue}`}
        data-cy-grouptype={cypress}
        htmlFor={id}
      >
        {name}
      </label>
    </div>
  );
}

export default CheckBox;
